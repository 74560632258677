import React from "react";
import Icon from "../global/Icon";
import clock from "../../assets/icons/forage-clock.png"
import certificate from "../../assets/icons/forage-certificate.png"
import badge from "../../assets/icons/forage-badge.png"
import logo from "../../assets/icons/forage-green.svg"

const ForageModal = (props) => {

    return (
        <div className='modal-overlay'>
        <div className="modal forage-modal">
            <button className="button icon-btn close-btn" aria-label="close" onClick={props.close}>
                <Icon icon="close" size="24"/>
            </button>
            <div className="modal-header">
                <h1 className="modal-title desktop-h3">
                    {props.data.title}
                </h1>
            </div>
            <div className="modal-body">
                <div className="course-description">
                    <h2 className="desktop-h4 bold">What you'll learn with {props.data.company}</h2>
                    <div className="desc-info course-info">
                        <div className="avatar">
                            <img src={props.data.avatar} alt={props.data.company}/>
                        </div>
                        <p>{props.data.description}</p>
                    </div>
                </div>
                <div className="forage-info">
                    <div className="forage-description">
                        <h3 className="desktop-h5 bold">What is Forage?</h3>
                        <div className="desc-info">
                            <div className="avatar">
                                <img src={logo} alt={"Forage"}/>
                            </div>
                            <p>Forage job simulations build real-life skills for real-life roles, offering a window into the company and a preview of their day-to-day. Job sims are 100% free, open access and self-paced.</p>
                        </div>
                    </div>
                    <div className="forage-benefits">
                        <h3 className="desktop-h5 bold">More benefits:</h3>
                        <ul className="custom-bullets">
                            <li>
                                <span className="icon">
                                    <img src={clock} alt={""}/>
                                </span>
                                <span className="text">Do it on your own time</span>
                            </li>
                            <li>
                                <span className="icon"><img src={certificate} alt={""}/></span>
                                <span className="text">Try out a career you're interested in, risk-free</span>
                            </li>
                            <li>
                                <span className="icon"><img src={badge} alt={""}/></span>
                                <span className="text">Showcase your knowledge on your resume and networks</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                {props.data.externalLink ?
                <a href={props.data.externalLink} className="button primary medium" target="_blank">
                Launch Forage
            </a>
                : 
                <button className="button primary medium" onClick={props.close}>
                    Launch Forage
                </button>}
            </div>
        </div>
        </div>
    )
}
export default ForageModal