import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCareers = createAsyncThunk(
    'careers/fetchCareers',
    async () => {
        const headers = new Headers();
        const response = await fetch(`https://services.onetcenter.org/ws/mnm/careers?client=pearson1`, {
            method: "GET",
            mode: 'cors',
                credentials: 'omit',
            headers: headers,
        });
        const data = await response.json();
        console.log(data)
        return [data]
        // dispatch(setCareers(data));
    }
);

const careersSlice = createSlice({
    name: "careers",
    initialState: {
        data: [],
        isLoading: false,
        error: null
    },
    reducers: {
        resetCareers: (state) => {
            state.data = [];
        },
        setCareers(state, action) {
            state.data = action.payload;
        },
    }
});

export const { resetCareers, setCareers } = careersSlice.actions;
export default careersSlice.reducer;
