import React from "react";
import SkillCard from "../global/cards/SkillCard";
import videos from "../../assets/data/skillVideos.json"
const VideoLearning = () => {

    return (
        <>
        <section >
                <div className="">
                    
                    <div className="card-body">
                        <div className="card-row skills-group show-all">
                        {videos.map((item, index) => {

                            return (
                                <SkillCard data={item} modifier="madecraft-card" key={"video"+index}/>
                                
                            )
                            })}
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}
export default VideoLearning