import React, { useState, useRef, useEffect } from "react";
import Icon from "../global/Icon";
import { searchTerms } from "../../assets/data/jobTerms";


const JobSearchInput = (props) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(props.default ? props.default : "");
    const [companyList, setCompanyList] = useState(searchTerms);
    const [showClear, setShowClear] = useState(false);
    const input = useRef();
    const container = useRef();

    useEffect(() => {
        setSelected(props.default)
        if (props.default.length > 0) {setShowClear(true)}
    },[props.default])

    useEffect(() => {
        let autocomplete = container.current;
        const handleClick = (event) => {
            if (autocomplete && autocomplete.contains(event.target)) {
                //do nothing
            } else {
                console.log("close", event.target)
                setOpen(false)
            }
        }
        document.addEventListener("click",handleClick)
        document.addEventListener('keydown', event => {
            if (event.key === "Escape") {
                setOpen(false)
            }
        })
        return () => {
            document.removeEventListener('click', handleClick)
            document.removeEventListener('keydown', event => {
                if (event.key === "Escape") {
                    setOpen(false)
                }
            })
        }
    },[])

    const onInput = (event) => {
        
        console.log(event.target.value)
        if (event.target.value.length > 0) {
            
            setShowClear(true)
            
        } else {
            setShowClear(false)
            // setOpen(false)
        }
        if (event.target.value.length > 2) {
            setOpen(true)
            transformCompanies(event.target.value)
        } 
    }
    const addToSelected = (item) => {
        input.current.value = item
        setSelected(item)
        props.search(item)
        setOpen(false)
        setShowClear(true)
        transformCompanies('')
    }


    const clearInputFilter = () => {
        input.current.value = ''
        setSelected('')
        setOpen(false)
        setCompanyList(searchTerms)
        setShowClear(false)
        props.search('')
        input.current.focus()
    }
    const onEnter = (event) => {
        if (event.key === "Enter") {
            //search
            props.search(event.target.value)
            setOpen(false)
        }
    }

    const transformCompanies = (str) => {
        let newcompanyList = searchTerms.filter(c => c !== selected)
        //remove any already selected
        
        let filteredList;
        //filter by input
        if (str.length > 0) {
            filteredList = newcompanyList.filter(name => name.toLowerCase().includes(str.toLowerCase()))
            // (post) => {
            //     return post.title.toLowerCase().match(searchValue.toLowerCase());
            //  });
        } else {
            filteredList = newcompanyList
        }
        
        setCompanyList(filteredList)
    }

    return (
        <div className="jobs-search-list" ref={container}>
            <div className="autocomplete-container ">
                <div className="search-input">
                    <input type="text" className="gr-input" 
                    aria-label="Search jobs and internships" 
                    role="combobox" aria-autocomplete="list" aria-expanded={open} aria-haspopup="listbox"
                    aria-controls="jobSearchList"
                    placeholder="Search for jobs and internships"
                    onChange={onInput}
                    onKeyDown={(event) => onEnter(event)}
                    defaultValue={selected}
                    ref={input}
                    />
                    <span className="search-icon">
                        <Icon icon="search" size="24"/>
                    </span>
                    
                    <button hidden={!showClear} className="button medium icon-btn clear-icon" aria-label="Clear search" onClick={() => clearInputFilter()}>
                        <Icon icon="close" size="24"/>
                    </button> 
                </div>
                
                <div className="autocomplete-list" style={open && companyList.length > 0 ? null : {display:"none"}} id="jobSearchList" role="listbox">
                    <ul>
                        {companyList.length > 0 ? <>
                        {companyList.map((co, index) => {
                            return (
                                <li key={"co"+index} role="presentation">
                                    <button role="option" onClick={() => addToSelected(co)}>{co}</button>
                                </li>
                            )
                        })}
                        </>
                        : <li style={{padding:"16px"}}>No results found.</li>
                        }
                        {/* <li><button>List item</button></li> */}
                    </ul>
                </div> 
            </div>
        </div>
    )
}
export default JobSearchInput