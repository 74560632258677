import React, { useEffect, useState } from "react";
import Icon from "../global/Icon";
import ToggleChip from "../global/chips/ToggleChip";
import SearchChip from "../global/chips/SearchChip";
import AddChip from "../global/chips/AddChip";

const JobPreferences = (props) => {
    const defaultPref = {type: [], location: [], title: []};
    const [preferences, setPreferences] = useState(defaultPref);

    const [addedJobs, setAddedJobs] = useState([]);
    const [savedJobs, setSavedJobs] = useState([]);
    //const default = {type: ["Full time", etc], location: ["entry"], title: ["entry"]}


    useEffect(() => {
        if (props.showSaved?.length > 0) {
            setSavedJobs(props.showSaved)
        }
    },[]);

    const addSavedJobToPref = (job) => {
        setAddedJobs([...addedJobs, job])
        let currentSaved = [...savedJobs];
        let filtered = currentSaved.filter(f => f.title !== job)
console.log(filtered, currentSaved)
        setSavedJobs(filtered)
    }
    const checkExists = (type, entry) => {
        let currentPref = {...preferences};

        //get existing from pref state

        
        //check if new exists
        //if checkbox, then remove / else do nothing

        //if not there, add it
        if (type === 'type') {
            let existing = currentPref.type

            if (existing.includes(entry)) {
                //remove
                // let index = existing.indexOf(entry);
                //existing.splice(index, 1)
                existing = existing.filter(f => f !== entry)
            } else {
                existing.push(entry)
            }


        } else if (type === 'location') {
            let existing = currentPref.location
            if (existing.includes(entry)) {
                //do nothing
            } else {
                existing.push(entry)
            }

        } else if (type === 'title') {
            let existing = currentPref.title
            if (existing.includes(entry)) {
                //do nothing
            } else {
                existing.push(entry)
            }

        } else {
            //do nothing
        }

        setPreferences(currentPref)
    }

    return (
        <div className='modal-overlay'>
        <div className="modal">
            <button className="button icon-btn close-btn" aria-label="Close" onClick={() => props.close()}>
                <Icon icon="close" size="24"/>
            </button>
            <div className="modal-header border-bottom">
                <h1 className="modal-title desktop-h3 text-left">
                    Job preferences
                </h1>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Job type</legend>
                        <div className="checkbox-group">
                            <div className="checkbox">
                                
                                <label htmlFor="jobType1">
                                    <input type="checkbox" name="jobType" id="jobType1" onChange={() => checkExists('type', 'Paid internship')}/>
                                    <span>Paid internship</span></label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="jobType2">
                                   <input type="checkbox" name="jobType" id="jobType2" onChange={() => checkExists('type', 'Unpaid internship')}/> 
                                   <span>Unpaid internship</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                
                                <label htmlFor="jobType3">
                                    <input type="checkbox" name="jobType" id="jobType3" onChange={() => checkExists('type', 'Contract')}/>
                                    <span>Contract</span></label>
                            </div>
                            <div className="checkbox">
                                
                                <label htmlFor="jobType4">
                                <input type="checkbox" name="jobType" id="jobType4" onChange={() => checkExists('type', 'Part time')}/>
                                <span>Part time</span></label>
                            </div>
                            <div className="checkbox">
                                
                                <label htmlFor="jobType5">
                                <input type="checkbox" name="jobType" id="jobType5"  onChange={() => checkExists('type', 'Full time')}/>
                                <span>Full time</span></label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="form-group">
                    <label htmlFor="searchLocation" className="legend">Location</label>
                    <div className="autocomplete-container">
                        <div className="search-input">
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                            <input className="gr-input" type="text" 
                            placeholder="Search locations" id="searchLocation"
                            // onInput={() => checkExists('location', 'Location')}
                            />
                        </div>
                        <div className="autocomplete-results"></div>
                        {/* <div className="chip-list"></div> */}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="jobTitles" className="legend">Job titles</label>
                    <div className="autocomplete-container">
                        <div className="search-input">
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                            <input className="gr-input" type="text" placeholder="Search titles" id="jobTitles"/>
                        </div>
                        
                    </div>
                    {addedJobs.length > 0 && <div className="recommended selected-jobs">
                        {/* <p className="secondary-label">Popular for your major</p> */}
                        <div className="chip-list">
                        {addedJobs.map((item, index) => {
                            return (
                            <div className="chip-btn search-filter-chip dark with-remove">
                                {item}
                                <button className="button icon-btn" aria-label={"Remove " + item}><Icon icon="close" size="18"/></button>
                            </div>)
                        })}
                        </div>
                        </div>
                        }
                    {props.major &&
                    <div className="recommended">
                        <p className="secondary-label">Popular for your major</p>
                        <div className="chip-list">
                            <button className="chip-btn">Popular job role</button>
                            <button className="chip-btn">Popular job role</button>
                            <button className="chip-btn">Popular job role</button>
                            <button className="chip-btn">Popular job role</button>
                        </div>
                    </div> }
                    {savedJobs?.length > 0 && <div className="recommended">
                        <p className="secondary-label">From your saved occupations</p>
                        <div className="chip-list">
                            {savedJobs.map((item,index) => {return (<AddChip variant={"small"} key={"saved"+item.code} label={item.title} click={() => {addSavedJobToPref(item.title)}}/>)})}
                        </div>
                    </div>}
                        
                </div>
                
            </div>
            <div className="modal-footer">
                <button className="button primary medium" onClick={() => props.apply(preferences)}>
                    Apply preferences
                </button>
            </div>
        </div>
        </div>
    )
}
export default JobPreferences