import React, {useState} from "react";

const FilterMenuDate = (props) => {
    const [checked, setChecked] = useState(props.data.selected)

    const handleCheck = (item) => {
        let currentChecked = [item]
        // if (currentChecked.indexOf(item) > -1) {
        //     currentChecked.splice(currentChecked.indexOf(item),1)
        // } else {
        //     currentChecked.push(item)
        // }
        setChecked(currentChecked)
        props.modify(currentChecked)
    }
    return (
        <div className="filter-body">
            <div className="form-group radio-options">
                {/* <div className="gr-radio">
                    <input type="radio" name="postDate" id="postDate1" value="any" 
                    onChange={() => handleCheck("Anytime")}
                    defaultChecked={props.data.selected.includes("Anytime")}/>
                    <label htmlFor="postDate1">
                        <span>Anytime</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div> */}
                <div className="gr-radio">
                    <input type="radio" name="postDate" id="postDate2" value="month" 
                    onChange={() => handleCheck("month")}
                    defaultChecked={props.data.selected.includes("month")}/>
                    <label htmlFor="postDate2">
                        <span>Past month</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="postDate" id="postDate3" value="week" 
                    onChange={() => handleCheck("week")}
                    defaultChecked={props.data.selected.includes("week")}/>
                    <label htmlFor="postDate3">
                        <span>Past week</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="postDate" id="postDate4" value="day" 
                    onChange={() => handleCheck("day")}
                    defaultChecked={props.data.selected.includes("day")}/>
                    <label htmlFor="postDate4">
                        <span>Past 24 hours</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}
export default FilterMenuDate