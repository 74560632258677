import React, {useEffect, useState, useRef} from "react";
import Icon from "../Icon";

const SavedJobsCarousel = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [controls, setControls] = useState({show:false,panels:1});
    const scrollContainer = useRef()

    useEffect(() => {
        //on load, get container size
        setUpControls()
        // console.log(props.children)
    },[props.children])
    const setUpControls = () => {
        let windowWidth = window.innerWidth;
        let cardWidth = 343 // change responsively?
        let containerSize = scrollContainer.current.getBoundingClientRect().width,
        // totalContent = props.children.length * cardWidth + (props.children.length - 1) * 24;
        totalContent = scrollContainer.current.scrollWidth;

        // console.log(containerSize, totalContent)
        // let numPanels = Math.ceil(totalContent / containerSize)
        let numCardsFitinContainer = containerSize < cardWidth ? 1 : Math.floor((containerSize + 24)/(cardWidth + 24))
        let numPanels = windowWidth < cardWidth ? props.children.length : Math.ceil(props.children.length / numCardsFitinContainer)
        // console.log((containerSize + 24)/367)

        let numCardsAT = Math.round((containerSize + 24)/(cardWidth + 24))
        
        if (totalContent > containerSize) {
            setControls({show:true, panels: numPanels})

            //hide hidden ones from AT
            // controlVisibleCards(activeTab)
            // console.log(numCardsAT)
            // let allCards = scrollContainer.current.querySelectorAll(".card");
            // allCards.forEach((card,index) => {

            //     if (index > numCardsAT) {
            //         card.setAttribute("aria-hidden","true")
            //         card.setAttribute("tabindex","-1")
            //     }
            // })
        } else {
            setControls({show:false, panels: numPanels})
            // let allCards = scrollContainer.current.querySelectorAll(".card");
            // allCards.forEach((card,index) => {
            //     card.removeAttribute("aria-hidden")
            //     card.removeAttribute("tabindex")
            // })
        }
    }

    useEffect(() => {
        window.addEventListener("resize", setUpControls)
        return () => {
            window.removeEventListener("resize", setUpControls)
        }
    })

    useEffect(() => {
        if (scrollContainer.current) {
            //on load
            // setUpControls()

            

            scrollContainer.current.addEventListener("scroll", event => {
                let cardWidth = 343 // change responsively?
                let containerWidth = scrollContainer.current.getBoundingClientRect().width,
                    scrollPosition = scrollContainer.current.scrollLeft,
                    howManyScrolls = scrollContainer.current.scrollWidth / controls.panels //doesnt include 0 and final

                // console.log(scrollContainer.current.getBoundingClientRect())
                // console.log("p " + (scrollPosition))
                // console.log(scrollContainer.current.scrollWidth / controls.panels)
                // console.log(scrollPosition / howManyScrolls)
                

                if (scrollPosition === scrollContainer.current.scrollWidth - containerWidth) {
                    //last
                    setActiveTab(controls.panels - 1)
                } else {
                    setActiveTab(Math.round(scrollPosition / howManyScrolls))
                }
                // } else if (scrollPosition < howManyScrolls*0.5) {
                //     //beginning
                //     setActiveTab(0)
                // } else {
                //     //
                    
                //     setActiveTab(Math.floor(scrollPosition / howManyScrolls))
                // }
                
                // else if (scrollPosition < cardWidth*1.5) {
                //     setActiveTab(1)
                // } else if (scrollPosition < cardWidth*2.5) {
                //     setActiveTab(2)
                // } else {
                //     setActiveTab(3)
                // }
                
            })
        }

        return () => {
            if (scrollContainer.current) { 
            
            scrollContainer.current.removeEventListener("scroll", event => {
                let cardWidth = 343 // change responsively?
                let containerWidth = scrollContainer.current.getBoundingClientRect().width,
                    scrollPosition = scrollContainer.current.scrollLeft,
                    howManyScrolls = scrollContainer.current.scrollWidth / controls.panels //doesnt include 0 and final

                // console.log(scrollContainer.current.getBoundingClientRect())
                // console.log("p " + (scrollPosition))
                // console.log(scrollContainer.current.scrollWidth / controls.panels)

                // if (scrollPosition === scrollContainer.current.scrollWidth - containerWidth) {
                //     //last
                //     setActiveTab(controls.panels - 1)
                // } else if (scrollPosition < howManyScrolls*0.5) {
                //     //beginning
                //     setActiveTab(0)
                // } else {
                //     //
                //     console.log(Math.floor(scrollPosition / howManyScrolls))
                //     setActiveTab(Math.floor(scrollPosition / howManyScrolls))
                // }
                if (scrollPosition === scrollContainer.current.scrollWidth - containerWidth) {
                    //last
                    setActiveTab(controls.panels - 1)
                } else {
                    setActiveTab(Math.round(scrollPosition / howManyScrolls))
                }
                
            })}
        }

        //scroll listener to update active tab
    })

    const controlVisibleCards = (tab) => {
        let allCards = scrollContainer.current.querySelectorAll(".card");
        //how many cards are visible?
        let visibleCards = Math.floor(props.children.length / controls.panels),
        rangeLeft = visibleCards * (tab),
        rangeRight = rangeLeft + visibleCards

        console.log(props.children.length / controls.panels)
        allCards.forEach((card,index) => {
            //activetab + 1 is which panel to bring into view
            // visible cards = how many cards are visible at a time

            if (index >=rangeLeft && index < rangeRight) {
                // console.log(index > rangeLeft)
                card.removeAttribute("aria-hidden")
                card.removeAttribute("tabindex")
            } else {
                // console.log(card)
                card.setAttribute("aria-hidden","true")
                card.setAttribute("tabindex","-1")
                
            }
        })
    }

    const scrollRight = () => {
        let currentPosition = scrollContainer.current.scrollLeft,
            scrollAmount = scrollContainer.current.scrollWidth / controls.panels;
           
            // controlVisibleCards(activeTab+1)

            scrollContainer.current.scrollTo({
                top:0,
                left: scrollAmount * (activeTab+1),
                behavior:'smooth'
            })

            // scrollContainer.current.scrollLeft = currentPosition + scrollAmount;
            // scrollContainer.current.scrollTo({
            //     top:0,
            //     left: currentPosition + scrollAmount,
            //     behavior:'smooth'
            // })
        // setActiveTab(activeTab+1)
    }
    const scrollLeft = () => {
        let currentPosition = scrollContainer.current.scrollLeft,
            scrollAmount = scrollContainer.current.scrollWidth / controls.panels;

            // controlVisibleCards(activeTab-1)

            scrollContainer.current.scrollTo({
                top:0,
                left: scrollAmount * (activeTab-1),
                behavior:'smooth'
            })
            // console.log(current Position)
            // if (activeTab === controls.panels - 1) {
            //     //if last one
                
            //     // scrollContainer.current.scrollLeft = 
            // } else {
            //     scrollContainer.current.scrollTo({
            //         top:0,
            //         left: currentPosition - scrollAmount,
            //         behavior:'smooth'
            //     })
            //     // scrollContainer.current.scrollLeft = currentPosition - scrollAmount;
            // }

            
        // setActiveTab(activeTab-1)
    }
    return (
        <div className="scroll-carousel jobs-carousel">

            <div className="carousel-body overflow-scroll" ref={scrollContainer}>
                
                {props.children}
            </div>
            {controls.show ? <>
            <div className="carousel-nav">
                    <button className="slider-nav-button prev" aria-label="Previous" 
                        onClick={() => scrollLeft()} disabled={activeTab === 0}>
                        <Icon icon="chevron-left" size="24"/>
                    </button>
                    <button className="slider-nav-button next" aria-label="Next" 
                    onClick={() => scrollRight()} disabled={activeTab === controls.panels - 1}>
                        <Icon icon="chevron-right" size="24"/>
                    </button>
                </div>
            <div className="carousel-footer">
                {controls.panels > 1 && 
                <div className="dots">
                    {Array.from(Array(controls.panels)).map((dot, index) => <span key={"dot"+index} className={activeTab === index ? "dot active" : "dot"}/>)}
                    {/* <span className={activeTab === 0 ? "dot active" : "dot"}/>
                    <span className={activeTab === 1 ? "dot active" : "dot"}/> */}
                    {/* <span className={activeTab === 2 ? "dot active" : "dot"}/>
                    <span className={activeTab === 3 ? "dot active" : "dot"}/> */}
                </div> }
            </div>
            </> : <></>}
        </div>
    )
}
export default SavedJobsCarousel