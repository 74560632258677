import React from "react";
import Icon from "../global/Icon";
import Helly from "../../assets/images/profile_helly.jpeg"
import Laura from "../../assets/images/profile_laura.png"

const AboutMe = (props) => {

    return (
        <div className="card about-me-card">
            <button className="button icon-btn edit-btn" aria-label="Edit">
                <Icon icon="edit" size="24"/>
            </button>
            <div className="avatar">
                {props.user && props.user.name === "Laura Perry" ? <img src={Laura} alt=""/> 
                : props.user && props.user.name === "Helly Turner" ? <img src={Helly} alt=""/> 
                : 
                <svg width="98" height="122" viewBox="0 0 98 122" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.45" d="M17.375 32.25C17.375 14.715 31.59 0.5 49.125 0.5C66.66 0.5 80.875 14.715 80.875 32.25C80.875 49.785 66.66 64 49.125 64C31.59 64 17.375 49.785 17.375 32.25ZM0 104.5C0 89.8875 13.3458 74.5833 27.9583 74.5833H70.2917C84.9042 74.5833 97.5 90.8875 97.5 105.5C97.5 105.5 79 121.5 48 121.5C17 121.5 0 104.5 0 104.5Z" fill="#0D0D0D"></path></svg> }
            </div>
            <h1 className="desktop-h3">{props.user ? props.user.name : "Name"}</h1>
            {/* <p>Headline area</p> */}
            <div className="school">
                <h2 className="desktop-h3-regular school-name">{props.user ? props.user.currentJob : "Job job"}</h2>
                {/* <p className="desktop-h4 muted grad-date">Subtitle</p> */}
            </div>
        </div>
    )
}
export default AboutMe