import React, {useState} from "react";

const Accordion = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`accordion-container ${props.modifier}`}>
            <button className="accordion-btn" aria-expanded={open} onClick={() => setOpen(!open)}>
                <span className="accordion-txt" dangerouslySetInnerHTML={{__html: props.label}}>
                    
                </span>
                <span className="icon-24">
                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3124 1.63286L8.59759 8.06646C8.38665 8.24224 8.17571 8.31255 7.99993 8.31255C7.78899 8.31255 7.57805 8.24224 7.40227 8.10161L0.652273 1.63286C0.300711 1.31646 0.300711 0.753958 0.617117 0.437551C0.933523 0.085989 1.49602 0.085989 1.81243 0.402395L7.99993 6.30865L14.1523 0.402395C14.4687 0.085989 15.0312 0.085989 15.3476 0.437551C15.664 0.753958 15.664 1.31646 15.3124 1.63286Z" fill="#60646D"/>
                    </svg>
                </span>
            </button>
            <div className="accordion-body">
                {props.children}
            </div>
        </div>
    )
}
export default Accordion