import React, {useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import careerList from "../../../assets/data/jobTitles.json";
import majors from "../../../assets/data/majors.json";
import goalImage from "../../../assets/images/goals/Goal-progress.png";
import jobImage from "../../../assets/images/goals/Job-Interview.png";
import certficateImage from "../../../assets/images/goals/Graduate-certificate.png";
import studentImage from "../../../assets/images/goals/Resume-profile.png";

const GoalCard = (props) => {
    const [goal, setGoal] = useState({open:false, selected:''})
    const [dreamJob, setDreamJob] = useState({open:false, selected:''})
    const [major, setMajor] = useState({open:false, selected:''});
    const [nextStep, setNextStep] = useState({open:false, selected:''});
    const [jobList, setJobList] = useState(careerList);
    const [majorList, setMajorList] = useState(majors);
    const [showClear, setShowClear] = useState(false);
    const input = useRef();
    const container = useRef();
    const goalOptions = ["find a job","go from being a student to joining the workforce","move forward in my current profession","make a career change","continue my certification path"]

    useEffect(() => {
        let autocomplete = container.current
        document.addEventListener("click", event => {
            if (autocomplete && autocomplete.contains(event.target)) {
                //do nothing
            } else {
                console.log("close", event.target)
                setDreamJob({...dreamJob, open:false})
            }
        })
        document.addEventListener('keydown', event => {
            if (event.key === "Escape") {
                setDreamJob({...dreamJob, open:false})
            }
        })
        return () => {
            document.removeEventListener('click', event => {
                if (autocomplete && autocomplete.contains(event.target)) {
                    //do nothing
                } else {
                    console.log("close", event.target)
                    setDreamJob({...dreamJob, open:false})
                }
            })
            document.removeEventListener('keydown', event => {
                if (event.key === "Escape") {
                    setDreamJob({...dreamJob, open:false})
                }
            })
        }
    },[])

    useEffect(() => {
        //if change my goal, then clear the second input
        if (input.current) {
            input.current.value = ''
        }
    },[goal.selected])

    const onInput = (event, type) => {
        if (type === 'major') {
            setMajor({...major, open:true})
            if (event.target.value.length > 0) {
                
                setShowClear(true)
                transformMajors(event.target.value)
            } else {
                setShowClear(false)
                // setOpen(false)
            }
        } else {
            //assume job
            setDreamJob({...dreamJob, open:true})
            if (event.target.value.length > 0) {
                
                setShowClear(true)
                transformJobs(event.target.value)
            } else {
                setShowClear(false)
                // setOpen(false)
            }
        }
        
    }
    const clearInputFilter = () => {
        input.current.value = ''
        setDreamJob({...dreamJob, selected:''})
        setJobList(careerList)
        setMajorList(majorList)
        setShowClear(false)
        // props.filter('')
        input.current.focus()
    }
    const transformJobs = (str) => {
        let newJobList = careerList.filter(c => c.title !== dreamJob.selected)
        //remove any already selected
        
        let filteredList;
        //filter by input
        if (str.length > 0) {
            filteredList = newJobList.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
            // (post) => {
            //     return post.title.toLowerCase().match(searchValue.toLowerCase());
            //  });
        } else {
            filteredList = newJobList
        }
        
        setJobList(filteredList)
    }
    const transformMajors = (str) => {
        let newList = majors.filter(c => c.title !== major.selected)
        //remove any already selected
        
        let filteredList;
        //filter by input
        if (str.length > 0) {
            filteredList = newList.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
            // (post) => {
            //     return post.title.toLowerCase().match(searchValue.toLowerCase());
            //  });
        } else {
            filteredList = newList
        }
        
        setMajorList(filteredList)
    }
    const addToSelected = (item, type) => {
        input.current.value = item
        if (type === 'major') {
            setMajor({open:false, selected:item})
            transformMajors('')
        } else {
            setDreamJob({open:false,selected:item})
            transformJobs('')
        }
        
        props.personalize()
        // props.filter(item)
        setShowClear(true)
        
    }

    return (
        <div className={`card goal-card ${props.cta ? "dark action-card padding-even" : ""}`}>
            <div className="card-header">
                <div className="flex-row">
                    {props.cta ? 
                        <h2 className="card-title desktop-h3">
                        What's your goal?
                        </h2> 
                        : <h3 className="card-title desktop-h3">
                        What's your goal?
                        </h3> 
                    }
                    
                </div>
                <p className="subtitle-1">Take the first step by personalizing your dashboard.</p>
            </div>
            <div className="card-body">
                <div className="goal-picker-container">
                    <span className="text">I want to use my skills to </span>
                    <div className={goal.open ? "goal-dropdown dropdown open" : "goal-dropdown dropdown"}>
                        <button className="button" aria-expanded={goal.open} onClick={() => setGoal({...goal, open:!goal.open})}>
                            <span className="selected-text">
                                {goal.selected.length > 0 ? goal.selected : <span className="none">select one</span>}
                            </span>
                            <Icon icon="drop-down" size="24"/>
                        </button>
                        {goal.open &&
                        <div className="menu">
                            <ul>
                                {goalOptions.map((opt,ind) => {
                                    return (
                                        <li role="presentation" key={"goalOpt"+ind}>
                                            <button className="drop-item" aria-selected={opt === goal.selected}  onClick={() => setGoal({open:false, selected: opt})}>{opt}</button>
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div> }
                    </div>
                    {/* <span className="text" style={{margin:"0 20px"}}>+</span> */}
                    
                </div>
                {goal.selected.length > 0 ? <>
                {
                     goal.selected === 'go from being a student to joining the workforce' ?
                        <div className="goal-picker-container">
                        <span className="text">my major is </span>
                        <div className={major.open ?  "goal-dropdown dropdown autocomplete open" : "goal-dropdown dropdown autocomplete"} ref={container}>
                            <div className={showClear ? "button with-clear" : "button without-clear"} style={{padding:"0 18px 0 0"}}>
                            <input type="text" 
                                ref={input}
                                aria-label="Search majors"
                                aria-expanded={major.open} 
                                placeholder={"Start typing or select from list"}
                                defaultValue={major.selected.length > 0 ? major.selected : ""}
                                onClick={() => setMajor({...major, open:!major.open})}
                                onChange={(event) => onInput(event, 'major')}
                                />
                                
                                {showClear && <button className={"button small icon-btn clear-icon"} style={showClear ? {opacity:1} : {opacity:0}} aria-label="Clear search" onClick={() => clearInputFilter()}>
                                    <Icon icon="close" size="24"/>
                                </button>}
                                <Icon icon="drop-down" size="24"/>
                            </div>
                            
                            <div className="menu" style={major.open ? null : {display:"none"}}>
                                <ul>
                                    {majorList.length > 0 ?
                                    <>
                                    {majorList.map((job,index) => {
                                        return (
                                            <li role="presentation" key={"jobitem"+index}>
                                                <button className="drop-item" 
                                                aria-selected={major.selected === job.title}
                                                onClick={() => addToSelected(job.title, 'major')}
                                                >{job.title}</button>
                                            </li>
                                        )
                                    })}</>
                                    
                                : <li role={"presentation"}>
                                    <span className="none">No results.</span></li>}
                                    
                                </ul>
                            </div> 
                        </div>
                        </div>
                    : goal.selected === "continue my certification path" ?
                    <div className="goal-picker-container">
                    <span className="text">my next step is </span>
                    <div className={nextStep.open ? "goal-dropdown dropdown open" : "goal-dropdown dropdown"}>
                        <button className="button" aria-expanded={nextStep.open} onClick={() => setNextStep({...nextStep, open:!nextStep.open})}>
                            <span className="selected-text">
                                {nextStep.selected.length > 0 ? nextStep.selected : <span className="none">select one</span>}
                            </span>
                            <Icon icon="drop-down" size="24"/>
                        </button>
                        {nextStep.open &&
                        <div className="menu">
                            <ul>
                                {["CompTia Network+", "CompTia Security+","CompTia Cloud Essentials+","CompTia Project+","IT Specialist Computational Thinking"].map((opt,ind) => {
                                    return (
                                        <li role="presentation" key={"goalOpt"+ind}>
                                            <button className="drop-item" aria-selected={opt === nextStep.selected}  onClick={() => setNextStep({open:false, selected: opt})}>{opt}</button>
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div> }
                    </div>
                    {/* <span className="text" style={{margin:"0 20px"}}>+</span> */}
                    
                </div>
                     : <div className="goal-picker-container">
                     <span className="text">my dream job is to be a(n) </span>
                     <div className={dreamJob.open ?  "goal-dropdown dropdown autocomplete open" : "goal-dropdown dropdown autocomplete"} ref={container}>
                         <div className={showClear ? "button with-clear" : "button without-clear"} style={{padding:"0 18px 0 0"}}>
                         <input type="text" 
                             ref={input}
                             aria-label="Search jobs"
                             aria-expanded={dreamJob.open} 
                             placeholder={"Start typing or select from list"}
                             defaultValue={dreamJob.selected.length > 0 ? dreamJob.selected : ""}
                             onClick={() => setDreamJob({...dreamJob, open:!dreamJob.open})}
                             onChange={(event) => onInput(event, 'job')}
                             />
                             
                             {showClear && <button className={"button small icon-btn clear-icon"} style={showClear ? {opacity:1} : {opacity:0}} aria-label="Clear search" onClick={() => clearInputFilter()}>
                                 <Icon icon="close" size="24"/>
                             </button>}
                             <Icon icon="drop-down" size="24"/>
                         </div>
                         
                         <div className="menu" style={dreamJob.open ? null : {display:"none"}}>
                             <ul>
                                 {jobList.length > 0 ?
                                 <>
                                 {jobList.map((job,index) => {
                                     return (
                                         <li role="presentation" key={"jobitem"+index}>
                                             <button className="drop-item" 
                                             aria-selected={dreamJob.selected === job.title}
                                             onClick={() => addToSelected(job.title, 'job')}
                                             >{job.title}</button>
                                         </li>
                                     )
                                 })}</>
                                 
                             : <li role={"presentation"}>
                                 <span className="none">No results.</span></li>}
                                 
                             </ul>
                         </div> 
                     </div>
                     </div>
                    }
                
                </>  : <></>}
                <div className="image">
                    <img src={goal.selected === 'find a job' ? jobImage : goal.selected === 'go from being a student to joining the workforce' ? studentImage : goal.selected === "continue my certification path" ? certficateImage : goalImage} alt="goal"/>
                </div>
            </div>

        </div>

    )
}
export default GoalCard