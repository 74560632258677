import React, {useEffect, useState} from "react";
import CareerBanner from "./careers/CareerBanner";
import { Link, useParams } from "react-router-dom";
import careers from "../assets/data/careersMenu.json"; //for now
import OccupationCard from "./global/cards/OccupationCard";
import recommended from "../assets/data/recommendedOccupations.json";
import saved from "../assets/data/savedOccupations.json";

const ClusterDetail = (props) => {
    const [pageData, setPageData] = useState({});
    const params = useParams();
    const {clusterId} = params;
    useEffect(() => {
        window.scrollTo(0,0)
    })


    useEffect(() => {
        let newData;

        newData = careers.filter(f => f.code === clusterId)
        setPageData(newData[0])
    },[clusterId, props])
    return ( 
        <main className="careers-main">
            <div className="careers-header intro">
                <CareerBanner key={"banner"+clusterId} code={clusterId} video={pageData.video ? pageData.video : false} title={pageData.title ? pageData.title :"Cluster"} description={pageData.description ? pageData.description :"Description"}/>
                
            </div>
            <section className="cluster-body career-body">
                <div className="career-body-header">
                    <h2 className="desktop-h3 bold">Browse by pathway</h2>
                    <p className="body-text">In each pathway, you’ll find roles with similar goals, outcomes, or required skills.</p>
                </div>
                {/* Map the pathways, get the first three occupations */}
                {pageData.pathways?.map((path, index) => {
                    let count = 0;
                    return (
                        <div className="pathway-group" key={"pathway"+index}>
                            <div className="pathway-group-header">
                                <h3 className="pathway-title desktop-h4 bold">{path.title} ({path.occupations.length})</h3>
                                <Link to={process.env.PUBLIC_URL + "/careers/pathway/" + path.code} className="link-btn">View all</Link>
                            </div>
                            <div className="card-row three-three-three">
                            {path.occupations.map((job, jobindex) => {
                                    if (recommended.includes(job.soc_code) && count < 3) {
                                        count = count + 1;
                                        return (
                                            <OccupationCard key={"job"+job.soc_code+jobindex} data={job} recommended={true} saved={saved.includes(job.soc_code)} />
                                        )
                                    } 
                                    else {return null}
                                })}
                                {path.occupations.filter(f => !recommended.includes(f.soc_code)).map((job, jobindex) => {
                                     if (count < 3) {
                                        count = count + 1;
                                        return (
                                            <OccupationCard key={"job"+job.soc_code+jobindex} data={job} saved={saved.includes(job.soc_code)} />
                                        
                                        ) 
                                    } 
                                    else {return null}
                                })}
                                
                            </div>
                        </div>
                    )
                })}
                

                <div className="quick-facts">
                    <h3 className="desktop-h3 bold">Quick facts:</h3>
                    <div className="quick-facts-grid">
                        {pageData?.quickFacts?.map((fact, index) => {

                            return (
                                <div className="quick-fact card" key={clusterId + "fact"+index}>
                            <h4 className="desktop-h4 bold">{fact.title}</h4>
                            <p className="body-text">{fact.text}</p>
                        </div>
                            )
                        })}
                        
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ClusterDetail