/** @format */

import React, {useEffect, useState} from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';
import GoBack from '../global/GoBack';
import Icon from '../global/Icon';
// import Tabs from '../global/tabs/Tabs';
import TabPanel from '../global/tabs/TabPanel';
import { Tabs, Tab } from "@mui/material"; 
import InsightsGroup from './InsightsGroup';
import { useMediaQuery } from '@mui/material';
import InsightsAccordion from './InsightsAccordion';
import Swoop from '../global/Swoop';
import { createPortal } from 'react-dom';
import InsightsModal from './InsightsModal';
import InsightsDrawer from './InsightsDrawer';
import InsightsHeader from './InsightsHeader';
import InsightsBarModal from './InsightsBarModal';

function Insights(props) {
	const [activeTab, setActiveTab] = useState(0);
	const [partVisible, setPartVisible] = useState(2);
	const [modal,setModal] = useState(false);
	const [mobileModal, setMobileModal] = useState({open:false, activeTab:null})
	const [barModal, setBarModal] = useState({open:false, category:[]});
	const location = useLocation();
	const { from } = location.state ? location.state : '';
    const search = useLocation().search;
	const navigate = useNavigate();

	const isDesktop = useMediaQuery('(min-width:808px)');
	// const isDesktop = true

	useEffect(() => {
		const part = new URLSearchParams(search).get('part');

		if (part) {
			setPartVisible(+part)
		}
	},[])

	const insightsData = [
		{
			title: 'Approaching work',
			type: 'work',
			id: 'insights1',
			categories: [
				{
					title: 'Persistence',
					description:
						'A tendency to persist and complete work tasks, even when faced with obstacles or difficulties, or when success is unlikely.',
					part:1,
					value: 41,
					minimum: {
						title: 'You may lack follow through',
						details:
							'Focuses energy on doable tasks; may lack follow-through in the face of difficulties, obstacles, or when success is unlikely.',
					},
					maximum: {
						title: "You fixate on one task until it's done",
						details:
							'Doggedly continues assigned work even in the face of difficulties and obstacles: may occasionally need help redirecting efforts or prioritizing.',
					},
					meaning: [
						{
							type: 'p',
							content:
								'Your score indicates that you can lose motivation when tasks are overly challenging and success seems unlikely.',
						},
						{
							type: 'p',
							content:
								'You have effective strategies for getting work done, switching between tasks or taking breaks when you feel stuck. However, the risk is that you may give up too easily.',
						},
						{
							type: 'p',
							content:
								'You know when you should take a break, which allows you to come back to a task with fresh eyes and avoid exhaustion.',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content:
								'Persistence is about continuing to push through toward a goal. Review situations when you have not persisted and look for patterns. Wanted to avoid conflict? A little impulsive? Identify your triggers so you can prevent or address them.',
						},
					],
				},
				
				{
					title: 'Attention to detail',
					description:
						'Tends to focus on details, catch errors, and complete all tasks thoroughly and carefully.',
					value: 73,
					part:2,
					minimum: {
						title: 'You ignore "unnecessary" rules',
						details: 'More details here',
					},
					maximum: {
						title: 'You work by the book',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Rule following',
					description:
						'Adheres to rules and regulations, behaves honestly and ethically, and follows set procedures.',
					value: 65,
					part:2,
					minimum: {
						title: 'You ignore "unnecessary" rules',
						details: 'More details here',
					},
					maximum: {
						title: 'You work by the book',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Achievement/Effort',
					description:
						'Strives for success and recognition, to get ahead, and be the best; sets difficult work goals and works hard.',
					value: 46,
					part:2,
					minimum: {
						title: 'You go with the flow',
						details: 'More details here',
					},
					maximum: {
						title: 'You strive for success',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Dependability',
					description:
						'Can be counted on to be on time, fulfill obligations, and meet deadlines.',
					value: 95,
					part:2,
					minimum: {
						title: 'You struggle with deadlines',
						details: 'More details here',
					},
					maximum: {
						title: 'You meet all deadlines',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Initiative',
					description:
						'Willing to take on and enjoys new or additional work responsibilities and challenges.',
					value: 5,
					part:1,
					minimum: {
						title: 'You may lack follow through',
						details: 'More details here',
					},
					maximum: {
						title: "You fixate on one task until it's done",
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				}
			],
		},
		{
			title: 'Relating to people',
			type: 'relate',
			id: 'insights2',
			categories: [
				{
					title: 'Leadership orientation',
					description:
						'Tends to be assertive and to take charge; willingly offering opinions, persuading and inspiring.',
					value: 71,
					part:1,
					minimum: {
						title: 'You thrive in social situations',
						details: 'More details here',
					},
					maximum: {
						title: 'You seek out leadership',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Social Orientation',
					description:
						'Tends to seek out and be comfortable in social situations. Prefers to be surrounded by people and being the focus of attention.',
					value: 76,
					part:1,
					minimum: {
						title: 'You prefer working alone',
						details: 'More details here',
					},
					maximum: {
						title: 'You thrive in social situations',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Cooperation',
					description:
						'Good-natured, approachable, and quick to help others.',
					value: 42,
					part:2,
					minimum: {
						title: 'You focus on your own work',
						details: 'More details here',
					},
					maximum: {
						title: 'You’re quick to help others',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Concern for others',
					description:
						'Pereptive, caring, and in tune with other coworkers’ feelings and personal problems.',
					value: 87,
					part:2,
					minimum: {
						title: 'You’re sometimes insensitive',
						details: 'More details here',
					},
					maximum: {
						title: 'You’re highly empathetic',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						}
					]
				}
			]
		},
		{
			title: 'Dealing with emotions',
			type: 'emotions',
			id: 'insights3',
			categories: [
				{
					title: 'Category name',
					description:
						'Category description goes here',
					value: 37,
					part:1,
					minimum: {
						title: 'Value',
						details: 'More details here',
					},
					maximum: {
						title: "Value",
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						}
					]
				}
			]
		},
		{
			title: 'Thinking styles',
			type: 'thinking',
			id: 'insights4',
			categories: [
				{
					title: 'Category name',
					description:
						'Category description goes here',
					value: 37,
					part:1,
					minimum: {
						title: 'Value',
						details: 'More details here',
					},
					maximum: {
						title: "Value",
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						}
					]
				}
			]
		},
	];
	const handleChange = (event, newValue) => {
        setActiveTab(newValue);
      };

	  const toggleDrawer = (boo) => {
		if (boo) {
			//open
			// navigate(process.env.PUBLIC_URL + '/insights/sub', { replace: false })
		}
		else {
			// navigate(process.env.PUBLIC_URL + '/insights', { replace: false })
		}
	  }
	return (
		<>
		{/* <Swoop swoop={{title:'Work Style Insights', subtitle:'Work Style Insights is an assessment that has been designed to be a reliable, fair, and valid measure of the work styles that contribute to and influence job success. The work styles measured can be thought of as the individual’s typical patterns of thinking, feeling and behaving.',navigation:"/profile"}} /> */}
		<main className={isDesktop ? 'insights-main' : 'insights-main mobile'}>
			{isDesktop ? 
				<div className='insights-container'>
					<InsightsHeader isDesktop={isDesktop}/>
					<section className='insights'>
						<div className='card work-insights'>
					<div className='section-header'>
						<h2 className='desktop-h3 bold'>Results</h2>
						<button className='link-btn' onClick={() => setModal(true)}>About your scores</button>
					</div>
					
						<div className='section-body'>
							<div className='tab-list-container'>
							<Tabs
								value={activeTab}
								onChange={handleChange}
								variant="scrollable"
								scrollButtons
								allowScrollButtonsMobile
								aria-label={"Work Style Insights"}
								>
									<Tab label={<span>Approaching work<span className="hidden-bold">Approaching work</span></span>} disableRipple/>
									<Tab label={<span>Relating to people<span className="hidden-bold">Relating to people</span></span>} disableRipple/>
									<Tab label={<span>Dealing with emotions<span className="hidden-bold">Dealing with emotions</span></span>} disableRipple/>
									<Tab label={<span>Thinking styles<span className="hidden-bold">Thinking styles</span></span>} disableRipple/>
									</Tabs>
											</div>
											{activeTab === 0 ?
												<TabPanel
													label='Approaching work'
													id='insightsTabOne'
												>
													{partVisible === 1 ? <>
														<div className='insights-group-container part-one'>
														{insightsData[0].categories.filter(f => f.part === 1).map((category, index) => {
															return (
																<InsightsGroup
																	type={insightsData[0].type}
																	data={category}
																	first={index===0}
																/>
															);
														})}
														</div>
														<div className='insights-group-container part-two'>
															<div className='part-two-message'>
																<h2 className='desktop-h4'>Complete part 2 of the assessment to view your full results</h2>
																<button className='button medium primary'>Start part 2</button>
															</div>
															{insightsData[0].categories.filter(f => f.part === 2).map((category, index) => {
																return (
																	<InsightsGroup
																		type={insightsData[0].type}
																		data={category}
																		hideData={true}
																	/>
																);
															})}
														</div>
													</>
													
												: <>
													{insightsData[0].categories.map((category, index) => {
														return (
															<InsightsGroup
																type={insightsData[0].type}
																data={category}
																first={index===0}
															/>
														);
													})}
												</>}
													
												</TabPanel>
												: activeTab === 1 ?
												<TabPanel
													label='Relating to people'
													id='insightsTabTwo'
												>
													{partVisible === 1 ? <>
														<div className='insights-group-container part-one'>
														{insightsData[1].categories.filter(f => f.part === 1).map((category, index) => {
															return (
																<InsightsGroup
																	type={insightsData[1].type}
																	data={category}
																	first={index===0}
																/>
															);
														})}
														</div>
														<div className='insights-group-container part-two'>
															<div className='part-two-message'>
																<h2 className='desktop-h4'>Complete part 2 of the assessment to view your full results</h2>
																<button className='button medium primary'>Start part 2</button>
															</div>
															{insightsData[1].categories.filter(f => f.part === 2).map((category, index) => {
																return (
																	<InsightsGroup
																		type={insightsData[1].type}
																		data={category}
																		hideData={true}
																	/>
																);
															})}
														</div>
													</>
													
												: <>
													{insightsData[1].categories.map((category, index) => {
														return (
															<InsightsGroup
																type={insightsData[1].type}
																data={category}
																first={index===0}
															/>
														);
													})}
												</>}
												</TabPanel>
												: activeTab === 2 ?
												<TabPanel
													label='Dealing with emotions'
													id='insightsTabThree'
												>
													{insightsData[2].categories.map((category, index) => {
														return (
															<InsightsGroup
																type={insightsData[2].type}
																data={category}
																first={index===0}
															/>
														);
													})}
												</TabPanel>
												: 
												<TabPanel
													label='Thinking styles'
													id='insightsTabFour'
												>
													{insightsData[3].categories.map((category, index) => {
														return (
															<InsightsGroup
																type={insightsData[3].type}
																data={category}
																first={index===0}
															/>
														);
													})}
												</TabPanel>
											}
										</div>
									
								</div>
					</section>
				</div>
				: 
				<div className={mobileModal.open ? 'insights-container is-mobile level2-open' :'insights-container is-mobile level1-open'}>
					<div className='insights-level1'>
						<InsightsHeader isDesktop={isDesktop}/>
						<section className='insights'>
							<div className='section-header'>
								{/* <h2 className='desktop-h3 bold'>Results</h2> */}
								<button className='link-btn' onClick={() => setModal(true)}>About your scores</button>
							</div>
							<div className='work-insights mobile-group'>
								<div className='section-body mobile'>
									<InsightsAccordion
										type={insightsData[0].type}
										title={insightsData[0].title}
										click={() => {toggleDrawer(true);setMobileModal({open:true,activeTab:insightsData[0]})}}
									>
								
									</InsightsAccordion>
									<InsightsAccordion
										type={insightsData[1].type}
										title={insightsData[1].title}
										click={() => {toggleDrawer(true);setMobileModal({open:true,activeTab:insightsData[1]})}}
									>
										
									</InsightsAccordion>
									<InsightsAccordion
										type={insightsData[2].type}
										title={insightsData[2].title}
										click={() => {toggleDrawer(true);setMobileModal({open:true,activeTab:insightsData[2]})}}
									>
										Block block block
									</InsightsAccordion>
									<InsightsAccordion
										type={insightsData[3].type}
										title={insightsData[3].title}
										click={() => {toggleDrawer(true);setMobileModal({open:true,activeTab:insightsData[3]})}}
									>
										Block block block
									</InsightsAccordion>
								</div>
							</div>
						</section>
					</div>
					<div className='insights-level2'>
					<InsightsDrawer visible={mobileModal.open} data={mobileModal.activeTab} partVisible={partVisible} info={(obj) => setBarModal({open:true,category:obj})} close={() => {toggleDrawer(false);setMobileModal({open:false,activeTab:null})}}/>
					</div>
				</div>
			}
			{barModal.open && createPortal(<InsightsBarModal data={barModal.category} close={() => setBarModal({...barModal, open:false})}/>, document.body)}
			{modal && createPortal(<InsightsModal isDesktop={isDesktop} close={() => setModal(false)}/>, document.body)}
		</main>
		</>
	);
}

export default Insights;
