import React, {useState} from "react";
import { Tabs, Tab } from "@mui/material"; 
import TabPanel from "../global/tabs/TabPanel";
const StudyOptions = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    
    const options = [
        {
            type: "study",
            title: "N10-007: CompTIA Network+ Cert Guide",
            thumbnail: 'https://cdn-tp2.mozu.com/16951-50845/cms/50845/files/7484e93b-b247-4f8e-9a4f-dbbd8fe8f593?max=350&as_parameters&_mzcb=_1706194795380',
            author: {
                avatar: 'https://lh3.googleusercontent.com/brMUWv-6OauWDOupAE4SO-HhAh7vTWu0uRGHXTsTjONifDekiLcm7xrql2VX47ignbdnhbd4JHlIuQyXCxmqY-u_OA=s1280-w1280-h800',
                name: 'uCertify, Pearson'
            },
            price: "139.00"
        },
        {
            type: "study",
            title: "N10-008: Network+ Online Practice Test",
            thumbnail: 'https://cdn-tp2.mozu.com/16951-50845/cms/50845/files/36aa0f2f-a3ed-4bb6-a0a3-aa42309d5237?_mzcb=_1706194795380',
            author: {
                avatar: 'https://pedagoo.com/wp-content/uploads/2021/03/measureup.png',
                name: 'measureup'
            },
            price: "99.00"
        },
        {
            type: "instructor",
            title: "CompTIA Network+: Instructor-led",
            thumbnail: 'https://cdn-tp2.mozu.com/16951-50845/cms/50845/files/009faabd-09b1-417e-aabd-5e650e3a8640?_mzcb=_1706194795380',
            author: {
                avatar: 'https://www.mindhubpro.com/cms/files/graphic-certprep-large-logo-updated.webp',
                name: 'CertPREP Courseware'
            },
            price: "150.00"
        },
        {
            type: "study",
            title: "N10-008 Network+ CyberVista Practice Test",
            thumbnail: 'https://cdn-tp2.mozu.com/16951-50845/cms/50845/files/af6e30cb-52c4-4f4f-8165-d35150fdec98?_mzcb=_1706194795380',
             author: {
                avatar: 'https://www.gartner.com/pi/vendorimages/cybervista_security-awareness-computer-based-training_1598279392576.png',
                name: 'CyberVista'
            },
            price: "129.00"
        },
        {
            type: "self",
            title: "CompTIA Network+: Self-paced",
            thumbnail: 'https://cdn-tp2.mozu.com/16951-50845/cms/50845/files/a5f7b546-5c34-48da-a932-41364093448c?_mzcb=_1706194795380',
            author: {
                avatar: 'https://www.mindhubpro.com/cms/files/graphic-certprep-large-logo-updated.webp',
                name: 'CertPREP Courseware'
            },
            price: "150.00"
        }
    ]
    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
      };

    return (
        <section className=" secondary-tabs tabs-card">
                <div className="tab-list-container">
               <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label={"Courses and exam prep"}
                    >
                    <Tab label={<span>All<span className="hidden-bold">All</span></span>} disableRipple />
                    <Tab label={<span>Instructor-led classes<span className="hidden-bold">Instructor-led classes</span></span>} disableRipple />
                    <Tab label={<span>Self-paced eLearning<span className="hidden-bold">Self-paced eLearning</span></span>} disableRipple />
                    <Tab label={<span>Study guides, practice tests, and more<span className="hidden-bold">Study guides, practice tests, and more</span></span>} disableRipple />
                    </Tabs>
                    </div>
                    <div className="tab-content">
                    {activeTab === 0 ? 
                    <TabPanel
                            label='All'
                            id='allExamPrep'
                        >
                            <div className="secondary-card-list">
                                <div className="card-row study-group show-all">
                                    {options.map((option, index) => {
                                        return (
                                            <div className="card skill-card study-card" key={"studyAll"+index}>
                                                    <div className={"image video"}>
                                                        <img src={option.thumbnail} alt={"thumbnail"}/>
                                                    </div>
                                                    <div className="card-body card-info">
                                                        {option.author.avatar && <div className="avatar">
                                                            <img src={option.author.avatar} alt={option.author.name}/>
                                                        </div>}
                                                        
                                                        <div className="card-info">
                                                            <h3 className="card-title">{option.title}</h3>
                                                            <p className="card-meta">{option.author.name}</p>
                                                            <p className="card-meta">
                                                                WEB PRICE: ${option.price}
                                                            </p>
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    })}
                                
                                </div>
                            </div>
                        </TabPanel>
                    : activeTab === 1 ?  <TabPanel
                            label='Instructor-led classes'
                            id='instructorLed'
                        >
                            <div className="secondary-card-list">
                                <div className="card-row study-group">
                                    {options.filter(f => f.type === 'instructor').map((option, index) => {
                                        return (
                                            <div className="card skill-card study-card" key={"studyAll"+index}>
                                                    <div className={"image video"}>
                                                        <img src={option.thumbnail} alt={"thumbnail"}/>
                                                    </div>
                                                    <div className="card-body card-info">
                                                        {option.author.avatar && <div className="avatar">
                                                            <img src={option.author.avatar} alt={option.author.name}/>
                                                        </div>}
                                                        
                                                        <div className="card-info">
                                                            <h3 className="card-title">{option.title}</h3>
                                                            <p className="card-meta">{option.author.name}</p>
                                                            <p className="card-meta">
                                                                WEB PRICE: ${option.price}
                                                            </p>
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    })}
                                
                                </div>
                            </div>
                            
                        </TabPanel>
                    : activeTab === 2 ? <TabPanel
                            label='Self-paced'
                            id='selfPacedLearning'
                        >
                            <div className="secondary-card-list">
                                <div className="card-row study-group">
                                    {options.filter(f => f.type === 'self').map((option, index) => {
                                        return (
                                            <div className="card skill-card study-card" key={"studyAll"+index}>
                                                    <div className={"image video"}>
                                                        <img src={option.thumbnail} alt={"thumbnail"}/>
                                                    </div>
                                                    <div className="card-body card-info">
                                                        {option.author.avatar && <div className="avatar">
                                                            <img src={option.author.avatar} alt={option.author.name}/>
                                                        </div>}
                                                        
                                                        <div className="card-info">
                                                            <h3 className="card-title">{option.title}</h3>
                                                            <p className="card-meta">{option.author.name}</p>
                                                            <p className="card-meta">
                                                                WEB PRICE: ${option.price}
                                                            </p>
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    })}
                                
                                </div>
                            </div>
                        </TabPanel>
                    :  <TabPanel
                            label='Study'
                            id='studyAndTests'
                        >
                            <div className="secondary-card-list">
                                <div className="card-row study-group">
                                    {options.filter(f => f.type === 'study').map((option, index) => {
                                        return (
                                            <div className="card skill-card study-card" key={"studyAll"+index}>
                                                    <div className={"image video"}>
                                                        <img src={option.thumbnail} alt={"thumbnail"}/>
                                                    </div>
                                                    <div className="card-body card-info">
                                                        {option.author.avatar && <div className="avatar">
                                                            <img src={option.author.avatar} alt={option.author.name}/>
                                                        </div>}
                                                        
                                                        <div className="card-info">
                                                            <h3 className="card-title">{option.title}</h3>
                                                            <p className="card-subtitle author-name">{option.author.name}</p>
                                                            <p className="card-meta">
                                                                WEB PRICE: ${option.price}
                                                            </p>
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    })}
                                
                                </div>
                            </div>
                        </TabPanel>
                    }
                    </div>
               </section>
    )
}
export default StudyOptions