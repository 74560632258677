import React, { useState } from "react";

const FilterMenuType = (props) => {
    const [checked, setChecked] = useState(props.data.selected)

    const handleCheck = (item) => {
        let currentChecked = [...checked]
        if (currentChecked.indexOf(item) > -1) {
            currentChecked.splice(currentChecked.indexOf(item),1)
        } else {
            currentChecked.push(item)
        }
        setChecked(currentChecked)
        props.modify(currentChecked)
    }

    return (
        <div className="filter-body">
            <div className="form-group">
            {/* <p className="group-label" style={{marginBottom:"12px"}}>Job type</p> */}
            <div className="checkbox">
                    <label htmlFor="jobType0">
                        <input type="checkbox" name="jobType" id="jobType0" value="internship" 
                        onChange={() => handleCheck("Internship")}
                        defaultChecked={props.data.selected.includes("Internship")}/>
                        <span>Internship</span>
                    </label>
                </div>
                <div className="checkbox">
                    <label htmlFor="jobType1">
                        <input type="checkbox" name="jobType" id="jobType1" value="contract" 
                        onChange={() => handleCheck("Contract")}
                        defaultChecked={props.data.selected.includes("Contract")}/>
                        <span>Contract</span>
                    </label>
                </div>
                <div className="checkbox">
                    <label htmlFor="jobType2">
                        <input type="checkbox" name="jobType" id="jobType2" value="parttime" 
                        onChange={() => handleCheck("Part time")}
                        defaultChecked={props.data.selected.includes("Part time")} />
                        <span>Part time</span>
                    </label>
                </div>
                <div className="checkbox">
                    <label htmlFor="jobType3">
                        <input type="checkbox" name="jobType" id="jobType3" value="fulltime" 
                        onChange={() => handleCheck("Full time")}
                         defaultChecked={props.data.selected.includes("Full time")}/>
                        <span>Full time</span>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default FilterMenuType