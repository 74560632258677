import React, { useEffect, useRef, useState } from "react";
import Icon from "../global/Icon";
import placeholder from "../../assets/images/comptia/logoaplus.svg";
import StudyOptions from "./StudyOptions";
import certifications from "../../assets/data/certificates.json";
import OverflowList from "../OverflowList";

const PathwayModal = (props) => {
    let allCerts = [...props.certifications]
    const [active, setActive] = useState(props.active ? props.active - 1 : 0);

    const [availableCerts, setAvailableCerts] = useState(props.certifications);
    const content = useRef();


    // useEffect(() => {
    //     if (content.current) {
    //         content.current.addEvenListener("scroll", event => {
    //             console.log(content.current.scrollTop)
    //         })
    //     }
    // },[])

    useEffect(() => {
        let existingCerts = [...props.data.steps];
        let newList = [];

        allCerts.forEach(cert => {
            if (existingCerts.findIndex(f => f.title === cert.title) > -1) {
                //do nothing
            } else {
                newList.push(cert)
            }
        })

        setAvailableCerts(newList)

    },[props.data.steps])


    const scrollStart = (event) => {
        let scrollPos = event.currentTarget.scrollTop

        if (scrollPos > 25) {
            content.current.classList.add("minimize")
        } else {
            content.current.classList.remove("minimize")
        }

    }

    return (
        <div className='modal-overlay'>
        <div className="modal pathway-modal" ref={content}>
            <button className="button icon-btn close-btn" aria-label="close" onClick={props.close}>
                <Icon icon="close" size="24"/>
            </button>
            <div className="modal-header">
                <h1 className="modal-title desktop-h3">
                    Suggested certificates
                </h1>
                <div className="autocomplete-container">
                    <div className="search-input">
                        <span className="search-icon">
                            <Icon icon="search" size="24"/>
                        </span>
                        <input className="gr-input" type="text" placeholder="Search certificates" id="searchCerts"/>
                    </div>
                    <div className="autocomplete-results"></div>
                </div>
                <div className="card-row overflow-scroll">
                    {availableCerts.map((card,index) => {
                       
                        return (
                            <button className={`card pathway-card ${active === index ? "active":""}`} 
                            onClick={() => {setActive(index)}}
                            >
                            {index < 3 &&  <span className="card-label ">
                                Recommended
                            </span> }
                            <div className="image">
                                <img src={card.thumbnail} alt=""/>
                            </div>
                            <div className="card-text">
                                <h3 className="card-title">{card.title}</h3>
                                <p className="issuer">{card.issuer}</p>
                            </div>
                        </button>
                        )
                        })}
                    
                </div>
            </div>
            <div className="modal-body" onScroll={scrollStart}>
                <div className="certificate-info">
                    <div className="certificate-header">
                        <h2 className="certificate-title desktop-h3">
                        {availableCerts[active].issuer.toLowerCase() === 'comptia' ? availableCerts[active].issuer : ""} {availableCerts[active].title}
                        </h2>
                        <button className="button primary small" onClick={() => {props.add(availableCerts[active]);props.close()}}>Add to path</button>
                    </div>
                    <div className="certificate-tagline">
                        <div className="avatar">
                            <img src={availableCerts[active].thumbnail} alt="title title"/>
                        </div>
                        <div className="text">
                            <p>EXAM CODE {availableCerts[active].examCode}</p>
                            <p>{availableCerts[active].shortDesc}</p>
                        </div>
                    </div>
                    <div className="certificate-info-group">
                        <h3 className="desktop-h4 bold">
                            Skills you&rsquo;ll gain with this certificate:
                        </h3>
                        <OverflowList data={availableCerts[active].skills}/>
                        {/* <div className="chip-list">
                        {availableCerts[active].skills.map((skill,index) => {
                            return (
                               <div className="chip-label chip-btn" key={"skill"+index}>
                                {skill}
                            </div> 
                            )
                        }) }
                            
                          
                        </div> */}
                    </div>
                    <div className="certificate-info-group">
                        <h3 className="desktop-h4 bold">
                            Ready to take the test?
                        </h3>
                        <h4 className="body-text bold">Exam description</h4>
                        <p>{availableCerts[active].numExams} exam{+availableCerts[active].numExams > 1 ? "s":""}, 90 minutes {+availableCerts[active].numExams > 1 ? "each":""}</p>
                        <div className="certificate-description" dangerouslySetInnerHTML={{__html: availableCerts[active].examDesc}}>
                            {/* dangerously set html here */}
                        </div>
                        <h4 className="body-text bold">Passing score:</h4>
                        <p>{availableCerts[active].passingScore}</p>
                        <div className="certificate-more-info">
                            <h4 className="body-text">Need more information about this certificate? <a className="body-text" href="https://comptia.org" target={"_blank"}>Visit the {availableCerts[active].issuer.toLowerCase() === "comptia" ? "CompTIA" : "AWS"} website</a></h4>
                            <div className="button-row">
                                <a className="button small light outline" href="https://www.comptia.org/testing/testing-options/take-in-person-exam" target="_blank">Find a test center near me</a>
                                <a className="button small light outline" href="https://www.comptia.org/testing/testing-options/take-online-exam" target="_blank">Online testing options</a>
                            </div>
                        </div>
                    </div>
                    <div className="certificate-info-group">
                        <h3 className="desktop-h4 bold">
                            Courses and exam preparation
                        </h3>
                        <p>Prep successfully by finding the study option that works for you.</p>
                        <StudyOptions/>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    )
}
export default PathwayModal