import React from "react";
import pathwayImg from "../../../assets/images/pathway-cta2.png"
import pathwayGED from "../../../assets/images/pathway-cta-GED.png"
import { Link } from "react-router-dom";
const PathwayCTACard = (props) => {
    

    return (
        
        <div className={`card dark action-card pathway-cta docked-image full-width`}>
            
            <div className="card-header">
                <div className="flex-row">
                    <h2 className="card-title desktop-h3">
                        What's your next step?
                    </h2>
                </div>
                <p className="subtitle-1">Check out all the certification paths your {props.type === 'ged' ? "GED" : "CompTIA A+"} certificate has opened up.</p>
            </div>
                
            <div className="card-body">
                <div className="flex-row">
                    <div className="image">
                        <img src={props.type === 'ged' ? pathwayGED : pathwayImg} alt="cta image alt text"/>
                    </div>
                </div>
            </div>
            <div className="card-footer">
            <Link className="button small primary" to={process.env.PUBLIC_URL + "/pathway?user=" + props.user + "&badge=" + props.type}>Explore certificate paths</Link>
            </div>
            
            
            
        </div>
    )
}
export default PathwayCTACard