import React from "react";
import Img from "../../../assets/images/dashboard-done.png"

const DashboardDoneCard = () => {

    return (
        <div className={`card dark-gradient horizontal-card dashboard-cta`}>
            <div class="card-body">
                <h2 class="card-title desktop-h4 bold">What’s up next?</h2>
                <p class="body-text">Check out the latest jobs or start building some real-world skills.</p>
            </div>
            <div class="image"><img src={Img} alt="" /></div>
        </div>
    )
}

export default DashboardDoneCard