import React, {useEffect, useState, useRef} from "react";
import CareerBanner from "./careers/CareerBanner";
import { Link, useParams } from "react-router-dom";
import OccupationCard from "./global/cards/OccupationCard";
import careers from "../assets/data/careersMenu.json"; //for now
import recommended from "../assets/data/recommendedOccupations.json";
import saved from "../assets/data/savedOccupations.json";

const CareerRecommended = (props) => {
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    useEffect(() => {
        let newData = [];

        
        careers.forEach(cluster => {
            cluster.pathways.forEach(pathway => {

                pathway.occupations.forEach(job => {
                    if (recommended.includes(job.soc_code)) {
                        newData.push(job)
                    }
                })
            })
            
        })
        console.log(newData)
        setPageData(newData)
    },[])

    return (
        <main className="careers-main" style={{paddingTop:"48px"}}>
            <section className="intro">
            
                <h1 className="desktop-h3 bold">Career ideas for biology majors</h1>
                <p className="career-description body-text">Explore careers that are popular for students with the same major as you.</p>
            
                
            </section>
           
            <section className="career-body">
            
                <div className="card-row three-three-three">
                    {pageData.map((job) => {
                        
                        return (
                            <OccupationCard data={job} saved={saved.includes(job.soc_code)} recommended={true}/>
                            
                        )
                    })}

                    
                        
                </div>
            </section>
        </main>
    )
}

export default CareerRecommended