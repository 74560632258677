import React, {useState} from "react";
import Icon from "../Icon";
import { Link } from "react-router-dom";
import altImages from "../../../assets/data/careerImages.json"

const OccupationCard = (props) => {
    const [saved, setSaved] = useState(props.saved ? props.saved : false);

    const fallBackSrc = process.env.PUBLIC_URL + "/images/occupations/placeholder2.jpg"

    const bookmark = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setSaved(!saved)
    }

    let altImage = altImages.filter(f => f.code === props.data.soc_code)

            // if (altImage.length > 0) {
            //     setData(altImage[0].image)
            // } else {
            //     setData(props.image)
            // }

    return (
        <Link className={`card occupation-card ${saved ? "saved" : ""}`} to={process.env.PUBLIC_URL + '/career/'+props.data.soc_code} >
            <button className="button icon-btn bookmark-btn" aria-pressed={saved}
                onClick={(e) => bookmark(e)}>
                <Icon icon={saved ? "bookmark-on":"bookmark-off"} size="24"/></button>
            <div className={"image"}>
                <img src={altImage.length > 0 ? altImage[0].image : "https://cdn.careeronestop.org/OccVids/OccupationVideos/" + props.data.soc_code + ".jpg"} 
                onError={(e)=>{e.target.onError = null; e.target.src = fallBackSrc}}
                    alt={""}/>
                {props.recommended && 
                    <span className="tag" role="img" aria-label="Based on your major">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24244 0C3.25296 0 0 3.25296 0 7.24244C0 8.81192 0.359491 9.96054 0.86804 10.8374C1.36782 11.7054 2.00789 12.2929 2.53398 12.74C2.94608 13.1171 3.19158 13.3801 3.33187 13.6256C3.516 13.9851 3.9018 14.4761 4.48926 14.4761C5.03288 14.4761 5.60281 14.0114 5.43621 13.3538C5.19071 12.3893 4.50679 11.7229 3.9018 11.1793C3.42832 10.7672 2.98115 10.3463 2.65673 9.75888C2.33231 9.17142 2.09557 8.39106 2.09557 7.23367C2.09557 4.39281 4.41911 2.06927 7.25997 2.06927C10.1008 2.06927 12.4244 4.39281 12.4244 7.23367C12.4244 8.39106 12.1964 9.17142 11.8632 9.75888C11.53 10.3463 11.0916 10.7584 10.6181 11.1705L10.5918 11.2056C9.99562 11.7492 9.31171 12.3718 9.07497 13.3626C8.91714 14.0114 9.48707 14.4761 10.0307 14.4761C10.6181 14.4761 11.0039 13.9851 11.1881 13.6256C11.3196 13.3713 11.5651 13.1083 11.986 12.74C12.5121 12.2841 13.1434 11.7054 13.6519 10.8374C14.1605 9.96054 14.5199 8.81192 14.5199 7.24244C14.4761 3.25296 11.2231 0 7.24244 0Z" fill="currentColor"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.61903 17.9132H5.86585C5.29592 17.9132 4.82245 18.3779 4.82245 18.9566C4.82245 19.5353 5.28715 20 5.86585 20H8.61903C9.18895 20 9.66243 19.5353 9.66243 18.9566C9.66243 18.3779 9.19772 17.9132 8.61903 17.9132Z" fill="currentColor"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99562 15.16H4.48049C3.91057 15.16 3.43709 15.6247 3.43709 16.2034C3.43709 16.7821 3.9018 17.2468 4.48049 17.2468H9.99562C10.5655 17.2468 11.039 16.7821 11.039 16.2034C11.039 15.6247 10.5743 15.16 9.99562 15.16Z" fill="currentColor"/>
                        </svg>
                    </span>
                }
            </div>
            <div className="card-info">
                <h4 className="card-title job-title">{props.data.title}</h4>
                <p className="card-meta card-description">Meta line....</p>
            </div>
        </Link>
    )
}
export default OccupationCard