import React, {useEffect, useRef, useState} from "react";
import Accordion from "../global/Accordion";
import CertificatePopup from "./CertificatePopup";

const SuggestedCertificatesAccordion = (props) => {
    let card = props.data;
    const [open, setOpen] = useState(false);
    let accordionLabel = (card, index) => {

        if (index < 2) {
            return (
                `<div className="pathway-card">
                    <span className="card-label ">
                            Recommended
                        </span>
                        <div className="image">
                            <img src=${card.thumbnail} alt=""/>
                        </div>
                        <div className="card-text">
                            <h3 className="card-title">${card.title}</h3>
                            <p className="issuer">${card.issuer}</p>
                        </div>
                    </div>`
            )
        } else {
            return (
                `<div className="pathway-card">
                    
                <div className="image">
                <img src=${card.thumbnail} alt=""/>
            </div>
            <div className="card-text">
                <h3 className="card-title">${card.title}</h3>
                <p className="issuer">${card.issuer}</p>
            </div>
                </div>`
            )
        }
       
    }

    return (
        <div className={`accordion-container suggested-certificate-accordion`}>
            <button className="accordion-btn card pathway-card" aria-expanded={open} onClick={() => setOpen(!open)}>
                <div className="pathway-info">
                    {props.recommended && <span className="card-label ">
                            Recommended
                        </span> }
                        <div className="image">
                            <img src={card.thumbnail} alt=""/>
                        </div>
                        <div className="card-text">
                            <h3 className="card-title">{card.title}</h3>
                            <p className="issuer">{card.issuer}</p>
                        </div>
                    </div>
                <span className="icon-24">
                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3124 1.63286L8.59759 8.06646C8.38665 8.24224 8.17571 8.31255 7.99993 8.31255C7.78899 8.31255 7.57805 8.24224 7.40227 8.10161L0.652273 1.63286C0.300711 1.31646 0.300711 0.753958 0.617117 0.437551C0.933523 0.085989 1.49602 0.085989 1.81243 0.402395L7.99993 6.30865L14.1523 0.402395C14.4687 0.085989 15.0312 0.085989 15.3476 0.437551C15.664 0.753958 15.664 1.31646 15.3124 1.63286Z" fill="#60646D"/>
                    </svg>
                </span>
            </button>
            <div className="accordion-body">
                <div className="certificate-popup certificate-accordion-body">
                {/* <h4 className="desktop-h4 bold">{card.title}</h4> */}
                    <p>{card.shortDesc}</p>
                    <div className="certificate-info-group">
                        <h5 className="desktop-h6 bold">
                            Skills you&rsquo;ll gain with this certificate:
                        </h5>
                        <div className="chip-list">
                            {card.skills.map((item,index) => {
                                return (
                                    <div className="chip-label chip-btn" key={"skill"+index}>
                                        {item}
                                    </div>
                                )
                            })}
                            
                        
                        </div>
                    </div>
                    <div className="button-row">
                        <button className="button small outline light filled" onClick={() => props.more(card)}>See details</button>
                        <button className="button small dark filled" onClick={() => props.add(card)}>Add to path</button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
export default SuggestedCertificatesAccordion