import React, {useState} from "react";
import Header from "../global/Header";
import Footer from "../global/Footer";
import Icon from "../global/Icon";
import { Link, useLocation } from "react-router-dom";
import ToggleChip from "../global/chips/ToggleChip";

const RecruiterHome = (props) => {
    const [filters, setFilters] = useState([
        {title: 'Active', active:true},
        {title: 'Paused', active:true},
        {title: 'Closed', active:true},
        {title: 'Draft', active:true},
        {title: 'Archived', active:true}
    ]);
    const [activeFilter, setActiveFilter] = useState('All')

    const location = useLocation();

    // console.log(location.state)
    const updateFilters = () => {

    }

    const sampleJobPostings = [
        {
            title: 'Marketing Intern',
            location: 'Denver, CO',
            datePosted: 'September 6, 2024',
            status: 'Active'
        },
        {
            title: 'Carpenter Apprentice',
            location: 'Denver, CO',
            datePosted: 'September 6, 2024',
            status: 'Paused'
        },
        {
            title: 'IT intern',
            location: 'Denver, CO',
            datePosted: 'September 6, 2024',
            status: 'Closed'
        },
        {
            title: 'Electrical Apprentice',
            location: '',
            datePosted: 'September 6, 2024',
            status: 'Draft'
        }
    ]

    const todayDate = () => {
        const currentDate = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        return (currentDate.toLocaleDateString('en-us',options))
    }

    return (
        <div className={`page-wrapper horizontal-nav recruiter-home wireframe`} >
            <Header logo={true} auth={true}></Header>
            <main className="">
                <div className="main-header">
                    <div className="flex-row">
                        <h1>Job posts</h1>
                        <Link className="button medium primary" to={process.env.PUBLIC_URL + "/recruiter/add-job"}>Post a job</Link>
                    </div>
                    <div className="flex-row">
                        <div className="search-input">
                            <input type="text" className="gr-input" 
                            aria-label="Search job posts" 
                            placeholder="Search job posts"
                            />
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                        </div>
                    </div>
                    <div className="filter-row flex-row">

                        <div className="chip-list">
                            <button className="chip-btn" aria-pressed={activeFilter === "All"} onClick={() => setActiveFilter("All")}>
                                All
                            </button>
                            {filters.map((filter, index) => {
                                return (
                                    <button className="chip-btn" aria-pressed={activeFilter === filter.title}  onClick={() => setActiveFilter(filter.title)}>
                                        {filter.title.toLowerCase() === 'active' ? 
                                            <span className="dot active"></span> 
                                        : filter.title.toLowerCase() === 'paused' ? 
                                            <span className="dot paused"></span>
                                        : filter.title.toLowerCase() === 'closed' ? 
                                            <span className="dot closed"></span>
                                        : <></>}
                                        {filter.title}
                                    </button>
                                )
                            })}
                            
                        </div>
                        <button className="filter-btn">
                            <svg className="icon-24 MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1tie80k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TuneIcon"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" fill="currentColor"></path></svg>
                            <span>More Filters</span>
                        </button>
                    </div>
                </div>
                <div className="job-posting-table table-container">
                    <table className="skills-table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <div className="checkbox">
                                        <input type="checkbox" />
                                    </div>
                                </th>
                                <th scope="col">
                                    <button className="sort-btn">
                                        Job title
                                        <Icon icon="descending" size="18"/>
                                    </button>
                                    </th>
                                <th scope="col">
                                    <button className="sort-btn">
                                        Location
                                        <Icon icon="sortable" size="18"/>
                                    </button>
                                </th>
                                <th scope="col"><button className="sort-btn">Date posted<Icon icon="sortable" size="18"/></button></th>
                                <th scope="col"><button className="sort-btn">Job status<Icon icon="sortable" size="18"/></button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sampleJobPostings.map((post,index) => {
                                return (
                                    <tr>
                                        <td><div className="checkbox">
                                                <input type="checkbox" />
                                            </div></td>
                                        <th scope="row">{post.title}</th>
                                        <td>{post.location}</td>
                                        <td>{post.datePosted}</td>
                                        <td>
                                            {post.status.toLowerCase() === 'draft' ? 
                                            <span>Draft</span>

                                            : 
                                            <div className="gr-select-container">
                                                <select className="gr-select" defaultValue={filters.findIndex(f => f.title.includes(post.status))}>
                                                    {filters.slice(0,3).map((item,index) => {
                                                        return <option value={index}>{item.title}</option>
                                                    })}
                                                </select>
                                                <Icon icon="drop-down" size="24"/>
                                            </div>
                                            }


                                        </td>
                                    </tr>
                                )
                            })}
                            {location?.state?.post && <tr>
                                        <td><div className="checkbox">
                                                <input type="checkbox" />
                                            </div></td>
                                        <th scope="row">{location.state.post.title}</th>
                                        <td>{location.state.post.location}</td>
                                        <td>{todayDate()}</td>
                                        <td style={{textTransform: 'capitalize'}}>
                                            {location.state.status.toLowerCase() === 'draft' ? <span>Draft</span>
                                            : 
                                            <div className="gr-select-container">
                                                <select className="gr-select" defaultValue={filters.findIndex(f => f.title.includes(location.state.status))}>
                                                    {filters.map((item,index) => {
                                                        return <option value={index}>{item.title}</option>
                                                    })}
                                                </select>
                                                <Icon icon="drop-down" size="24"/>
                                            </div>
                                            }
                                            
                                            </td>
                                    </tr>
                                    }
                        </tbody>
                    </table>
                    

                </div>
                
            </main>
            <Footer puppy={false}/>
        </div>
    )
}
export default RecruiterHome