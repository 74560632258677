import React, {useState} from "react";
import Icon from "../Icon";


const AddEducation = (props) => {
    const [education, setEducation] = useState({school:'',major:'', date:''});
    return (
        <div className='modal-overlay'>
        <div className="modal">
            <button className="button icon-btn close-btn" aria-label="Close" onClick={() => props.close()}>
                <Icon icon="close" size="24"/>
            </button>
            <div className="modal-header">
                <h1 className="modal-title desktop-h3">
                    Add education
                </h1>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label htmlFor="searchSchools">What school do you attend?</label>
                    <div className="autocomplete-container">
                        <div className="search-input">
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                            <input className="gr-input" type="text" placeholder="Search institutions" id="searchSchools"/>
                        </div>
                        <div className="autocomplete-results"></div>
                        {/* <div className="chip-list"></div> */}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="searchSchools">What is your major?</label>
                    <div className="autocomplete-container">
                        <div className="search-input">
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                            <input type="text" className="gr-input" placeholder="Search majors" id="searchSchools"/>
                        </div>
                        
                    </div>
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>When do you plan to graduate?</legend>
                        <div className="flex-row" style={{justifyContent:"flex-start",gap:"8px"}}>
                            <div className="gr-select-container">
                                <select aria-label="Month" className="gr-select">
                                <option hidden={true} value="0na">MM</option>
                                <option disabled={true} value="0">MM</option>
                                    <option value="1">01</option>
                                    <option value="2">02</option>
                                </select>
                                <Icon icon="drop-down" size="18"/>
                            </div>
                            <div className="gr-select-container">
                                <select aria-label="Year" className="gr-select">
                                <option hidden={true} value="0na">YYYY</option>
                                    <option disabled={true} value="0">YYYY</option>
                                    <option value="1">2028</option>
                                    <option value="2">2027</option>
                                    <option value="3">2026</option>
                                </select>
                                <Icon icon="drop-down" size="18"/>
                            </div>
                        </div>
                        
                    </fieldset>
                </div>
                
            </div>
            <div className="modal-footer">
                <button className="button primary medium" onClick={() => props.apply(education)}>
                    Save
                </button>
            </div>
        </div>
        </div>
    )
}
export default AddEducation