import React, {useState} from "react";
import Icon from "../Icon";

const ForageCard = (props) => {
    const [saved, setSaved] = useState(props.saved ? true : props.data.saved ? props.data.saved : false);

    const bookmark = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setSaved(!saved)
    }

    return (
        <div className={`card skill-card ${props.modifier} ${saved ? "saved" : ""}`}>
            <button className="button icon-btn bookmark-btn" aria-pressed={saved}
                onClick={(e) => bookmark(e)}>
                <Icon icon={saved ? "bookmark-on":"bookmark-off"} size="24"/></button>
            <button className="forage-card-inner" onClick={props.click}>
            <div className={"image video"}>
                <img src={props.data.thumbnail} alt={"thumbnail"}/>
                {props.affiliate && <span className="avatar logo" dangerouslySetInnerHTML={{__html: props.data.affiliate}}/>}
                {props.tag && <span className="tag">{props.data.tag}</span>}
                {props.message && <div className="circle message"><Icon icon="chat" size="24"/></div>}
            </div>
            <div className="card-body card-info">
                {props.data.avatar && <div className="avatar">
                    <img src={props.data.avatar} alt={props.data.company}/>
                </div>}
                
                <div className="card-info">
                    <h3 className="card-title">{props.data.title}</h3>
                    <p className="card-subtitle author-name">{props.data.company}</p>
                    <p className="card-meta">
                        <span>{props.data.length}</span>
                        <span>{props.data.tasks} tasks</span>
                        <span>{props.data.category}</span>
                    </p>
                </div>
            </div>
            </button>
        </div>
    )
}
export default ForageCard