import React, {useEffect, useState, useRef} from "react";

import { useLocation, useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";

import fallBack from "../assets/data/user.json";
import JobPreferences from "./jobs/JobPreferences";
import Toast from "./global/Toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import AddEducation from "./global/modals/AddEducation";
import GoalCard from "./global/cards/GoalCard";
import PersonalizingLoader from "./badge/PersonalizingLoader";
import BadgeCTACard from "./global/cards/BadgeCTACard";
import PathwayCTACard from "./global/cards/PathwayCTACard";
import AssessmentMiniCard from "./global/cards/AssessmentMiniCards";
import JobsGroupCard from "./global/cards/JobsGroupCard";
import DashboardCTA from "./dashboard/DashboardCTA";
import skier from "../assets/images/cta/woman-skiing.png";
import womanStudy from "../assets/images/cta/woman-studying.png";
import CareerMiniCard from "./global/cards/CareerMiniCard";
import DashboardDoneCard from "./global/cards/DashboardDoneCard";
import Coachmark from "./global/Coachmark";

const Dashboard = (props) => {
    const [pageType, setPageType] = useState(0);
    const [pageState, setPageState] = useState(0);
    const [user, setUser] = useState(null);
    const [preferencesOpen, setPreferencesOpen] = useState(false);
    const [educationOpen, setEducationOpen] = useState(false);
    // const [shareOpen, setShareOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({show: false, message: ''});
    const [personalizing, setPersonalizing] = useState(false);
    const [personalizedContent, setPersonalizedContent] = useState(false);
    const [tooltip, setTooltip] = useState(false);

    // const [searchParams, setSearchParams] = useSearchParams();
    // const params = useParams();
    const search = useLocation().search;
    // const { userType, majorId, state, courses, workStyle, preferences, savedJobs } = params;
    const loader = useRef(null);

    // let random = Math.random();

    useEffect(() => {
        // const params = new URLSearchParams(search).getAll();
        const name = new URLSearchParams(search).get('user')
        const role = new URLSearchParams(search).get('userType')
        const major = new URLSearchParams(search).get('major')
        const state = new URLSearchParams(search).get('state')
        const preferences = new URLSearchParams(search).get('preferences')
        const savedJobs = new URLSearchParams(search).get('savedJobs')
        const jobRecs = new URLSearchParams(search).get('jobs')
        const workStyleCase = new URLSearchParams(search).get('workStyleCase')
        const badgeShared = new URLSearchParams(search).get("badgeShared")
        const badgeEarned = new URLSearchParams(search).get("badgeEarned")
        const certEarned = new URLSearchParams(search).get("certificateEarned")
        const fromMLM = new URLSearchParams(search).get("fromMLM");
        let assessmentProps = {};

        if (fromMLM) {
            setTimeout(() => setTooltip(true), 1500)
        }
        if (workStyleCase) {
            // let assessmentProps = {part:1,progress:0,resultsReady:false}
            if (workStyleCase === "0") {
                console.log("zero")
                assessmentProps = {part:1,progress:0,resultsReady:false, resultsViewed:false}
            } else if (workStyleCase === "1") {
                assessmentProps = {part:1,progress:50,resultsReady:false, resultsViewed:false}
            } else if (workStyleCase === "2") {
                assessmentProps = {part:1,progress:100,resultsReady:false, resultsViewed:false}
            } else if (workStyleCase === "3") {
                assessmentProps = {part:1,progress:100,resultsReady:true, resultsViewed:false}
            } else if (workStyleCase === "4") {
                assessmentProps = {part:2,progress:50,resultsReady:false, resultsViewed:false}
            } else if (workStyleCase === "5") {
                assessmentProps = {part:2,progress:100,resultsReady:true, resultsViewed:false}
            } else if (workStyleCase === "6") {
                assessmentProps = {part:2,progress:100,resultsReady:true, resultsViewed:true}
            } 
        }

        // console.log(assessmentProps)
        setUser(
            { 
                name: name ? name : state && state === "future" ? "Laura" : "Helly",
                role: role ? role : fallBack[0].role, 
                major:major ? major : null, 
                state: state ? state : name === "Laura" ? "future" : "existing", 
                courses: fallBack[0].courses, 
                workStyle: workStyleCase ? assessmentProps : null, 
                preferences: preferences ? {type: preferences} : {type: [], location: [], title: []},
                savedJobs : savedJobs ? savedJobs : 0,
                jobs: jobRecs ? false : true,
                badge: {
                    badgeEarned: badgeEarned ? badgeEarned : false,
                    badgeShared: false
                },
                pathway: certEarned ? certEarned : ""
            }
        )
        setPageType(certEarned ? 8 : badgeShared && badgeShared==='true' ? 6 : (state === 'future' || name === "Laura") && badgeEarned ? 7 :  savedJobs ? 4 : preferences && major ? 1 : major ? 2 : preferences ? 3 : workStyleCase ? 5 : 0)
        console.log(user)
    },[])

    // useEffect(() => {
    //     random = Math.random();
    // },[loading])


    const applyPref = (pref) => {
        setPreferencesOpen(false)
        setUser({...user, preferences: pref})
            setPageState(1)
            setToast({open:true, message:"Preferences updated"})
            setTimeout(() => {
                setToast({open:false, message:''})
            },4000)   


        // triggerLoading(() => {
            
        //     } 
        // )
        
    }
    const applyEdu = (pref) => {
        setEducationOpen(false)
        setUser({...user, education: pref})
            setPageState(1)
            setToast({open:true, message:"Education details updated"})
            setTimeout(() => {
                setToast({open:false, message:''})
            },4000)   


        // triggerLoading(() => {
            
        //     } 
        // )
        
    }

    const recommendedJobs = [
        {
            title: "Entry Level Electrical Engineer",
            company:"Arcadis",
            avatar: "https://media.licdn.com/dms/image/D560BAQFAyhh51gC9dw/company-logo_100_100/0/1688175751520?e=2147483647&v=beta&t=iK-C4WvTeyM9IFAJqV6veo2QK3h7AdQMPh6czTH4fkQ",
            type: "Full time",
            location: "Boston, MA",
            category: "Engineering",
            posted: "3 months ago"
        },
        {
            title: "Electronics Engineer",
            company:"U.S. Department of Commerce",
            avatar: "https://media.licdn.com/dms/image/C4D0BAQEY8SR1kFutMQ/company-logo_100_100/0/1648577411471?e=2147483647&v=beta&t=0b7hrFw65W2QW__UJpqwS4C4wOEJTEO2v_YSNuXj5FM",
            type: "Full time",
            location: "Boulder, CO",
            category: "Engineering",
            posted: "3 months ago"
        },
        {
            title: "Electrical Designer - Entry",
            company:"Power Design, Inc.",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQE32SZ0OyPrig/company-logo_100_100/0/1688561445332?e=2147483647&v=beta&t=cnY_Eic2qQvTrT919O-m9AiB3r0yE1J_B4EPIrfvaGQ",
            type: "Full time",
            location: "Saint Petersburg, FL",
            category: "Engineering",
            posted: "3 months ago"
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "https://media.licdn.com/dms/image/D560BAQExWVK4Gj-SZw/company-logo_100_100/0/1688476635974?e=2147483647&v=beta&t=quaLK2WCwycr684FFFyE1vs7HCgZ8PJSfEA7kN_G7QY",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago"
        }
    ]
    const recommendedJobsIT = [
        {
            title: "Information Security Analyst",
            company:"Commercial Bank of California",
            avatar: "https://cbcal.com/wp-content/uploads/2023/01/Logo-box_0.png",
            type: "Full time",
            location: "Irvine, CA",
            category: "IT",
            posted: "3 months ago"
        },
        {
            title: "Cyber Security Analyst",
            company:"McLaughlin Research Corporation",
            avatar: "https://media.licdn.com/dms/image/C510BAQG3qidIowPAbg/company-logo_200_200/0/1631348428851?e=2147483647&v=beta&t=0zWeSX809mQRIbJl63qkztfopC3ycKy3bNAUIZRyjW0",
            type: "Full time",
            location: "Newport, RI",
            category: "IT",
            posted: "3 months ago"
        },
        {
            title: "Information Security Analyst",
            company:"The Beneficient Company Group USA LLC",
            avatar: "https://media.licdn.com/dms/image/C4E0BAQF7Lv6tq23AtQ/company-logo_200_200/0/1630609603955/beneficientco_logo?e=2147483647&v=beta&t=Mo29PfpuMSG-Wax91QWsSx_-Vv5f3zlObIAkB40sohg",
            type: "Full time",
            location: "Dallas, TX",
            category: "Engineering",
            posted: "3 months ago"
        },
        {
            title: "Information Security Consultant (Mobile and Web Application Penetration)",
            company:"Tevora",
            type: "Full time",
            location: "Irvine, CA",
            category: "Engineering",
            posted: "3 months ago"
        },
        {
            title: "Information Security Architect",
            company:"TEKsystems",
            type: "Full time",
            location: "Anaheim, CA",
            category: "Engineering",
            posted: "3 months ago"
        },
        {
            title: "Data Architect",
            company:"MDMS Recruiting LLC",
            type: "Full time",
            location: "Tampa, FL",
            category: "IT",
            posted: "3 months ago"
        },
        {
            title: "Systems Administrator",
            company:"Rochester Electronics LLC",
            avatar: "https://media.licdn.com/dms/image/C560BAQGVMxlNNcD0GA/company-logo_200_200/0/1648565359565/rochester_electronics_logo?e=2147483647&v=beta&t=fEkad9yJOkxMz80WjKq7GB7wf2X-zjQxf6ydQyMtM7A",
            type: "Full time",
            location: "Newburyport, MA",
            category: "IT",
            posted: "3 months ago"
        },
        {
            title: "IT Manager",
            company:"Simply Biotech",
            avatar: "https://media.licdn.com/dms/image/C560BAQF14F98fHv4Mg/company-logo_200_200/0/1630672183256/simply_biotech_logo?e=2147483647&v=beta&t=6AlFDnL1cpH4ukNXjqUwz3kEc740DoIax_0tH4pvllQ",
            type: "Full time",
            location: "Vista, CA",
            category: "IT",
            posted: "3 months ago"
        }
    ]

    const compTIACourses = [
        {
            type:"skill",
            title:"CompTIA Security+ 601",
            length:"1.5 hours",
            level: "All levels",
            cost:"Free",
            thumbnail:"./images/comptia/comptia-security-601.png"
        },
        {
            type:"skill",
            title:"Microsoft Cybersecurity Architect",
            length:"13 hours",
            contents:"171 lectures",
            cost:"Free",
            thumbnail:"./images/comptia/microsoft-cybersecurity-architect.png"
        },
        {
            type:"skill",
            title:"CompTIA Security Plus",
            length:"4 hours",
            contents:"130 lectures",
            level: "All levels",
            source: "Oak Academy",
            cost:"Free",
            thumbnail:"./images/comptia/comptia-security-plus.png"
        },
        {
            type:"skill",
            title:"TOTAL: Cloud Computing / CompTIA Cloud+ Cert. (CVO-003)",
            length:"15 hours",
            contents:"130 lectures",
            level: "All levels",
            cost:"Free",
            thumbnail:"https://img-c.udemycdn.com/course/750x422/5426372_c813_2.jpg"
        },
        {
            type:"skill",
            title:"CompTIA Linux+ Certification | CompTIA Linux XKO-005 Lab",
            length:"11 hours",
            contents:"82 lectures",
            level: "All levels",
            cost:"Free",
            thumbnail:"https://img-c.udemycdn.com/course/240x135/5171394_15c3_3.jpg"
        },
        {
            type:"skill",
            title:"TOTAL: CompTIA A+ Certification (220 - 1102)",
            length:"13 hours",
            contents:"42 lectures",
            level: "All levels",
            cost:"Free",
            thumbnail:"https://img-c.udemycdn.com/course/240x135/3870424_3037_2.jpg"
        },
        {
            type:"skill",
            title:"CompTIA Network+ (N10-008)",
            length:"26 hours",
            contents:"179 lectures",
            level: "All levels",
            cost:"Free",
            thumbnail:"https://img-c.udemycdn.com/course/240x135/4223486_9c44.jpg"
        }
    ]

    const personalize = () => {

        setPersonalizing(true)
        if (window.innerWidth < 807) {
            document.body.style.overflow = 'hidden'
        }

        setTimeout(() => {
            setPersonalizing(false);
            document.body.removeAttribute('style')
            setPersonalizedContent(true)}, 2000)

    }

    return (
        
            <>
                
                <main>
                    {tooltip && <Coachmark position={"top-right"} title={"Heads up!"} body={"You can use your existing Pearson account to log into Job Match at any time."} click={() => setTooltip(false)}/>}
                
                {/* hide if major exists, pref exists, and report viewed and 0 saved jobs */}
                {user?.major && user?.preferences.type?.length > 0 && user?.workStyle?.resultsViewed && !user.savedJobs ? <>
                    <section className="intro" >
                        <DashboardDoneCard/>
                    </section>
                </>
                :
                <section className="intro" >
                {/* <h1 className="page-title desktop-h1 bold">{pageType === 8 ? `Congratulations on earning your badge!` : user &&  user.badge.badgeEarned ? "Congratulations!" : user ? "Welcome, " + user.name + "!" : "Welcome!"}</h1> */}
                
                
                    {user ?
                        <div style={{position:"relative",minHeight:`${user.pathway ? "270px" :"360px"}`}}>

                            {
                                pageType === 8 ? 
                                <TransitionGroup>
                                    <CSSTransition key={"ctacard"+pageType+pageState} timeout={1000} classNames="ctaCard">
                                        {/* <PathwayCTACard type={user.pathway}/> */}
                                        <BadgeCTACard type={user.pathway} badgeShared={user.badge.badgeShared} user={user.name ? user.name : "Laura"} share={() => {setPageState(1);setUser({...user, badge: {badgeShared:true,badgeEarned:true}})}} />
                                    </CSSTransition>
                                </TransitionGroup>
                               : pageType === 7 ?
                                <TransitionGroup>
                                    <CSSTransition key={"ctacard"+pageType + pageState} timeout={1000} classNames="ctaCard">
                                        <BadgeCTACard badgeShared={user.badge.badgeShared} user="Laura" share={() => {setPageState(1);setUser({...user, badge: {badgeShared:true,badgeEarned:true}})}} />
                                    </CSSTransition>
                                </TransitionGroup>
                               : pageType === 6 ? 
                                <TransitionGroup>
                                    <CSSTransition key={"ctacard"+pageType + pageState} timeout={1000} classNames="ctaCard">
                                        <GoalCard cta={true} personalize={personalize}/>
                                    </CSSTransition>
                                </TransitionGroup>
                               :  
                               
                                <DashboardCTA savedJobs={user.savedJobs} workStyle={user.workStyle} major={user.major} preferences={user.preferences.type}
                                    openPreferences={() => setPreferencesOpen(true)}
                                    openEducation={() => setEducationOpen(true)}/>
                                  
                                
                            }
                        </div>
                    : <></>}
                   
                </section> }
               
                {(pageType === 8 && user.pathway === 'ged') &&
                <section>
                   <PathwayCTACard type={user.pathway} user={user.name ? user.name : "Laura"}/>
                </section> }
                
                {(pageType === 8 && user.pathway === 'Aplus') &&
                <section>
                   <PathwayCTACard type={user.pathway} user={user.name ? user.name : "Laura"}/>
                </section> }
                {pageType === 7 && 
                <section>
                    <TransitionGroup>
                        <CSSTransition key={"goalcard"} timeout={1000} classNames="ctaCard">
                            <GoalCard cta={false} personalize={personalize}/>
                        </CSSTransition>
                    </TransitionGroup>
                </section>
                
                }
                
                {   
                user && user.state === "future" && user.badge.badgeEarned ? 
                    <>
                    <section>
                        <div className="card-row">
                            <JobsGroupCard size="three-col" major={true} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobsIT,...recommendedJobs]}/>    
                        </div>
                        <div className="card-row">
                            <div className="card">
                                <div className="card-header">
                                    <div className="flex-row">
                                        <h3 className="mobile-h3">CompTIA Security courses</h3>
                                        {/* <button className="button link-btn">
                                        All video learning
                                    </button> */}
                                    </div>
                                
                                    <p className="mobile-h6">Check out these CompTIA Security courses to keep your learning going</p>
                                </div>
                                <div className="card-body">
                                <div className="card-row skills-group">
                                    {compTIACourses.slice(0,4).map((course, index) => {
                                        return (
                                            <div className="card video-card" key={"comptia"+index}>
                                                <div className="image video">
                                                    <img src={course.thumbnail} alt="placeholder"/>
                                                    <div className="play-icon">
                                                        <svg width="22" height="22" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><g filter="url(#filter0_d_2120_12483)"><path d="M53.3136 39.891L17.8459 60.5101C14.836 62.2582 10.9731 60.1415 10.9731 56.6175V15.3793C10.9731 11.8609 14.8304 9.73868 17.8459 11.4923L53.3136 32.1114C53.9983 32.503 54.5675 33.0691 54.9634 33.7523C55.3592 34.4354 55.5677 35.2113 55.5677 36.0012C55.5677 36.7912 55.3592 37.567 54.9634 38.2502C54.5675 38.9333 53.9983 39.4994 53.3136 39.891Z" fill="white"></path></g><defs><filter id="filter0_d_2120_12483" x="0.973145" y="0.864868" width="64.5947" height="70.2703" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="5"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2120_12483"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2120_12483" result="shape"></feBlend></filter></defs></svg>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="card-info">
                                                        <h3 className="card-title">{course.title}</h3>
                                                        <p className="card-meta">
                                                            <span>{course.length}</span>
                                                            {course.contents && <span>{course.contents}</span>}
                                                            {course.level && <span>{course.level}</span>}
                                                            {course.source && <span>{course.source}</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                
                </> 
                : user?.major && user?.preferences?.type.length > 0 ? 
                        
                <>
                    {user.savedJobs > 0 && !user?.workStyle?.resultsViewed ? 
                        <section>
                            
                            <div className={"card-row one-two"}>
                            <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={false}/>
                                <JobsGroupCard size="two-col" major={true} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs]}/>    
                            </div>
                            <div className="card-row three three three">
                                <CareerMiniCard type={4} recommendationType="preferences" />
                                <CareerMiniCard type={5} recommendationType="major" />
                                <CareerMiniCard type={6} recommendationType="major" />
                            </div>
                        </section>
                    : user?.workStyle?.resultsViewed ? 
                    <section>
                        <div className={"card-row"}>
                            <JobsGroupCard size="three-col" major={true} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>    
                        </div>
                        <div className="card-row three-three-three">
                            <CareerMiniCard type={4} recommendationType="preferences"/>
                            <CareerMiniCard type={5} recommendationType="major"/>
                            <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={true}/>
                            {/* {part:2,progress:100,resultsReady:true, resultsViewed:true} */}
                        </div>
                    </section>
                    :
                    <section>
                        <div className={"card-row"}>
                            <JobsGroupCard size="three-col" major={true} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>    
                        </div>
                        <div className="card-row three-three-three">
                            <CareerMiniCard type={4} recommendationType="preferences" />
                            <CareerMiniCard type={5} recommendationType="major" />
                            <CareerMiniCard type={6} recommendationType="major" />        
                        </div>
                    </section>
                    }
                
                
                </>
                : user?.major && user?.preferences.type.length === 0 ? <>
                    {user.savedJobs ? 
                        <section>
                            <div className={"card-row one-two"}>
                            <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                                <JobsGroupCard size="two-col" major={true} preferences={false} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>    
                            </div>
                            <div className="card-row three-three-three">
                                <CareerMiniCard type={4} recommendationType="major" />
                                <CareerMiniCard type={5} recommendationType="major" />
                                <CareerMiniCard type={6} recommendationType="major" />
                            </div>
                        </section>
                        : user.workStyle?.resultsViewed ? 
                        <section>
                            <div className="card-row three-three-three">
                                <CareerMiniCard type={4} recommendationType="major" />
                                <CareerMiniCard type={5} recommendationType="major" />
                                <CareerMiniCard type={6} recommendationType="major" />
                            </div>
                            <div className={"card-row two-one"}>
                                <JobsGroupCard size="two-col" major={true} preferences={false} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>    
                                <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                            </div>
                        </section>
                        : user.workStyle ? 
                        <section>
                            <div className={"card-row three-three-three"}>
                                <CareerMiniCard type={4} recommendationType="major" />
                                <CareerMiniCard type={5} recommendationType="major" />
                                <CareerMiniCard type={6} recommendationType="major" />
                            </div>
                            <div className="card-row">
                                <JobsGroupCard size="three-col" major={true} preferences={false} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>                                    
                            </div>
                        </section>
                        : <section>
                            <div className="card-row three-three-three">
                            <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                            <CareerMiniCard type={4} recommendationType="major" />
                            <CareerMiniCard type={5} recommendationType="major" />
                            
                            </div>
                            <div className="card-row">
                                <JobsGroupCard size="three-col" major={true} preferences={false} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>                                    
                            </div>
                        </section>
                    }
                </>
                : user?.preferences.type.length > 0 ? <>
                    {
                        user.workStyle?.resultsViewed ? 
                            <>
                                {user.jobs ?
                                <section>
                                    <div className={"card-row"}>
                                        <JobsGroupCard size="three-col" major={false} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>    
                                    </div>
                                    <div className="card-row three-three-three">
                                        <CareerMiniCard type={5} recommendationType="preferences"/>
                                        <CareerMiniCard type={6} recommendationType="preferences"/>
                                        <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                                    </div>
                                    
                                </section>
                                : 
                                <section>
                                    <div className="card-row three-three-three">
                                        <CareerMiniCard type={5} recommendationType="preferences"/>
                                        <CareerMiniCard type={6} recommendationType="preferences"/>
                                        <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                                        
                                    </div>
                                    <div className="card-row">
                                        <div className="card horizontal-card empty-state">
                                    
                                            <div className="card-body">
                                                    <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                                    <p className="body-text">Broaden your <button className="link-btn" onClick={() => setPreferencesOpen(true)}>job preferences</button> for more recommendations.</p>
                                            </div>
                                            <div className="image">
                                                <img src={skier} alt="skier" style={{objectPosition: "50% 15%"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                }
                            </>
                        : user.savedJobs ? <>
                            <section>
                                <div className="card-row one-two">
                                <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                                <JobsGroupCard size="two-col" major={false} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>  
                                </div>
                                <div className="card-row three-three-three">
                                        
                                        <CareerMiniCard type={5} recommendationType="preferences"/>
                                        <CareerMiniCard type={6} recommendationType="preferences"/>
                                        <CareerMiniCard type={4} recommendationType="preferences"/>
                                    </div>
                            </section>
                        </>
                        : user.workStyle ? <>
                        
                            {user.jobs ? 
                                <section>
                                    <div className="card-row">
                                        <JobsGroupCard size="three-col" major={false} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>                                    
                                    </div>
                                    <div className={"card-row three-three-three"}>
                                        <CareerMiniCard type={5} recommendationType="preferences"/>
                                        <CareerMiniCard type={6} recommendationType="preferences"/>
                                        <CareerMiniCard type={4} recommendationType="preferences"/>
                                    </div>
                                    
                                </section> 
                                : 
                                <section>
                                    <div className="card-row three-three-three">
                                        <CareerMiniCard type={5} recommendationType="preferences"/>
                                        <CareerMiniCard type={6} recommendationType="preferences"/>
                                        <CareerMiniCard type={4} recommendationType="preferences"/>
                                    </div>
                                    <div className="card-row">
                                        <div className="card horizontal-card empty-state">
                                        
                                            <div className="card-body">
                                                    <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                                    <p className="body-text">Broaden your <button className="link-btn" onClick={() => setPreferencesOpen(true)}>job preferences</button> for more recommendations.</p>
                                            </div>
                                            <div className="image">
                                                <img src={skier} alt="skier" style={{objectPosition: "50% 15%"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                }
                        </>
                        : <section>
                            <div className="card-row one-two">
                                <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                                {user.jobs ? 
                                    <JobsGroupCard size="two-col" major={false} preferences={true} addMajor={() => setEducationOpen(true)} addPref={() => setPreferencesOpen(true)} jobs={[...recommendedJobs,...recommendedJobs]}/>  
                                    :  <div className="card vertical-card empty-state">
                                            <div className="image">
                                                <img src={skier} alt="skier" />
                                            </div>
                                            <div className="card-body">
                                                    <h3 className="card-title desktop-h2 bold">Jobs & internships for you</h3>
                                                    <p className="body-text">Broaden your <button className="link-btn " onClick={() => setPreferencesOpen(true)}>job preferences</button> for more recommendations.</p>
                                            </div>
                                            
                                        </div>
                                }                                  
                            </div>
                            <div className="card-row three-three-three">   
                                <CareerMiniCard type={5} recommendationType="preferences"/>
                                <CareerMiniCard type={6} recommendationType="preferences"/>
                                <CareerMiniCard type={4} recommendationType="preferences"/>
                            </div>
                            
                        </section>
                    }
                </>
                : <>
                {/* no pref, no major */}
                    {
                        user?.workStyle?.resultsViewed ? 
                        <section>
                            <div className="card-row three-three-three">
                                <CareerMiniCard type={2} />
                                <CareerMiniCard type={3} />
                                <CareerMiniCard type={1}/>
                            </div>
                            <div className="card-row two-one">
                                <div className="card vertical-card empty-state">
                                    <div className="image">
                                        <img src={womanStudy} alt="woman studying"/>
                                    </div>
                                    <div className="card-body">
                                            <h3 className="card-title desktop-h2 bold">Jobs & internships for you</h3>
                                            <p className="body-text">Add <button className="link-btn" onClick={() => setEducationOpen(true)}>major</button> & <button className="link-btn" onClick={() => setPreferencesOpen(true)}>preferences</button> to get recommendations.</p>
                                    </div>
                                    
                                </div>
                                <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                            </div>
                        </section>
                        : user?.savedJobs ? 
                            <section>
                                <div className="card-row three-three-three">
                                <AssessmentMiniCard part={user.workStyle?.part} progress={user.workStyle?.progress} resultsReady={user.workStyle?.resultsReady} resultsViewed={user.workStyle?.resultsViewed}/>
                                <CareerMiniCard type={1}/>
                                <CareerMiniCard type={2}/>
                            </div>
                                <div className="card-row">
                                    <div className="card horizontal-card empty-state">
                                        
                                        <div className="card-body">
                                                <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                                <p className="body-text">Add <button className="link-btn" onClick={() => setEducationOpen(true)}>major</button> & <button className="link-btn" onClick={() => setPreferencesOpen(true)}>preferences</button> to get recommendations.</p>
                                        </div>
                                        <div className="image">
                                            <img src={womanStudy} alt="woman studying"/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        : user?.workStyle ? <section>
                            <div className="card-row three-three-three">
                                {/* <AssessmentMiniCard part={user?.workStyle?.part} progress={user?.workStyle?.progress} resultsReady={user?.workStyle?.resultsReady} resultsViewed={user?.workStyle?.resultsViewed}/> */}
                                <CareerMiniCard type={2} />
                                <CareerMiniCard type={3} />
                                <CareerMiniCard type={1} />
                            </div>
                            <div className="card-row">
                                <div className="card horizontal-card empty-state">
                                    
                                    <div className="card-body">
                                            <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                            <p className="body-text">Add <button className="link-btn" onClick={() => setEducationOpen(true)}>major</button> & <button className="link-btn" onClick={() => setPreferencesOpen(true)}>preferences</button> to get recommendations.</p>
                                    </div>
                                    <div className="image">
                                        <img src={womanStudy} alt="woman studying"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : <section>
                            <div className="card-row three-three-three">
                                <AssessmentMiniCard part={user?.workStyle?.part} progress={user?.workStyle?.progress} resultsReady={user?.workStyle?.resultsReady} resultsViewed={user?.workStyle?.resultsViewed}/>
                                <CareerMiniCard type={1} />
                                <CareerMiniCard type={2} />
                                {/* <CareerMiniCard type={3}/> */}
                            </div>
                            <div className="card-row">
                                <div className="card horizontal-card empty-state">
                                    
                                    <div className="card-body">
                                            <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                            <p className="body-text">Add <button className="link-btn" onClick={() => setEducationOpen(true)}>major</button> & <button className="link-btn" onClick={() => setPreferencesOpen(true)}>preferences</button> to get recommendations.</p>
                                    </div>
                                    <div className="image">
                                        <img src={womanStudy} alt="woman studying"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                
                </> 
                }
                
                {(personalizing && window.innerWidth < 807) && <>
                <div className="modal-overlay personalizing-modal" ref={loader}>
                    <TransitionGroup>
                        <CSSTransition key={"personalizing"+pageType} timeout={1000}  classNames="personalizing">
                            <PersonalizingLoader />
                        </CSSTransition>
                    </TransitionGroup></div></>}
                {preferencesOpen && createPortal(<JobPreferences preferences={user.preferences} close={() => setPreferencesOpen(false)} apply={(pref) => applyPref(pref)} major={user.major?.length > 0}/>, document.body)}
                {educationOpen && createPortal(<AddEducation major={user.major} close={() => setEducationOpen(false)} apply={(pref) => applyEdu(pref)} />, document.body)}
                {/* {shareOpen && createPortal(<ShareBadge share={() => {setPageState(1);setUser({...user, badge: {badgeShared:true,badgeEarned:true}})}} close={() => setShareOpen(false)}/>, document.body)} */}
                            
                {toast.open && <Toast message={toast.message}/> }
                </main>
            </>
    )
}

export default Dashboard