import React from "react";
import placeholder from "../../assets/images/Graph.png"
import Icon from "../global/Icon";
import jobsIcon from "../../assets/icons/icon-numberjobs-white.png";
const FastFacts = (props) => {

    return (
        <div className="card career-fast-facts">
            <h4 className="desktop-h3">{props.data.title}</h4>
            <h5 className="fast-facts-title">Fast facts</h5>
            <div className="card-body">
                <div className="salary-chart">
                    <h6 className="desktop-h5 bold">Salary range</h6>
                    <div className="salary-chart-render">
                        <img src={placeholder} alt="placeholder curve"/>
                        <span className="bottom-10">${props.data.salary.low.toLocaleString("en-US")}</span>
                        {/* <span className="average">${props.data.salary.annualMedian.toLocaleString("en-US")}</span> */}
                        <span className="top-10">${props.data.salary.high.toLocaleString("en-US")}</span>
                
                    </div>
                    {/* <p className="salary-caption">Estimated median salary: ${props.data.salary.annualMedian.toLocaleString("en-US")} annually or ${props.data.salary.horlyMedian} hourly</p> */}
                </div>
                <div className="facts-row">
                    <div>
                        <h6 className="desktop-h5 bold">Growth potential</h6>
                        <p className="body-text">{props.data.growth} within the next 10 years</p>
                    </div>
                    <div>
                        <h6 className="desktop-h5 bold">Number of jobs</h6>
                        <p className="body-text">{props.data.jobs}</p>
                    </div>

                </div>
            </div>
            <div className="card-footer">
                <a className="button link-btn btn-centered" href={"https://achieve.pearsonct.design/apps/achieve-plus-mvp/careers/"+props.data.code}>View more</a>
            </div>
        </div>
    )
}

export default FastFacts