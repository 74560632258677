import React, {useEffect, useRef, useState} from "react";
import careers from "../../../assets/data/careersMenu.json";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import recommendedDark from "../../../assets/images/menu/recommended-occupations-dark.png";
import savedJobsDark from "../../../assets/images/menu/saved-occupations-dark.png";
import recommendedLight from "../../../assets/images/menu/recommended-occupations-light.png";
import savedJobsLight from "../../../assets/images/menu/saved-occupations-light.png";

const Menu = (props) => {
    const [selected, setSelected] = useState({cluster:'',pathway:''})
    const menu = useRef();
    const pathways = useRef();
    const jobs = useRef();
    useEffect(() => {
        //push focus 
        menu.current.focus()
    },[])
    //on
    const selectCluser = (code) => {
        setSelected({cluster: code, pathway:''})
        //push focus into that row
        setTimeout(() => {pathways.current.querySelector('a').focus()}, 300)
        
    }
    const selectPathway = (code) => {
        setSelected({...selected, pathway: code})
        //push focus into that row
        setTimeout(() => {jobs.current.querySelector('a').focus()}, 300)
        
    }

    return (
        <div className="megamenu-menu" id="careers_menu" tabIndex={"-1"} ref={menu} 
        onMouseLeave={() => props.close()}
        >
            <div className="panel visible">
                <div className="panel-header">
                    <h3 className="panel-title">Career clusters</h3>
                </div>
                
                <ul>
                    {careers.map((cluster, index) => {
                        return (
                            <li className={selected.cluster === cluster.code ? "megamenu-item selected" :"megamenu-item"} key={"cluster"+cluster.code} onMouseEnter={() => setSelected({cluster: cluster.code, pathway:''})}>
                                <Link to={process.env.PUBLIC_URL + "/careers/" + cluster.code} onClick={() => props.close()}>{cluster.title}</Link>
                                <button className="button icon-btn" aria-label={"More " + cluster.title} aria-expanded={selected.cluster === cluster.code} onClick={() => selectCluser(cluster.code)}>
                                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M11.3136 5.55459C11.1574 5.39838 10.9041 5.39838 10.7479 5.55459L9.89938 6.40312C9.74317 6.55933 9.74317 6.81259 9.89938 6.9688L14.5663 11.6357L9.89938 16.3026C9.74317 16.4588 9.74317 16.7121 9.89938 16.8683L10.7479 17.7168C10.9041 17.873 11.1574 17.873 11.3136 17.7168L17.1119 11.9186C17.1216 11.9088 17.1308 11.8986 17.1393 11.8882C17.2675 11.7311 17.2583 11.4993 17.1119 11.3529L11.3136 5.55459Z"/>
                                    </svg>
                                </button>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
            <div className={selected.cluster.length > 0 ? "panel visible" : "panel"} ref={pathways}>
                <div className="panel-header">
                    <h4 className="panel-title">Pathways</h4>
                </div>
                <ul>
                    {selected.cluster.length > 0 && careers.filter(f => f.code === selected.cluster)[0].pathways.map((path, index) => {
                        return (
                            <li className={selected.pathway === path.code ? "megamenu-item selected": "megamenu-item"} key={"path"+path.code} onMouseEnter={() => setSelected({...selected, pathway: path.code})}>
                                <Link to={process.env.PUBLIC_URL + "/careers/pathway/" + path.code} onClick={() => props.close()}>{path.title}</Link>
                                <button className="button icon-btn" aria-label={"More " + path.title} aria-expanded={selected.pathway === path.code} onClick={() => selectPathway(path.code)}>
                                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M11.3136 5.55459C11.1574 5.39838 10.9041 5.39838 10.7479 5.55459L9.89938 6.40312C9.74317 6.55933 9.74317 6.81259 9.89938 6.9688L14.5663 11.6357L9.89938 16.3026C9.74317 16.4588 9.74317 16.7121 9.89938 16.8683L10.7479 17.7168C10.9041 17.873 11.1574 17.873 11.3136 17.7168L17.1119 11.9186C17.1216 11.9088 17.1308 11.8986 17.1393 11.8882C17.2675 11.7311 17.2583 11.4993 17.1119 11.3529L11.3136 5.55459Z"/>
                                    </svg>
                                </button>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
            <div className={selected.pathway.length > 0 ? "panel visible" : "panel"} ref={jobs}>
                <div className="panel-header">
                    <h5 className="panel-title">Occupations</h5>
                </div>
                <ul>
                    {selected.pathway.length > 0 && careers.filter(f => f.code === selected.cluster)[0].pathways.filter(g => g.code === selected.pathway)[0].occupations.map((job, index) => {
                        return (
                            <li className="megamenu-item" key={"path"+job.soc_code+index}>
                                <Link to={process.env.PUBLIC_URL + "/career/" + job.soc_code} onClick={() => props.close()}>{job.title}</Link>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
            <div className="panel panel-small visible">
                <Link to={process.env.PUBLIC_URL + "/careers/recommended"} className="card link-card" onClick={() => props.close()}>
                    <div className="image">
                        <picture>
                            {/* <!-- User prefers light mode: --> */}
                            <source srcset={recommendedLight} media="(prefers-color-scheme: light)"/>

                            {/* <!-- User prefers dark mode: --> */}
                            <source srcset={recommendedDark}  media="(prefers-color-scheme: dark)"/>

                            {/* <!-- User has no color preference: --> */}
                            <img src={recommendedLight} alt="alt text here"/>
                        </picture>
                    </div>
                    <div className="card-body">
                        <h3 className="card-title">Ideas for biology majors</h3>
                        <p className="body-text caption">Explore popular careers for your major.</p>
                    </div>
                </Link>
                <Link to={process.env.PUBLIC_URL + "/careers/saved"} className="card link-card" onClick={() => props.close()}>
                    <div className="image">
                    <picture>
                            {/* <!-- User prefers light mode: --> */}
                            <source srcset={savedJobsLight} media="(prefers-color-scheme: light)"/>

                            {/* <!-- User prefers dark mode: --> */}
                            <source srcset={savedJobsDark}  media="(prefers-color-scheme: dark)"/>

                            {/* <!-- User has no color preference: --> */}
                            <img src={savedJobsLight} alt="alt text here"/>
                        </picture>
                    </div>
                    <div className="card-body">
                        <h3 className="card-title">Saved occupations</h3>
                        <p className="body-text caption">Review saved careers and edit your job search preferences.</p>
                    </div>
                </Link>
            </div>
        </div>

    )
}

export default Menu