import React, {useEffect, useState} from "react";
import videoLearning from "../assets/data/skillVideos.json";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import SkillCard from "./global/cards/SkillCard";
import ForageCard from "./global/cards/ForageCard";
import ForageModal from "./skills/ForageModal";
import Swoop from "./global/Swoop";

const Skills = (props) => {
    const [modal, setModal] = useState({open:false,data:{}})
    useEffect(() => {
        window.scrollTo(0,0)
    })

    const recommendedSkills = [
        {...videoLearning[9]},
        {
            type:"simulation",
            title: "Data Visualisation: Empowering Business with Effective Insights",
            company:"TATA",
            avatar: "https://cdn.theforage.com/vinternships/companyassets/ifobHAoMjQs9s6bKS/5XsFFJu2oCLdmYJW2/1693127946178/451px-Tata_logo.svg.png",
            thumbnail: "images/skills/img2.png",
            forage: true,
            length: "3.5-4.5 hours",
            tasks: 4,
            category: "Marketing",
            description: "Gain insights into leveraging data visualisations as a tool for making informed business decisions."
        },
        {...videoLearning[14]}
    ]
    const savedLearning = [
        {
            type:"simulation",
            title: "Agile Methodology",
            company:"Cognizant",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQHDKo68h6DqgQ/company-logo_100_100/0/1688389916667?e=2147483647&v=beta&t=Q6h9d65fGxFgbVVQIF9pHnSn64xSjnl7eUcq3-hTPqs",
            thumbnail: "images/skills/img4.png",
            forage: true,
            length: "4 hours",
            tasks: 4,
            category: "Project Management",
            description: "",
            saved:true
        },
        {
            type:"skill",
            id:"skillvid04",
            title:"Control Movement",
            author:"CareerOneStop",
            length:"xx min",
            cost:"Free",
            videoPage:"https://www.careeronestop.org/Videos/SkillandAbilityVideos/control-movement.aspx",
            embed:"https://www.youtube.com/embed/yVKFjwsre1M?rel=0",
            thumbnail:"Control_Movement.png",
            progress: 48,
            saved:true
        },
        {
            type:"skill",
            id:"skillvid06",
            title:"Fine Manipulative Ability",
            author:"CareerOneStop",
            length:"xx min",
            cost:"Free",
            videoPage:"https://www.careeronestop.org/Videos/SkillandAbilityVideos/fine-manipulative-ability.aspx",
            embed:"https://www.youtube.com/embed/PXQQSwrV8pk?rel=0",
            thumbnail:"Fine_Manipulative_Ability.png",
            progress: 11,
            saved:true
        },
    ]
    const simulations = [
        {
            title: "Technical Consulting",
            company:"SAP",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQFgplzXUs8o1Q/company-logo_100_100/0/1688178771763?e=2147483647&v=beta&t=1vYyvtKSwKW19Zxs9MpXPOOHkoxUqcFnY0TVc8IObRY",
            thumbnail: "images/skills/img3.png",
            forage: true,
            length: "5-6 hours",
            tasks: 3,
            category: "Consulting",
            description: "Trusted advisor. It has a nice ring to it, don’t you think? SAP tech consultants are problem-solvers, innovators, and guides. Look closely into critical operations to see how SAP's strategy, processes, and business technology can help customers run even better."
        },
        {
            title: "Advanced Software Engineering",
            company:"Walmart Global Tech",
            avatar: "https://media.licdn.com/dms/image/C560BAQE4Th_qgWovfg/company-logo_100_100/0/1655487552279?e=2147483647&v=beta&t=SsmPWgRBnfUbqtqsheqqA8hWgWlHj5hFtvtqwsIbjEs",
            thumbnail: "images/skills/img.png",
            forage: true,
            length: "4 hours",
            tasks: 4,
            category: "Software Engineering",
            description: "Take the opportunity to get a true feel of what it’s like to work at Walmart as a software engineer."
        },
        {
            title: "Agile Methodology",
            company:"Cognizant",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQHDKo68h6DqgQ/company-logo_100_100/0/1688389916667?e=2147483647&v=beta&t=Q6h9d65fGxFgbVVQIF9pHnSn64xSjnl7eUcq3-hTPqs",
            thumbnail: "images/skills/img4.png",
            forage: true,
            length: "4 hours",
            tasks: 4,
            category: "Project Management",
            description: "",
            saved: true
        },
        {
            title: "Strategy Consulting",
            company:"Accenture",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQGTUswcRlgg9A/company-logo_100_100/0/1689352303421?e=2147483647&v=beta&t=Esui0zYMu4wp7NuYfAd9ZyarMvAb39Wn27IImO1bSRY",
            thumbnail: "images/skills/img5.png",
            forage: true,
            length: "4 hours",
            tasks: 4,
            category: "Consulting",
            description: ""
        },
        {
            title: "Commercial Banking",
            company:"J.P.Morgan | Chase",
            avatar: "https://media.licdn.com/dms/image/C4E0BAQFN7ZGRjNcgeA/company-logo_100_100/0/1656681489088?e=2147483647&v=beta&t=0KMlNACKgGwm_fJcTt39rGG20ba1_w6cSKMOPsZ_Sf8",
            thumbnail: "images/skills/img6.png",
            forage: true,
            length: "4 hours",
            tasks: 4,
            category: "Banking & Finance",
            description: ""
        }
    ]

    return (
        <>
                {/* <Swoop swoop={{title:"Build skills",subtitle:"Prepare for that dream job by testing out real-life roles."}} /> */}
                <main>
                    <section className="intro">
                        <h1 className="page-title secondary desktop-h3 bold">Build skills</h1>
                    </section>
                <section>
                    <div className="card">
                        <div className="card-header">
                            <h2 className="mobile-h3">Recommended for you</h2>
                            <p className="mobile-h6">Based on your job preferences.</p>
                        </div>
                        
                        <div className="card-body">
                            <div className="card-row overflow-wrap skills-group">
                                {recommendedSkills.map((item, index) => {

                                    if (item.type === "skill") {
                                        return (
                                            <SkillCard data={item} modifier={"madecraft-card"} key={"recs"+index}/>
                                        )
                                    } else {
                                        return (
                                            <ForageCard data={item} modifier={"forage-card"} key={"recs"+index} click={() => setModal({...modal, open:true,data:item})}/>
                                            
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card">
                        <div className="card-header">
                            <div className="flex-row">
                            <h2 className="mobile-h3">Saved learning</h2>
                            <Link to={process.env.PUBLIC_URL + "/savedLearning"} className="button link-btn desktop-only">
                                    View all saved items
                                </Link>
                            </div>
                            
                            <p className="mobile-h6">Pick up where you left off in your recently saved items.</p>
                        </div>
                        
                        <div className="card-body">
                            <div className="card-row skills-group">
                                {savedLearning.map((item, index) => {

                                    if (item.type === "skill") {
                                        return (
                                            <SkillCard data={item} modifier={"madecraft-card"} key={"saved"+index}/>
                                        )
                                    } else {
                                        return (
                                            <ForageCard data={item} modifier={"forage-card"} key={"saved"+index} click={() => setModal({...modal, open:true,data:item})}/>
                                            
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <div className="card-footer mobile-only">
                            <Link to={process.env.PUBLIC_URL + "/savedLearning"} className="button link-btn mobile-only">
                                View all saved items
                            </Link>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card">
                        <div className="card-header">
                            <div className="flex-row">
                                <h2 className="mobile-h3">Virtual work experience</h2>
                                <Link to={process.env.PUBLIC_URL + "/simulations"} className="button link-btn desktop-only">
                                    Explore 200+ Forage experiences
                                </Link>
                            </div>
                            
                            <p className="mobile-h6">Learn what it’s like to work for a top employer. Each Forage experience gives you a taste of the tasks you can expect at your dream job.</p>

                        </div>
                        
                        <div className="card-body">
                            <div className="card-row skills-group">
                                {simulations.map((item, index) => {

                                    return (
                                        <ForageCard data={item} modifier={"forage-card"} key={"sim"+index} click={() => setModal({...modal, open:true,data:item})}/>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="card-footer mobile-only">
                            <Link to={process.env.PUBLIC_URL + "/simulations"} className="button link-btn mobile-only">
                            Explore 200+ Forage experiences
                            </Link>
                        </div>
                    </div>
                </section> 
                <section>
                    <div className="card">
                        <div className="card-header">
                            <div className="flex-row">
                                <h2 className="mobile-h3">Real-world skills</h2>
                                <Link to={process.env.PUBLIC_URL + "/learning"} className="button link-btn desktop-only">
                                    All video learning
                                </Link>
                            </div>
                            <p className="mobile-h6">Get ahead by learning the soft skills that everyone needs to succeed in the workplace.</p>

                        </div>
                        
                        <div className="card-body">
                            <div className="card-row skills-group">
                                {videoLearning.slice(0,4).map((item, index) => {

                                    return (
                                        <SkillCard data={item} modifier="madecraft-card" key={"video"+index}/>
                                        
                                    )
                                })}
                            </div>
                        </div>
                        <div className="card-footer mobile-only">
                        <Link to={process.env.PUBLIC_URL + "/learning"} className="button link-btn mobile-only">
                                    All video learning
                                </Link>
                        </div>
                    </div>
                </section>
                {modal.open &&
				createPortal(
					<ForageModal
						close={() => setModal({...modal, open:false})}
						data={modal.data}
					/>,
					document.body
				)}
                </main>
        </>
    )
}

export default Skills