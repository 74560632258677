import React, {useEffect, useRef, useState} from "react";
import { createPortal } from "react-dom";
import PageWrapper from "./global/PageWrapper";
import Header from "./global/Header";
import Icon from "./global/Icon";
import Toast from "./global/Toast";
import jobResults from "../assets/data/jobResultsEngineering.json";
import { useParams, Link, useLocation, useSearchParams, createSearchParams } from "react-router-dom";
import JobResultCard from "./jobs/JobResultCard";
import AlertToggle from "./jobs/filters/AlertToggle";
import AlertModal from "./jobs/filters/AlertModal";
import FilterDropdown from "./jobs/filters/FilterDropdown";
import { CSSTransition, Transition, TransitionGroup } from "react-transition-group";
import FilterDrawer from "./jobs/filters/FilterDrawer";
import { useNavigate } from 'react-router-dom';
import BookmarkButton from "./jobs/BookmarkButton";
import fallBack from "../assets/data/user.json";
import JobPreferences from "./jobs/JobPreferences";
import JobSearchInput from "./jobs/JobSearchInput";

const Jobs = (props) => {
    const [user, setUser] = useState(null);
    const [preferencesOpen, setPreferencesOpen] = useState(false);

    const [alertModal, setAlertModal] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [alertChecked, setAlertChecked] = useState(false);
    const [alertToast, setAlertToast] = useState(false);
    const [pageType, setPageType] = useState(props.pageType ? props.pageType : 'recommended') // search, recommended, or saved
    const [filteredResults, setFilteredResults] = useState(jobResults.results);
    const [recommendedResults, setRecommendedResults] = useState(props.zeroRecs ? [] : jobResults.results.slice(0,8));
    const [selectedResult, setSelectedResult] = useState(props.zeroRecs ? null : jobResults.results[0].id);
    const [filterModal, setFilterModal] = useState(false);
    const [view, setView] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState([{type:"Type",selected:[]},{type:"Onsite/Remote",selected:[]},{type:"Location",selected:[]},{type:"Role",selected:[]},{type:"Date Posted",selected:[]}])
    const [savedJobs, setSavedJobs] = useState(["bb95fa89"]);
    const [globalAlerts, setGlobalAlerts] = useState(false);

    const posting = useRef();
    const navigate = useNavigate();
    const search = useLocation().search;
    useEffect(() => {
        window.scrollTo(0,0)
    })
    useEffect(() => {
        const searchExists = new URLSearchParams(search).get('search')
        const global = new URLSearchParams(search).get('globalAlerts')
        const alerts = new URLSearchParams(search).get('alerts')
        const recommended = new URLSearchParams(search).get('recommended')
        if (searchExists !== null) {
            //do nothing
            setPageType('search')
            setSearchText(searchExists)
            filterResultsByTerm(searchExists)
        } else {
            setPageType(props.pageType)
        }
        if (recommended && recommended === "0") {
            setRecommendedResults([])
            setSelectedResult(null)
        }
        if (global) {
            let defaultAlerts = [
                {
                    "term": "Engineering",
                    "filters": [
                      {
                        "type": "Type",
                        "selected": [
                          "Full time"
                        ]
                      },
                      {
                        "type": "Location",
                        "selected": ["New York, NY"]
                      },
                      {
                        "type": "Role",
                        "selected": []
                      },
                      {
                        "type": "Onsite/Remote",
                        "selected": [
                          "Onsite"
                        ]
                      },
                      {
                        "type": "Compensation",
                        "selected": []
                      },
                      {
                        "type": "Date Posted",
                        "selected": []
                      }
                    ],
                    "frequency": "daily"
                  },
                  {
                    "term": "Project Engineer",
                    "filters": [
                      {
                        "type": "Type",
                        "selected": [
                          "Full time"
                        ]
                      },
                      {
                        "type": "Location",
                        "selected": ["New York, NY"]
                      },
                      {
                        "type": "Role",
                        "selected": []
                      },
                      {
                        "type": "Onsite/Remote",
                        "selected": []
                      },
                      {
                        "type": "Compensation",
                        "selected": []
                      },
                      {
                        "type": "Date Posted",
                        "selected": []
                      }
                    ],
                    "frequency": "daily"
                  }
            ]
            
            if (global === "true"){
                if (alerts && alerts === '0') {
                    setGlobalAlerts(true)
                } else {
                   setGlobalAlerts(true)
                    setAlerts(defaultAlerts) 
                }
                
            } else {
                setGlobalAlerts(false)
            }
        }
        
        console.log(searchExists)
        console.log("triggering ", props.pageType, pageType)
    },[props.pageType])
    useEffect(() => {
        // const params = new URLSearchParams(search).getAll();
        // const role = new URLSearchParams(search).get('userType')
        // const major = new URLSearchParams(search).get('major')
        // const preferences = new URLSearchParams(search).get('preferences')
        // const savedJobs = new URLSearchParams(search).get('savedJobs')
        // const workStyleCase = new URLSearchParams(search).get('workStyleCase')
        // const badgeShared = new URLSearchParams(search).get("badgeShared")
        // const badgeEarned = new URLSearchParams(search).get("badgeEarned")

       
        // console.log(assessmentProps)
        setUser(
            { 
                role: fallBack[0].role, 
                major:fallBack[0].major, 
                state: "new", 
                courses: fallBack[0].courses, 
                preferences: {type: [], location: [], title: []},
                savedJobs : savedJobs.length
            }
        )
        console.log(user)
    },[])

    useEffect(() => {
        //reset filters when search changes
        setFilters([{type:"Type",selected:[]},{type:"Onsite/Remote",selected:[]},{type:"Location",selected:[]},{type:"Role",selected:[]},{type:"Date Posted",selected:[]}])
    },[searchText])
    const getInitials = (company) => {
        let acronym = company.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')

        return acronym.substring(0,2);

    }
    useEffect(() => {
        if (filterModal) {
            document.body.querySelector('.page-wrapper').style.maxHeight= '100vh'
            document.body.querySelector('.page-wrapper').style.overflow = 'hidden'
        } else {
            document.body.querySelector('.page-wrapper').removeAttribute('style')
        }
        
    },[filterModal])

    const searchInputChange = (term) => {
        setSearchText(term)
        

        setAlertChecked(false)
        if (term.length > 2) {
            //search once gets to three?
            // navigate(process.env.PUBLIC_URL + '/jobs?search='+term.replace(" ","+"))
            
            if (globalAlerts) {
                navigate(
                    {pathname:process.env.PUBLIC_URL + '/jobs',
                    search:`?${createSearchParams({search:term.replace(" ","+"),globalAlerts:true})}`
                    }
                )
            } else {
                navigate(process.env.PUBLIC_URL + '/jobs?search='+term.replace(" ","+"))
            }
            setPageType('search')
            // setSelectedResult(0)
            filterResultsByTerm(term)
        } else if (term.length === 0) {
            setSearchText('')
            setSelectedResult(recommendedResults.length > 0 ? recommendedResults[0].id : null)
            if (pageType === 'search') {
                setPageType('recommended')
                if (globalAlerts) {
                    navigate(
                        {pathname:process.env.PUBLIC_URL +'/jobs',
                        search:`?${createSearchParams({globalAlerts:true})}`
                        }
                    )
                } else {
                    navigate(process.env.PUBLIC_URL + '/jobs')
                }
                
            }
        } else {
            //if one or two letters than do nothing yet
        }
    }

    const changeGlobalEmailPref = () => {
        setGlobalAlerts(!globalAlerts)
        if (searchText) {
            navigate(
                {pathname:'/jobs',
                search:`?${createSearchParams({search:searchText.replace(" ","+"),globalAlerts:!globalAlerts})}`
                }
            )
        }
    }

    const filterResultsByTerm = (term) => {
        let allResults = [...jobResults.results]

        let filtered = allResults.filter(f => f.details.title.toLowerCase().includes(term.toLowerCase()));
        setFilteredResults(filtered)
        setSelectedResult(filtered.length > 0 ? filtered[0].id : null)
    }

    const resetView = (type) => {
        setPageType(type)
        setSelectedResult(0)
    }

    const addToAlerts = () => {
        if (alertChecked) {
            // removeFromAlerts()
            setAlertChecked(false)
        } else {
            setAlertChecked(true)
        setAlerts([...alerts, {term: searchText, filters:filters, frequency:'daily'}])
        setAlertToast(true)
        setTimeout(() => {setGlobalAlerts(false)}, 1000)
        setTimeout(() => {setAlertToast(false)}, 4000)
        }


        
    }
    const removeFromAlerts = (item) => {
        // setAlertChecked(false)
        let currentAlerts = [...alerts];
        currentAlerts.splice(item, 1);
        setAlerts(currentAlerts)

    }

    const openJob = (result) => {
        setSelectedResult(result.id)
        setView(1)
        posting.current.scrollTo(0,0)
    }

    const returnToResults = () => {
        setView(0)
        setTimeout(() => {
            document.querySelector(".job-result-card.active").focus()
        },300)
    }
    const applyPref = (pref) => {
        setPreferencesOpen(false)
        setUser({...user, preferences: pref})
            
            // setToast({open:true, message:"Preferences updated"})
            // setTimeout(() => {
            //     setToast({open:false, message:''})
            // },4000)   
        
    }
    const modifySavedJobs = (job) => {
        let currentSaved = [...savedJobs];
            //job.id
            let indexOfJob = currentSaved.indexOf(job.id);
            //check for job.id
            if (indexOfJob > -1) {
                //remove
                currentSaved.splice(indexOfJob,1)
                setSavedJobs(currentSaved)
                if (job.id === selectedResult) {
                    //set new 
                    setSelectedResult(currentSaved[0])
                }
            } else {
                setSavedJobs([...savedJobs,job.id])
            }
    }
    const checkIfSaved = (job) => {
        let indexOfJob = savedJobs.indexOf(job.id);
        //check for job.id
        if (indexOfJob > -1) {
            return true
        }
        else {
            return false
        }
    }
    const checkIfRecommended = (job) => {
        let indexOfJob = recommendedResults.indexOf(job);
        //check for job.id
        if (indexOfJob > -1) {
            return true
        }
        else {
            return false
        }
    }

    const modifyFilters = (type) => {
        // [{type:"Type",selected:["Full time"]},{type:"Location",selected:["Orlando, FL"]},{type:"Role",selected:[]},{type:"Onsite/Remote",selected:[]},{type:"Compensation",selected:[]},{type:"Date Posted",selected:[]}]
        let currentFilters = [...filters],
            typeIndex = currentFilters.findIndex(f => f.type === type.type);

            currentFilters[typeIndex].selected = type.selected

            // console.log(currentFilters)
    }
    const countFilterTotal = () => {
        let count = 0;
        filters.forEach(filter => {
            count = count + filter.selected.length
        })

        return count
    }

    return (
        <main>
               
                <section>
                    <div className="search-header">
                        <JobSearchInput default={searchText} search={(term) => searchInputChange(term)}/>
                        {/* <div className='search search-input'>
							<input type="search" className="gr-input" id="searchPage" aria-label="Search" placeholder="Search for jobs and internships"
                            value={searchText} autoComplete={"off"}
                             onInput={(event) => searchInputChange(event)}
                            />
							<span className='search-icon'>
								<Icon
									icon='search'
									size='24'
								/>
							</span>
                            <button hidden={!showClear} className="button medium icon-btn clear-icon" aria-label="Clear search" onClick={() => clearInputFilter()}>
                                <Icon icon="close" size="24"/>
                            </button> 
						</div> */}
                        {pageType === 'search' && window.innerWidth < 808 ? <div className="mobile-only back-to-results">
                                <button className="filter-btn mobile-view"
                                    aria-label="Filters" onClick={() => setFilterModal(true)}>
                                    <svg class="icon-24 MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1tie80k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TuneIcon"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" fill="currentColor"></path></svg>
                                    {/* <span>Filters</span> */}
                                    {countFilterTotal() > 0 && <span className="filter-amount">{countFilterTotal()}</span>}
                                </button>
                            </div> 
                            : <Link className="link-btn" to={process.env.PUBLIC_URL + "/jobs/saved"} onClick={() => {setPageType('saved');setSearchText('');setSelectedResult(savedJobs[0])}}>
                                <span className="desktop-only">Saved jobs</span>
                                <span className="mobile-only" role="img" aria-label="Saved jobs" style={{color:"var(--color-white)"}}><Icon icon={pageType === "saved" ? "bookmark-on" : "bookmark-off"} size="24"/></span>
                                </Link> }
                         {/* pageType === 'saved' ? <button className="link-btn" onClick={() => {setPageType('recommended');setSearchText('')}}>Recommended jobs</button> : <button className="link-btn" onClick={() => {setPageType('saved');setSearchText('')}}>Saved jobs</button> } */}
                        
                    </div>
                    {pageType === 'search' && 
                        <div className="filter-controls desktop-only">
                            {filters.map((filter, index) => {
                                    return (
                                        <FilterDropdown type={filter.type} filters={filter} key={"filterJobsBy"+index} apply={modifyFilters}/>
                                    )

                            })}
                           
                        </div>
                    }
                    <div className={view === 0 ? "job-search-results sidebar-only" : "job-search-results job-posting-only"}>
                        {pageType === 'saved' ? 
                            <div className="job-search-sidebar">
                                <div className="job-search-sidebar-header">
                                    <h2 className="search-results-title">Saved jobs</h2>
                                    <p className="search-results-description">{savedJobs.length} results</p>
                                </div>
                            {savedJobs.length > 0 ? jobResults.results.map((job,index) => {
                                if (checkIfSaved(job)){
                                return (
                                    <JobResultCard key={"savedjob"+index} 
                                        data={job.details} active={job.id === selectedResult} saved={true}
                                        bookmark={() => modifySavedJobs(job)}
                                        click={() => openJob(job)}
                                        recommended={checkIfRecommended(job)}
                                        />
                                )
                                } else return null
                            })
                        
                            : <div className="card mini-card job-result-card no-results"><h3 className="subtitle-1">Save jobs for later by clicking the bookmark icon.</h3></div>}
                            
                        </div>
                        : pageType === 'search' ?
                        <div className="job-search-sidebar">
                            <div className="job-search-sidebar-header search-results">
                                <div>
                                    <h2 className="search-results-title">Search for</h2>
                                    <div className="job-search-alert-actions">
                                        <AlertToggle toggle={addToAlerts} checked={alertChecked} />
                                            <button className="button icon-btn" aria-label="Alert settings" onClick={() => setAlertModal(true)}>
                                                <Icon icon="settings-outline" size="24"/>
                                            </button>
                                        </div>
                                </div>  
                            
                            <p className="search-results-description"><span className="search-text">{searchText.length > 150 ? (searchText.slice(0,130) + "...") : searchText}</span> <span style={{whiteSpace:"nowrap"}}> &ndash; {filteredResults.length} results</span></p>
                                </div>
                            {filteredResults.length > 0 ? filteredResults.map((job,index) => {
                                return (
                                    <JobResultCard key={"searchResults"+index} data={job.details} recommended={checkIfRecommended(job)} saved={checkIfSaved(job)} active={job.id === selectedResult} bookmark={() => modifySavedJobs(job)} click={() => openJob(job)}/>
                                    
                                )
                            }) : <div className="card mini-card job-result-card no-results"><h3 className="subtitle-1">No results</h3></div>}
                            
                        </div>
                        :
                        <div className="job-search-sidebar">
                            
                            <div className="job-search-sidebar-header">
                                <h2 className="search-results-title">Recommended for you</h2>
                                <p className="search-results-description">Based on your profile and <button className="link-btn" onClick={() => setPreferencesOpen(true)}>preferences</button></p>
                                <p className="search-results-description">{recommendedResults.length} results</p>
                            </div>
                            <div className="job-search-sidebar-results">
                                {recommendedResults.length > 0 ? <> {recommendedResults.map((job,index) => {
                                    return (
                                        <JobResultCard key={"recommended"+index} recommended={checkIfRecommended(job)} data={job.details} active={job.id === selectedResult} saved={checkIfSaved(job)} bookmark={() => modifySavedJobs(job)} click={() => openJob(job)}/>
                                    )
                                })}
                                <div className="card job-result-card no-results more-recommended">
                                    <h3>Not the right fit?</h3>
                                    <p>Adjust your preferences to see more relevant recommendations.</p>
                                    <button className="button small primary" onClick={() => setPreferencesOpen(true)}>Adjust preferences</button>
                                    </div>
                                    </> : <div className="card mini-card job-result-card no-results"><h3 className="subtitle-1">Adjust your <button className="link-btn" onClick={() => setPreferencesOpen(true)}>preferences</button> to see personalized recommendations.</h3></div> }
                            </div>
                        </div> 
                        }
                        <div className="job-search-result">
                            {/* empty state */}
                            <div className="mobile-only back-to-results">
                                <button className="link-btn" onClick={() => returnToResults()}>
                                    <Icon icon="back" size="18"/>
                                    <span>Back to job results</span>
                                </button>
                            </div>
                        { console.log(jobResults.results.filter(f => f.id === selectedResult))}
                            {jobResults.results.filter(f => f.id === selectedResult).length === 0 ? 
                            <div className="job-posting no-result"/> : 
                            
                            <div className={`job-posting`} ref={posting}>

                                <div className="job-posting-header ">
                                    <div className="company-and-apply">

                                        <div className="job-posting-actions">
                                            <BookmarkButton saved={checkIfSaved(jobResults.results.filter(f => f.id === selectedResult)[0])} bookmark={() => modifySavedJobs(jobResults.results.filter(f => f.id === selectedResult)[0])} />
                                            {/* <button className="button icon-btn" aria-label="Bookmark" aria-pressed={checkIfSaved(jobResults.results[selectedResult])} bookmark={() => modifySavedJobs(jobResults.results[selectedResult])}>
                                                <Icon icon="bookmark-off" size="24"/>
                                            </button> */}
                                            <button className="button small primary">Apply now</button>
                                        </div>
                                        <div className="company-name">
                                        <div className="avatar">
                                            {jobResults.results.filter(f => f.id === selectedResult)[0].details.avatar ? 
                                            <img src={jobResults.results.filter(f => f.id === selectedResult)[0].details.avatar} alt="logo"/>
                                            : <span className="initial">{getInitials(jobResults.results.filter(f => f.id === selectedResult)[0].details.company_name)}</span>}
                                        </div>
                                        <p className="job-company">{jobResults.results.filter(f => f.id === selectedResult)[0].details.company_name}</p>
                                    </div>
                                    </div>
                                    
                                
                                    <h3 className="card-title job-title desktop-h2">{jobResults.results.filter(f => f.id === selectedResult)[0].details.job_title}</h3>
                                    <p className="job-details meta">
                                        <span>{jobResults.results.filter(f => f.id === selectedResult)[0].details.job_type}</span>
                                        <span>{jobResults.results.filter(f => f.id === selectedResult)[0].details.inferred_city}, {jobResults.results.filter(f => f.id === selectedResult)[0].details.state} </span>
                                        <span>Posted on {jobResults.results.filter(f => f.id === selectedResult)[0].details.post_date}</span>
                                    </p>
                                </div>
                                
                                <div className="job-posting-body" dangerouslySetInnerHTML={{__html: jobResults.results.filter(f => f.id === selectedResult)[0].details.html_job_description}}/>

                            </div> }
                        </div>
                    </div>
                </section>
                {filterModal && createPortal(
                    <FilterDrawer filters={filters} close={() => setFilterModal(false)} apply={(items) => setFilters(items)}/>
                    , document.body
                )}
                {alertModal &&
				createPortal(
					<AlertModal
						close={() => setAlertModal(false)}
						alerts={alerts}
                        off={globalAlerts}
                        delete={removeFromAlerts}
					/>,
					document.body
				)}
                {preferencesOpen && createPortal(<JobPreferences preferences={user.preferences} close={() => setPreferencesOpen(false)} apply={(pref) => applyPref(pref)} major={user.major.length > 0}/>, document.body)}

                {alertToast && createPortal(<Toast type={globalAlerts ? 'info' : 'success'} message={globalAlerts ? 'All job alert notifications are re-enabled.' : 'Job alert created.'} button={{text:"Manage alerts", action: () => setAlertModal(true)}}/>,document.body) }
        </main>
    )
}

export default Jobs