import React, { useEffect, useState } from "react";
import Illustration from "../../../assets/images/no-alerts.png"
import Icon from "../../global/Icon";

const AlertModal = (props) => {
    const [alerts, setAlerts] = useState(props.alerts);


    useEffect(() => {
        setAlerts(props.alerts)
    },[props.alerts])

    return (
        <div className='modal-overlay'>
            <div className="modal job-search-alert-modal">
                <button className="button icon-btn close-btn" aria-label="Close" onClick={() => props.close()}>
                    <Icon icon="close" size="24"/>
                </button>
                <div className="modal-header">
                    <h1 className="modal-title desktop-h3">
                        Job alerts
                    </h1>
                    {props.off && <p className="flex-row" style={{justifyContent:"flex-start",gap:"8px"}}>
                        <Icon icon="warning" size="18"/>
                        <span>All email alerts are off. Re-enable them in <a>preferences</a>.</span>
                        </p>}
                </div>
                <div className="modal-body">
                    
                    {props.alerts.length > 0 ? 
                        <div className="alerts-list">
                            <ul>
                                {alerts.map((item,index) => {
                                    return (
                                    <li className={props.off ? "job-alert-item disabled" :"job-alert-item"} key={"alertItem-"+item.term.replace(' ','')+index}>
                                        <div className="alert-info">
                                            <h2 className="desktop-h5 bold job-alert-title">
                                                {item.term}
                                            </h2>
                                            <p className="alert-meta">
                                                {item.filters.filter(f => f.selected.length > 0).length > 0 ?
                                                       <> {item.filters.map(filter => {
                                                            if (filter.selected.length > 0 && filter.type.toLowerCase() !== "date posted") {
                                                                return <span>{filter.type === "Role" ? "Roles: " : ""} {filter.selected.map((item,index) => {
                                                                    if (index > 0) return ( ", " + item)
                                                                    else return ( item )
                                                                })} </span>
                                                            }
                                                        })}
                                               </> : <></>}

                                            </p>
                                            {/* <fieldset className="alert-frequency">
                                                <legend><p>Frequency</p></legend>
                                                <div className="radio-options row">
                                                    <div className="gr-radio">
                                                        <input type="radio" name={"freq"+index} id={"freq"+index+"0"} value="daily" defaultChecked />
                                                        <label htmlFor={"freq"+index+"0"}>
                                                            <span>Daily</span>
                                                        </label>
                                                        <span>
                                                            <svg viewBox='0 0 18 18' width='18' height='18'>
                                                                <circle r='6' cx='9' cy='9' />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="gr-radio">
                                                        <input type="radio" name={"freq"+index} id={"freq"+index+"1"} value="weekly" />
                                                        <label htmlFor={"freq"+index+"1"}>
                                                            <span>Weekly</span>
                                                        </label>
                                                        <span>
                                                            <svg viewBox='0 0 18 18' width='18' height='18'>
                                                                <circle r='6' cx='9' cy='9' />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </fieldset> */}
                                        </div>
                                        <button className="button icon-btn delete-btn" aria-label={"Delete alert for " + item.term} disabled={props.off} onClick={() => props.delete(index)}>
                                            <Icon icon="delete" size="24"/>
                                        </button>
                                        
                                    </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                        : <div className="no-alerts">
                            <div className="illustration">
                                <img src={Illustration} alt=""/>
                            </div>
                            <h2 className="desktop-h5 bold">You have no job alerts</h2>
                            <p>Use your search terms to set alerts for roles, locations, and more.</p>
                            </div>    
                }
                    
                </div>
                <div className="modal-footer">
                    <button className="button primary medium" onClick={() => props.close()}>
                        {props.alerts.length > 0 ?  "Done" : "Okay" }
                        
                    </button>
                </div>
            </div>
        </div>
    )
}
export default AlertModal