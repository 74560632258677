import React from "react";
import { Link } from "react-router-dom";
import Icon from "../global/Icon";

const InsightsHeader = (props) => {
    let isDesktop = props.isDesktop

    return (
        <section className='intro'>
            <div className="page-nav">
                <Link to={process.env.PUBLIC_URL + "/profile"} className="button icon-btn">
                    <Icon icon="back" size="24"/>
                </Link>
                <h1 className={isDesktop ? 'page-title tertiary desktop-h3 bold' : 'page-title tertiary mobile-h3 bold'}>Work Style Insights</h1>
            </div>
            <p className={isDesktop ? 'body-text' : 'body-text small'}>
                Work Style Insights is an assessment that has been designed to be a
                reliable, fair, and valid measure of the work styles that contribute
                to and influence job success. The work styles measured can be
                thought of as the individual’s typical patterns of thinking, feeling
                and behaving.
            </p>
        </section>
    )
}
export default InsightsHeader