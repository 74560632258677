import React, {useEffect, useRef, useState} from "react";
import TabList from "../global/tabs/TabList";
import TabItem from "../global/tabs/TabItem";
import FastFacts from "./FastFacts";

const CareerTabs = (props) => {
    const [selectedTab, setSelectedTab] = useState(props.selected);
    const slider = useRef();
    const tabList = useRef();


    // keyboard functionality
    const onKeyDown = (e) => {
        const tabLength = props.children.length,
            tabButtons = tabList.current.querySelectorAll("button");
        let activeElement = document.activeElement,
            activeIndex = [].indexOf.call(tabButtons,activeElement);

            switch (e.key) {
                case "Left":
                case "ArrowLeft":
                    if (activeIndex === 0) {
                        tabButtons[tabLength-1].focus()
                        setSelectedTab(tabLength-1)  
                        props.select(tabLength-1)   
                    } else {
                        tabButtons[activeIndex -1].focus()
                        setSelectedTab(activeIndex - 1)
                        props.select(activeIndex - 1)
                    }
                    
                    break;
                case "Right":
                case "ArrowRight":
                    if (activeIndex === tabLength-1) {
                        tabButtons[0].focus()   
                        setSelectedTab(0)  
                        props.select(0)
                    } else {
                        tabButtons[activeIndex+1].focus()
                        setSelectedTab(activeIndex+1)
                        props.select(activeIndex+1)
                    }
                    break;
                default:
                    return;

            }
            // e.preventDefault();
            //arrow left

        //arrow right
    }

    return (
        <div className="tab-container career-switcher" >
           <div className="tab-list-container" ref={tabList} onKeyDown={onKeyDown}>
                <TabList>
                    
                    {props.careers.map((career, index) => {
                        return (
                            <TabItem key={"tab-" + index}
                                id={"career" + index + "-tab"} panelId={"career" + index + "-panel"}
                                activeTab={selectedTab === index}
                                select={() => {setSelectedTab(index);props.select(index)}}
                                >
                                <span className="tab-label"><span  dangerouslySetInnerHTML={{__html: career.title}}/></span>
                                {career.altImage ? <span className="image"><img src={career.altImage} alt="placeholder"/></span> : career.image ? <span className="image"><img src={career.image} alt="placeholder"/></span> : ''}
                            </TabItem>
                        )
                    })}
                </TabList>
                {!props.hideButton && <a className="button primary medium" href={process.env.PUBLIC_URL + "/careers"}>Explore more careers</a>}

            </div>
             <div className="tab-content" style={{minHeight:"405px"}}>
                {props.careers.map((career, index) => {
                    if (index !== selectedTab) return undefined;
                    return <FastFacts data={career} key={"careerInfo"+index}/>;
                 })}
            </div>
            
            
        </div>
    )

}
export default CareerTabs