import React from "react";
import SuggestedCertificatesAccordion from "./SuggestedCertificatesAccordion";

const SuggestedCertificatesMobile = (props) => {
  

    return (
        
        <div className="suggested-certificates mobile-only">
            
                
            {props.suggested.map((card, index) => {
                return (
                    <SuggestedCertificatesAccordion data={card} recommended={index < 2} key={"suggestedCerM"+index}
                    more={(step) => props.more(step)} add={(step) => props.add(step)}
                    />
                   
                )
            })}
                    
                    
        </div>
    )
}
export default SuggestedCertificatesMobile