import React, { useEffect, useState } from "react";
import Header from "./global/Header";
import Icon from "./global/Icon";
import { Link } from "react-router-dom";

import assessment from "../assets/images/assessment.png";
import skier from "../assets/images/cta/woman-skiing.png";
import womanStudy from "../assets/images/cta/woman-studying.png";
import ForageLogo from "../assets/images/Forage-logo.png";
import skillCourses from "../assets/data/proCourses.json";
import videoLearning from "../assets/data/videoLearning.json";
import CareerTabs from "./groups/CareerTabs";
import FastFacts from "./groups/FastFacts";
import careers from "../assets/data/careers.json";
import TabPanel from "./global/tabs/TabPanel"
import HorizontalNavMenu from "./navigation/HorizontalNav";
import Footer from "./global/Footer";
import SkillCard from "./global/cards/SkillCard";
import JobCardMini from "./global/cards/JobCardMini";
import Toast from "./global/Toast";
import CTACard from "./global/cards/CTACards";
import SavedJobsCarousel from "./global/cards/SavedJobsCarousel";
import CareersCardMobile from "./global/cards/CareersCardMobile";
import CareerOverviewCarousel from "./careers/CareerOverviewCarousel";
import CareersCard from "./global/cards/CareersCard";
import GoalCard from "./global/cards/GoalCard";
import BadgeCTACard from "./global/cards/BadgeCTACard";
import SavedJobsCTACard from "./global/cards/SavedJobsCTACard";
import AssessmentCTACard from "./global/cards/AssessmentCTACards";
import AssessmentMiniCard from "./global/cards/AssessmentMiniCards";
import JobsGroupCard from "./global/cards/JobsGroupCard";

const Sandbox = (props) => {
    const options = ["Jobs","Careers","Skills", "Profile"];
    const [activeTab, setActiveTab] = useState(props.active);
    const introMessages = (scenario) => {
        if (scenario === "intent") {
            return {heading: "Welcome!", text: "We're glad you're here."}
        } else if (scenario === "new") {
            return {heading: "Welcome to Job Match!", text: "Don't know where to start? We can help you prepare for your future."}
        } else if (scenario === "returning") {
            return {heading: "Welcome back!", text: "Land your dream job."}
        } else if (scenario === "engaged" ) {
            return {heading: "Welcome back!", text: "Let's chart your course."}
        }
    }
    const recommendedJobs = [
        {
            title: "Entry Level Electrical Engineer",
            company:"Arcadis",
            avatar: "https://media.licdn.com/dms/image/D560BAQFAyhh51gC9dw/company-logo_100_100/0/1688175751520?e=2147483647&v=beta&t=iK-C4WvTeyM9IFAJqV6veo2QK3h7AdQMPh6czTH4fkQ",
            type: "Full time",
            location: "Boston, MA",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        },
        {
            title: "Electronics Engineer",
            company:"U.S. Department of Commerce",
            avatar: "https://media.licdn.com/dms/image/C4D0BAQEY8SR1kFutMQ/company-logo_100_100/0/1648577411471?e=2147483647&v=beta&t=0b7hrFw65W2QW__UJpqwS4C4wOEJTEO2v_YSNuXj5FM",
            type: "Full time",
            location: "Boulder, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        },
        {
            title: "Electrical Designer - Entry",
            company:"Power Design, Inc.",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQE32SZ0OyPrig/company-logo_100_100/0/1688561445332?e=2147483647&v=beta&t=cnY_Eic2qQvTrT919O-m9AiB3r0yE1J_B4EPIrfvaGQ",
            type: "Full time",
            location: "Saint Petersburg, FL",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "https://media.licdn.com/dms/image/D560BAQExWVK4Gj-SZw/company-logo_100_100/0/1688476635974?e=2147483647&v=beta&t=quaLK2WCwycr684FFFyE1vs7HCgZ8PJSfEA7kN_G7QY",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        }
    ]
    const savedJobs = [
        {
            title: "Entry Level Electrical Engineer",
            company:"Arcadis",
            avatar: "",
            type: "Full time",
            location: "Boston, MA",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electronics Engineer",
            company:"U.S. Department of Commerce",
            avatar: "",
            type: "Full time",
            location: "Boulder, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Designer - Entry",
            company:"Power Design, Inc.",
            avatar: "",
            type: "Full time",
            location: "Saint Petersburg, FL",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        }
    ]

    return (
        <div className={`page-wrapper horizontal-nav sandbox ${props.option}`}>
            {/* <VerticalNav/> */}
            <div className="swoop"></div>
            
            <HorizontalNavMenu active="dashboard"/>
            
            <main>
                <section hidden>
                    <GoalCard cta={true}/>
                    <GoalCard cta={false}/>
                    <BadgeCTACard badgeShared={false}/>
                    <BadgeCTACard badgeShared={true}/>
                </section>
                {/* <Header search={true}/> */}
                <section hidden>
                    <div className="button-row">
                        <button className="button large primary">Button</button>
                        <button className="button large primary" disabled>Button</button>
                        <button className="button large primary icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button large primary icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button large icon-btn primary"><Icon icon="star" size="32"/></button>
                    </div>
                    <div className="button-row">
                        <button className="button medium primary" disabled>Button</button>
                        <button className="button medium primary">Button</button>
                        <button className="button medium primary icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button medium primary icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button medium icon-btn primary"><Icon icon="star" size="32"/></button>
                    </div>
                    <div className="button-row">
                        <button className="button small primary" disabled>Button</button>
                        <button className="button small primary">Button</button>
                        <button className="button small primary icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button small primary icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button small icon-btn primary"><Icon icon="star" size="32"/></button>
                    </div>
                    <div className="button-row">
                        <button className="button xsmall primary" disabled>Button</button>
                        <button className="button xsmall primary">Button</button>
                        <button className="button xsmall primary icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button xsmall primary icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button xsmall icon-btn primary"><Icon icon="star" size="32"/></button>
                    </div>
                    <div className="button-row">
                        <button className="button large dark filled">Button</button>
                        <button className="button large dark filled" disabled>Button</button>
                        <button className="button large dark filled icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button large dark filled icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button large icon-btn dark filled"><Icon icon="star" size="32"/></button>
                    </div>
                    <div className="button-row">
                        <button className="button large dark outline">Button</button>
                        <button className="button large dark outline" disabled>Button</button>
                        <button className="button large dark outline icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button large dark outline icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button large icon-btn dark outline"><Icon icon="star" size="32"/></button>
                    </div>
                    <div className="button-row">
                        <button className="button large dark clear">Button</button>
                        <button className="button large dark clear" disabled>Button</button>
                        <button className="button large dark clear icon-left"><Icon icon="star" size="32"/>Button</button>
                        <button className="button large dark clear icon-right">Button<Icon icon="star" size="32"/></button>
                        <button className="button large icon-btn dark clear"><Icon icon="star" size="32"/></button>
                    </div>
                </section>
                <section className="intro">
                    <h1>Top banners</h1>
                        {/* <CTACard useCase={4} pageState={0}/>
                        <CTACard useCase={4} pageState={1}/> */}
                        <CTACard useCase={2} pageState={0}/>
                        {/* <CTACard useCase={2} pageState={1}/> */}
                        <CTACard useCase={3} pageState={0}/>
                        {/* <CTACard useCase={3} pageState={1}/> */}
                        {/* <CTACard useCase={4} pageState={0}/>  */}
                        <SavedJobsCTACard />
                        <h2>example of jobs cards when no avatar image available</h2>
                        <div className="card dark action-card saved-jobs-cta">
                            <div className="card-header">
                                <div className="flex-row">
                                    <div>
                                        <h2 className="desktop-h1">Still interested?</h2>
                                        <p className="body-text">You currently have 4 jobs saved:</p>
                                    </div>
                                    <a className="button small primary">Go to saved jobs</a>
                                </div>
                                        
                            </div>
                            <div className="card-body">
                            <SavedJobsCarousel>
                        
                                {savedJobs.map((job, index) => {
                                    return (
                                        <JobCardMini data={job} key={"savedjobs2"+index}/>
                                    )
                                })}
                            
                            </SavedJobsCarousel>
                                
                            </div>
                            
                        </div>
                        <AssessmentCTACard part={1} progress={0}/>
                        <AssessmentCTACard part={1} progress={50}/>
                        <AssessmentCTACard part={1} progress={100}/>
                        <AssessmentCTACard part={1} progress={100} resultsReady={true}/>
                        <AssessmentCTACard part={2} progress={50}/>
                        <AssessmentCTACard part={2} progress={100}/>
                    
                </section>
                <section>
                    <h1>Assessment mini cards</h1>
                    
                    <div className="card-row three-three-three">
                        <AssessmentMiniCard part={1} progress={0}/>
                        <AssessmentMiniCard part={1} progress={50}/>
                        <AssessmentMiniCard part={1} progress={100}/>
                        {/* <div className="flex-2"></div> */}
                    </div>
                    <div className="card-row three-three-three">
                        <AssessmentMiniCard part={1} progress={100} resultsReady={true}/>
                        <AssessmentMiniCard part={2} progress={50}/>
                        <AssessmentMiniCard part={2} progress={100} resultsReady={true}/>
                        {/* <div className="flex-2"></div> */}
                    </div>
                    <div className="card-row one-two">
                        <AssessmentMiniCard part={2} progress={100} resultsViewed={true}/>
                        
                        <div></div>
                    </div>
                </section>
                <section>
                    <h1 className="">Jobs</h1>
                    <div className="card-row">
                        <JobsGroupCard size="three-col" major={false} preferences={false} addMajor={() => console.log("add major modal")} addPref={() => console.log("add pref modal")} jobs={[...recommendedJobs, ...recommendedJobs]}/>
                    </div>
                    <div className="card-row">
                        <JobsGroupCard size="three-col" major={false} preferences={true} addMajor={() => console.log("add major modal")} addPref={() => console.log("add pref modal")} jobs={[...recommendedJobs, ...recommendedJobs]}/>
                    </div>
                    <div className="card-row">
                        <JobsGroupCard size="three-col" major={true} preferences={true} addMajor={() => console.log("add major modal")} addPref={() => console.log("add pref modal")} jobs={[...recommendedJobs, ...recommendedJobs]}/>
                    </div>
                    <div className="card-row two-one">
                        <JobsGroupCard size="two-col" major={false} preferences={false} addMajor={() => console.log("add major modal")} addPref={() => console.log("add pref modal")} jobs={[...recommendedJobs]}/>
                        <div></div>
                    </div>
                    <div className="card-row two-one">
                        <JobsGroupCard size="two-col" major={false} preferences={true} addMajor={() => console.log("add major modal")} addPref={() => console.log("add pref modal")} jobs={[...recommendedJobs]}/>
                        <div></div>
                    </div>
                    <div className="card-row two-one">
                        <JobsGroupCard size="two-col" major={true} preferences={true} addMajor={() => console.log("add major modal")} addPref={() => console.log("add pref modal")} jobs={[...recommendedJobs]}/>
                        <div></div>
                    </div>
                    <div className="card-row">
                        <div className="card horizontal-card empty-state">
                            
                            <div className="card-body">
                                    <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                    <p className="body-text">Broaden your <button className="link-btn">job preferences</button> for more recommendations.</p>
                            </div>
                            <div className="image">
                                <img src={skier} alt="skier" style={{objectPosition: "50% 15%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-row">
                        <div className="card horizontal-card empty-state">
                            
                            <div className="card-body">
                                    <h3 className="card-title desktop-h4 bold">Jobs & internships for you</h3>
                                    <p className="body-text">Add <button className="link-btn">major</button> & <button className="link-btn">preferences</button> to get recommendations.</p>
                            </div>
                            <div className="image">
                                <img src={womanStudy} alt="woman studying"/>
                            </div>
                        </div>
                    </div>
                    <div className="card-row two-one">
                        <div className="card vertical-card empty-state">
                            <div className="image">
                                <img src={skier} alt="skier" />
                            </div>
                            <div className="card-body">
                                    <h3 className="card-title desktop-h2 bold">Jobs & internships for you</h3>
                                    <p className="body-text">Broaden your <button className="link-btn">job preferences</button> for more recommendations.</p>
                            </div>
                            
                        </div>
                        <div/>
                    </div>
                    <div className="card-row two-one">
                        <div className="card vertical-card empty-state">
                        <div className="image">
                                <img src={womanStudy} alt="woman studying"/>
                            </div>
                            <div className="card-body">
                                    <h3 className="card-title desktop-h2 bold">Jobs & internships for you</h3>
                                    <p className="body-text">Add <button className="link-btn">major</button> & <button className="link-btn">preferences</button> to get recommendations.</p>
                            </div>
                            
                        </div>
                        <div/>
                    </div>
                    
                </section>
                <section hidden>
                    <h1>Skills</h1>
                    <div className="card" style={{marginBottom:"32px"}}>
                        <div className="card-header">
                            <div className="flex-row">
                                <h3 className="desktop-h3">Get ready for the workplace</h3>
                                <button className="button link-btn desktop-only">
                                    All video learning
                                </button>
                            </div>
                           
                            <p className="desktop-h5">Learn skills at your own pace. Plus, you’ll earn a certificate and badge for your resume. </p>
                        </div>
                        
                        <div className="card-body">
                            <div className="card-row overflow-wrap skills-group">
                                {videoLearning.slice(0,3).map((item, index) => {

                                    return (
                                        <SkillCard data={item} modifier="madecraft-card" key={"video"+index}/>
                                       
                                    )
                                })}
                            </div>
                        </div>
                        <div className="card-footer mobile-only">
                            <button className="link-btn mobile-only">All video learning</button>
                        </div>
                    </div>
                    <div className="card split-card">
                        <div className="recommended-side">
                            <h3 className="mobile-h3">Recommended training for you</h3>
                            <div className="card-row overflow-wrap">
                            {videoLearning.slice(0,4).map((item, index) => {

                                return (
                                    <SkillCard data={item} modifier="madecraft-card" key={"video2"+index}/>
                                )
                                })}
                            </div>
                        </div>
                        <div className="resume-side">
                            <h3 className="mobile-h3">Pick up where you left off</h3>
                            <a className="card video-card large" href="#">
                                <div className="video image">
                                    <div className="play-icon">
                                        <svg width="22" height="22" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><g filter="url(#filter0_d_2120_12483)"><path d="M53.3136 39.891L17.8459 60.5101C14.836 62.2582 10.9731 60.1415 10.9731 56.6175V15.3793C10.9731 11.8609 14.8304 9.73868 17.8459 11.4923L53.3136 32.1114C53.9983 32.503 54.5675 33.0691 54.9634 33.7523C55.3592 34.4354 55.5677 35.2113 55.5677 36.0012C55.5677 36.7912 55.3592 37.567 54.9634 38.2502C54.5675 38.9333 53.9983 39.4994 53.3136 39.891Z" fill="white"></path></g><defs><filter id="filter0_d_2120_12483" x="0.973145" y="0.864868" width="64.5947" height="70.2703" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="5"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2120_12483"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2120_12483" result="shape"></feBlend></filter></defs></svg>
                                    </div>
                                    <img src='https://static.studychannel-prd.pearsonprd.tech/components/grid/images/unleash-and-advance-your-creativity.jpg' alt='thumbnail'/>
                                </div>
                                
                                    <div className="card-body">
                                        <div className="avatar">

                                            <img src={skillCourses[28].tutor.image} alt="avatar"/>
                                        </div>
                                        <div className="card-text">
                                            <h4 className="card-title">{skillCourses[28].title}</h4>
                                            <p className="card-subtitle author-name">{skillCourses[28].tutor.name}</p>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="card-progress" style={{maxWidth:"400px"}}>
                                        <div className="progress-bar-container">
                                            <div className="progress-bar">
                                                <div className="bar" style={{width:"11%"}}/>
                                            </div>
                                            <p className="progress-bar-label">11% complete</p>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                    <span className="button dark outline medium">Resume</span>
                                    </div>
                                   

                            </a>
                        </div>
                    </div>
                </section>
                
                <section>
                    <h1>Careers</h1>
                    <CareersCard title={"Explore Architecture and Engineering"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0]]}/>

                    <CareersCard title={"See what's out there"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0],careers[5]]}/>
                    <CareersCard title={"See what's out there"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0],careers[5],careers[3],careers[15]]}/>
                    
                </section>
            </main>           

            {/* <Footer/> */}
        </div>
    )
}
export default Sandbox