import React from "react";
import JobCardMini from "./JobCardMini";
import IntroBG from "../../../assets/images/welcome.png"
import welcome3 from "../../../assets/images/welcome-grid-reading.png";
import WorkStyle from "../../../assets/images/assessment.png";
import SavedJobsCarousel from "./SavedJobsCarousel";
import complete from "../../../assets/icons/Completion.svg"
import womanPhone from "../../../assets/images/cta/woman-phone.png";
import manHeadphones from "../../../assets/images/cta/man-listening-headphones.png"

const CTACard = (props) => {
    //props: useCase #, state (load, edit)
    const savedJobsArr = [
        {
            title: "Entry Level Electrical Engineer",
            company:"Arcadis",
            avatar: "https://media.licdn.com/dms/image/D560BAQFAyhh51gC9dw/company-logo_100_100/0/1688175751520?e=2147483647&v=beta&t=iK-C4WvTeyM9IFAJqV6veo2QK3h7AdQMPh6czTH4fkQ",
            type: "Full time",
            location: "Boston, MA",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electronics Engineer",
            company:"U.S. Department of Commerce",
            avatar: "https://media.licdn.com/dms/image/C4D0BAQEY8SR1kFutMQ/company-logo_100_100/0/1648577411471?e=2147483647&v=beta&t=0b7hrFw65W2QW__UJpqwS4C4wOEJTEO2v_YSNuXj5FM",
            type: "Full time",
            location: "Boulder, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Designer - Entry",
            company:"Power Design, Inc.",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQE32SZ0OyPrig/company-logo_100_100/0/1688561445332?e=2147483647&v=beta&t=cnY_Eic2qQvTrT919O-m9AiB3r0yE1J_B4EPIrfvaGQ",
            type: "Full time",
            location: "Saint Petersburg, FL",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "https://media.licdn.com/dms/image/D560BAQExWVK4Gj-SZw/company-logo_100_100/0/1688476635974?e=2147483647&v=beta&t=quaLK2WCwycr684FFFyE1vs7HCgZ8PJSfEA7kN_G7QY",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        }
    ]

    const messages = () => {
        if (props.useCase === 4) {
            //has saved jobs
            return {
                heading: '',
                text: '',
                button: '',
                image: welcome3,
                click: null
            }
        } else if (props.useCase === 3) {
            //pref exist, no major, no saved jobs
            if (props.pageState === 1) {
                return {
                    heading: 'Education details added',
                    text: 'We’ll be able to recommend jobs based on your major. Remember, you can edit these details at any time.',
                    button: 'Edit education details',
                    image: womanPhone,
                    click: props.openEducation
                }
            } else {
                return {
                    heading: 'Find your future',
                    text: 'Add your major to explore job and career recommendations in your field.',
                    button: 'Add education details',
                    image: womanPhone,
                    click: props.openEducation
                }
            }
        } else if (props.useCase === 2) {
            //no pref, yes major, no saved jobs
            if (props.pageState === 1) {
                //edit
                return {
                    heading: 'You’re on your way',
                    text: 'Now check out the latest job opportunities recommended for you. Remember, you can edit your preferences at any time.',
                    button: 'Edit job preferences',
                    image: manHeadphones,
                    click: props.openPreferences
                }
            } else {
                return {
                    heading: 'Put us to work',
                    text: 'Share your preferences so Job Match can track down the roles you want.',
                    button: 'Add job preferences',
                    image: manHeadphones,
                    click: props.openPreferences
                }
            } 
        } else if (props.useCase === 1) {
            //pref and major, no saved jobs
            //no CTA card
            return {
                heading: '',
                text: '',
                button: '',
                image: welcome3,
                click: null
            }
        } else {
            //no pref, no major, no saved jobs
            if (props.pageState === 1) {
                //edit
                return {
                    heading: 'You’re on your way!',
                    text: 'Now check out the latest job opportunities recommended for you. Remember, you can edit your preferences at any time.',
                    button: 'Edit job preferences',
                    image: manHeadphones,
                    click: props.openPreferences
                }
                
            } else {
                return {
                    heading: 'Put us to work',
                    text: 'Share your preferences so Job Match can track down the roles you want.',
                    button: 'Add job preferences',
                    image: manHeadphones,
                    click: props.openPreferences
                }
            } 
        }
    }


    return (
        
        <div className={`card dark action-card`}>
                <div className="card-message">
                    <h2 className={`card-title desktop-h1`}>{messages().heading}</h2>
                    <p className="body-text">{messages().text}</p>
                    <div className="card-actions">
                        <button className="button small primary" onClick={messages().click}>{messages().button}</button>
                    </div>
                </div>
                <div className="card-image">
                    
                    <div className="image">
                        <img src={messages().image} alt="cta image alt text"/>
                    </div>
            
                </div>
        </div>
    )
}
export default CTACard