import React, {useState, useRef, useEffect} from "react";
import SearchChip from "../../global/chips/SearchChip";
import ToggleChip from "../../global/chips/ToggleChip";
import Icon from "../../global/Icon";
import CareerSearchInput from "../../careers/CareerSearchInput";
import AutocompleteRoles from "../../global/search/AutocompleteRoles";

const FilterMenuRole = (props) => {
    const [checked, setChecked] = useState(props.data.selected);
    const [searchedRoles, setSearchedRoles] = useState([])
    const input = useRef();

    useEffect(() => {
        let newArr = []
        if (props.data.selected.length > 0) {
            props.data.selected.forEach(item => {
                //check if its in searched array
                if (searchedRoles.indexOf(f => f.term === item) > -1) {
                    //do nothing
                } else {
                    newArr.push({term: item, selected:true})
                }
                
            })
        }
        setSearchedRoles(newArr)
    },[])

    const handleCheck = (event,item) => {
        // event.preventDefault()
        let currentChecked = [...checked],
            results = [...searchedRoles],
            itemIndex = results.findIndex(f => f.term == item);
            console.log(results, item)
            console.log(itemIndex)

        if (currentChecked.indexOf(item) > -1) {
            currentChecked.splice(currentChecked.indexOf(item),1)
            if (itemIndex > -1) {
                console.log("exists in checked")
                //item exists in new search terms
                results[itemIndex].selected = false
            }
        } else {
            currentChecked.push(item)
            if (itemIndex > -1) {
                //item exists in new search terms
                results[itemIndex].selected = true
            }
        }
        // if (itemIndex > -1) {
        //     //item exists in new search terms
        //     results[itemIndex].selected = false
        // } else {
        //     //item is probably in suggested
        // }
        setChecked(currentChecked)
        setSearchedRoles(results)
        props.modify(currentChecked)
    }
    const onEnter = (event) => {
        if (event.key === "Enter") {
            //search
            setChecked([...checked, event.target.value])
            setSearchedRoles([...searchedRoles,{term:event.target.value, selected:true}])
            props.modify([...checked, event.target.value])
            input.current.value = ''
        }
    }
    const addToSelected = (arr) => {
        //need to pass selected up so button can show number
        props.modify(arr)
    }
    
    return (
        <div className="filter-body">
            <div className="form-group">
                    <AutocompleteRoles placeholder="Filter roles" addToSelected={addToSelected} selected={props.data.selected} />
            </div>
        </div>
    )
}
export default FilterMenuRole