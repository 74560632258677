import React, {useState, useEffect} from "react";
import ToggleChip from "../global/chips/ToggleChip";
import TabPanel from "../global/tabs/TabPanel";
import { Tabs, Tab } from "@mui/material"; 

import SkillCard from "../global/cards/SkillCard";
import ForageCard from "../global/cards/ForageCard";
import simulations from "../../assets/data/simulations.json";
import VirtualScrollChild from "../global/scroll/VirtualScrollChild";
import ForageFilterCompany from "./ForageFilterCompany";
import CareerFieldsDropdown from "./CareerFieldsDropdown";

const ForageContent = ({setModal}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [simulationsShown, setSimulationsShown] = useState([...simulations])
    const [simulationFilters, setSimulationFilters] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const careerfields = [
        "Accounting", "Education","Health Care", "Law","Life Science",
        "Banking & Financial Services",  "Client Services", "Consulting", "Data","Engineering", "Entrepreneurship", "Government and Public Policy",
         "Human Resources", "Insurance", "Interview and Career Skills",  "Marketing","Project Mangement",
        "Real Estate", "Sales", "Security"
    ]
    useEffect(() => {
        window.scrollTo(0,0)
    })

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
      };
    const filterSims = (by) => {
        let fullList = [...simulations]

        let currentFilters = [...simulationFilters]
        if (currentFilters.length > 0 && currentFilters.includes(by)) {
            //remove it
            let currentIndex = currentFilters.indexOf(by)
            currentFilters.splice(currentIndex,1)
        } else {
            currentFilters.push(by)
        }
        setSimulationFilters(currentFilters)

        console.log(currentFilters)
        //take current Filters and reapply
        let newList = [];
        if (currentFilters.length > 0) {
            fullList.forEach(sim => {
                if (currentFilters.includes(sim.category)) {
                    newList.push(sim)
                }
            })
        } else {
            newList = fullList
        }
       
        // .filter((item) => currentFilters?.includes(item.category))
        // .filter(
        //     f => f.category.toLowerCase() === by.toLowerCase()
            
        //     );
            
        setSimulationsShown(newList)
        
    }

    return (
        <section className=" secondary-tabs tabs-card">
                <div className="tab-list-container">
               <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label={"Virtual learning experiences"}
                    >
                    <Tab label={<span>Career fields<span className="hidden-bold">Career fields</span></span>} disableRipple/>
                    <Tab label={<span>Companies<span className="hidden-bold">Companies</span></span>} disableRipple/>
                    <Tab label={<span>Just added<span className="hidden-bold">Just added</span></span>} disableRipple/>
                    <Tab label={<span>Saved<span className="hidden-bold">Saved</span></span>} disableRipple/>
                    </Tabs>
                    </div>
                    <div className="tab-content">
                    {activeTab === 0 ? 
                    <TabPanel
                            label='Career fields'
                            id='SimsTabOne'
                        >
                            <div className="secondary-card-list">
                                
                                <div className="card-header desktop-only">
                                    
                                    <div className={showOptions ? "chip-list full-list" : "chip-list condensed"} >
                                        
                                        {careerfields.map((field, index) => {
                                            if (showOptions) {
                                                 return (
                                                    <ToggleChip label={field} key={"field"+index} click={() => filterSims(field)}/>
                                                )
                                            } else {
                                                if (index < 5) {
                                                    return (
                                                        <ToggleChip label={field} key={"field"+index} click={() => filterSims(field)}/>
                                                    )
                                                }
                                            }
                                           
                                        })}
                                    </div>
                                    <button className="button link-btn" onClick={() => setShowOptions(!showOptions)}>{showOptions ? "Fewer options" :"More options"}</button>
                                </div>
                                <div className="flex-row" style={{alignItems:"center",marginBottom:"-12px"}}>
                                <h2 className="mobile-h6" style={{margin:0}}>Viewing {simulationsShown.length} results</h2>
                                <div className="mobile-only" style={{justifyContent:"flex-end"}}>
                                    <CareerFieldsDropdown filters={careerfields} click={(field) => filterSims(field)}/>
                                
                                </div>
                                </div>
                                
                                {/* <div className="card-row skills-group show-all"> */}
                                {/* <List
                                    className="card-row skills-group show-all"
                                    height={650}
                                    itemCount={simulations.length}
                                    itemSize={35}
                                    width={"100%"}
                                    itemData={simulations}
                                >
                                    {ItemRenderer}
                                   <ForageCard data={props.data[props.index]} modifier="forage-card" click={() => setModal({...modal, open:true,data:simulations[0]})}/> 
                                   </List> */}
                                {/* </div> */}
                                {/* <VirtualAndInfiniteScroll> */}
                                <div className="card-row skills-group show-all">
                                    {simulationsShown.map((sim, index) => {
                                        return (
                                            <VirtualScrollChild key={"simulation"+index}>
                                                <ForageCard data={sim} modifier="forage-card" click={() => setModal({open:true,data:sim})}/> 
                                            </VirtualScrollChild>
                                        )
                                    })}
                                {/* </VirtualAndInfiniteScroll> */}
                                </div>
                            </div>
                        </TabPanel>
                    : activeTab === 1 ?  <TabPanel
                            label='Companies'
                            id='SimsTabTwo'
                        >
                            <ForageFilterCompany setModal={(item) => setModal(item)} data={simulations}/>
                            
                        </TabPanel>
                    : activeTab === 2 ? <TabPanel
                            label='Just added'
                            id='SimsTabThree'
                        >
                            <div className="secondary-card-list">
                            <h2 className="mobile-h6">Viewing 4 results</h2>
                                <div className="card-row skills-group show-all">
                                    {simulations.slice(0,4).map((sim,index3) => {
                                        return (
                                            <ForageCard data={sim} key={"recent1"+index3} modifier="forage-card" click={() => setModal({open:true,data:sim})}/>
                                        )
                                    })}
                                   
                                </div>
                            </div>
                        </TabPanel>
                    :  <TabPanel
                            label='Saved'
                            id='SimsTabFour'
                        >
                            <div className="secondary-card-list">
                            <h2 className="mobile-h6">Viewing 1 result</h2>
                                <div className="card-row skills-group show-all">
                                    <ForageCard data={simulations[2]} key="Saved1" saved={true} modifier="forage-card saved" click={() => setModal({open:true,data:simulations[2]})}/>
                                </div>
                            </div>
                        </TabPanel>
                    }
                    </div>
               </section>
    )
}
export default ForageContent