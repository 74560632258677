import React from "react";
import Icon from "../global/Icon";

const InsightsModal = (props) => {

    return (
        <div className='modal-overlay'>
            <div className="modal insights-modal">
                <button className="button icon-btn close-btn" aria-label="close" onClick={props.close}>
                    <Icon icon="close" size="24"/>
                </button>
                {/* <div className="modal-header">
                    
                </div> */}
                {props.isDesktop ?<>
                    <div className="modal-body">
                        <h1 className="modal-title desktop-h3">
                                About your scores
                            </h1>
                            <h2 className="desktop-h4 bold">How your results were generated</h2>
                            <p className="body-text">Your percentile scores are based on a norm group of hundreds of students like you who have taken this assessment. The guidance you receive in your report is determined based on if you scored within the average range, above average, or below for each scale. </p>
                            <h2 className="desktop-h4 bold">Interpreting your scores</h2>
                            <p className="body-text">This assessment is about helping you understand your working style - there is no “right” or “wrong” here! The results reflect your personal preferences, not abilities. These insights are for you to leverage as you proceed with your job search. </p>
                        </div>
                    <div className="modal-footer">
                        
                        <button className="button primary medium" onClick={props.close}>
                            Got it
                        </button>
                    </div>
                </> : <>
                <div className="modal-body">
                        <h1 className="desktop-h4 bold">
                            About your scores
                        </h1>
                        <h2 className="mobile-h5 bold">Interpreting your scores</h2>
                        <p className="body-text">This assessment is about helping you understand your working style - there is no “right” or “wrong” here! The results reflect your personal preferences, not abilities. These insights are for you to leverage as you proceed with your job search. </p>
                        <h2 className="mobile-h5 bold">How your results were generated</h2>
                        <p className="body-text">Your percentile scores are based on a norm group of hundreds of students like you who have taken this assessment. The guidance you receive in your report is determined based on if you scored within the average range, above average, or below for each scale. </p>
                        <h2 className="mobile-h5 bold">50th percentile mark</h2>
                        <div className="bar-container">
                            <div className="bar"></div>
                            <div className="fifty">
                                    {/* <span>50th percentile </span> */}
                                </div>
                        </div>
                        <p className="body-text">The average across a representative norm group of students, which serves as a comparison point. </p>
                        </div>
                </>}
                
            </div>
        </div>
    )
}
export default InsightsModal