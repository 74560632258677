import React from "react";
import { Link } from "react-router-dom";
import JobCardMini from "./JobCardMini";

const JobsGroupCard = (props) => {
    //props
    //has preferences
    //has major

    console.log(props.major, props.preferences)

    return (
        <div className={`card jobs-${props.size}`}>
            <div className="card-header">
                <h2 className="card-title desktop-h4 bold">
                    {props.major && props.preferences ? "Jobs & internships for you" : props.preferences ? "Jobs & internships based on your job preferences" : "Jobs & internships based on your major"}</h2>
                
                    {/* {(props.major && props.preferences) ? <p className="body-text">Based on your <button className="link-btn" onClick={props.addMajor}>major</button> and <button className="link-btn" onClick={props.addPref}>job preferences</button></p>  */}
                    
                    {(props.major && props.preferences) ? <p className="body-text">Adjust your <button className="link-btn" onClick={props.addPref}>job preferences</button> for better recommendations</p> 
                    : props.preferences ? <p className="body-text">Adjust your <button className="link-btn" onClick={props.addPref}>preferences</button> or add your <button className="link-btn" onClick={props.addMajor}>major</button> for better recommendations</p>
                    : <p className="body-text">Add <button className="link-btn" onClick={props.addPref}>job preferences</button> for better recommendations</p>}
                
            </div>
            <div className="card-row jobs-group">
                {props.jobs?.map((job, index) => {
                    return (
                        <JobCardMini data={job} key={"recommendedJob"+index}/>
                        
                    )
                })}
            </div>
            <div className="card-footer flex-start">
                <Link to={process.env.PUBLIC_URL + "/jobs"} className="button small outline light">See more jobs</Link>
            </div>
        </div>
    )
}

export default JobsGroupCard