const getOverlays = () => {
    const hotspotRatio = 4200;
    const overlays = [
        {
            id : 'cluster2',
            code: '2.0000',
            x : 300/hotspotRatio,
            y : 1180/hotspotRatio,
            className : "drawer-btn cluster2",
            text: 'Architecture & Construction',
            clusterId: 'CC2',
            width: 486/hotspotRatio,
            height:636/hotspotRatio,
            img: 'hotspot_architecture.svg',
            hover: 'hotspot_architecture_hover.svg'
        },
        {
            id : 'cluster6',
            code: "6.0000",
            x : 1360/hotspotRatio,
            y : 1210/hotspotRatio,
            className : "drawer-btn cluster6",
            text: 'Finance',
            clusterId: 'CC6',
            width: 489/hotspotRatio,
            height:555/hotspotRatio,
            img: 'hotspot_finance.svg',
            hover: 'hotspot_finance_hover.svg'
        },
        {
            id : 'cluster13',
            code:"13.0000",
            x : 2240/hotspotRatio,
            y : 1190/hotspotRatio,
            className : "drawer-btn cluster13",
            text: 'Manufacturing',
            clusterId: 'CC13',
            width: 421/hotspotRatio,
            height:557/hotspotRatio,
            img: 'hotspot_manufacturing.svg',
            hover: 'hotspot_manufacturing_hover.svg'
        },
        {
            id : 'cluster14',
            code: "14.0000",
            x : 3100/hotspotRatio,
            y : 1230/hotspotRatio,
            className : "drawer-btn cluster14",
            text: 'Marketing',
            clusterId: 'CC14',
            width: 267/hotspotRatio,
            height:492/hotspotRatio,
            img: 'hotspot_marketing.svg',
            hover: 'hotspot_marketing_hover.svg'
        },
        
        {
            id : 'cluster18',
            code: "5.0000",
            x : 3550/hotspotRatio,
            y : 1400/hotspotRatio,
            className : "drawer-btn cluster18",
            text: 'Education & Training',
            clusterId: 'CC5',
            width: 579/hotspotRatio,
            height:543/hotspotRatio,
            img: 'hotspot_education.svg',
            hover: 'hotspot_education_hover.svg'
        },
        
        {
            id : 'cluster4',
            code: '4.0000',
            x : 140/hotspotRatio,
            y : 2060/hotspotRatio,
            className : "drawer-btn cluster4",
            text: 'Business Management & Administration',
            clusterId: 'CC4',
            width: 603/hotspotRatio,
            height:768/hotspotRatio,
            img: 'hotspot_business.svg',
            hover: 'hotspot_business_hover.svg'
        },
        {
            id : 'cluster7',
            code:"7.0000",
            x : 960/hotspotRatio,
            y : 1780/hotspotRatio,
            className : "drawer-btn cluster7",
            text: 'Government & Public Administration',
            clusterId: 'CC7',
            width: 570/hotspotRatio,
            height:603/hotspotRatio,
            img: 'hotspot_govt.svg',
            hover: 'hotspot_govt_hover.svg'
        },
        {
            id : 'cluster8',
            code:"8.0000",
            x : 1900/hotspotRatio,
            y : 1880/hotspotRatio,
            className : "drawer-btn cluster8",
            text: 'Health Science',
            clusterId: 'CC8',
            width: 405/hotspotRatio,
            height:486/hotspotRatio,
            img: 'hotspot_health.svg',
            hover: 'hotspot_health_hover.svg'
        },
        {
            id : 'cluster11',
            code:"11.0000",
            x : 2870/hotspotRatio,
            y : 1820/hotspotRatio,
            className : "drawer-btn cluster11",
            text: 'Information Technology',
            clusterId: 'CC11',
            width: 345/hotspotRatio,
            height:668/hotspotRatio,
            img: 'hotspot_it.svg',
            hover: 'hotspot_it_hover.svg'
        },
        {
            id : 'cluster3',
            code: '3.0000',
            x : 3360/hotspotRatio,
            y : 2240/hotspotRatio,
            className : "drawer-btn cluster3",
            text: 'Arts, A/V Technology & Communications',
            clusterId: 'CC3',
            width: 558/hotspotRatio,
            height:557/hotspotRatio,
            img: 'hotspot_arts.svg',
            hover: 'hotspot_arts_hover.svg'
        },
        {
            id : 'cluster17',
            code: "10.0000",
            x : 200/hotspotRatio,
            y : 3050/hotspotRatio,
            className : "drawer-btn cluster17",
            text: 'Human Services',
            clusterId: 'CC10',
            width: 441/hotspotRatio,
            height:360/hotspotRatio,
            img: 'hotspot_humanservices.svg',
            hover: 'hotspot_humanservices_hover.svg'
        },
        {
            id : 'cluster9',
            code:"9.0000",
            x : 950/hotspotRatio,
            y : 2750/hotspotRatio,
            className : "drawer-btn cluster9",
            text: 'Hospitality & Tourism',
            clusterId: 'CC9',
            width: 377/hotspotRatio,
            height:541/hotspotRatio,
            img: 'hotspot_hospitality.svg',
            hover: 'hotspot_hospitality_hover.svg'
        },
        {
            id : 'cluster15',
            code:"15.0000",
            x : 1700/hotspotRatio,
            y : 2610/hotspotRatio,
            className : "drawer-btn cluster15",
            text: 'Science, Technology, Engineering & Mathematics',
            clusterId: 'CC15',
            width: 567/hotspotRatio,
            height:714/hotspotRatio,
            img: 'hotspot_stem.svg',
            hover: 'hotspot_stem_hover.svg'
        },
        {
            id : 'cluster19',
            code: "12.0000",
            x : 2500/hotspotRatio,
            y : 2700/hotspotRatio,
            className : "drawer-btn cluster19",
            text: 'Law, Public Safety, Corrections & Security',
            clusterId: 'CC12',
            width: 525/hotspotRatio,
            height:546/hotspotRatio,
            img: 'hotspot_law.svg',
            hover: 'hotspot_law_hover.svg'
        },
        {
            id : 'cluster1',
            code : "1.0000",
            x : 1300/hotspotRatio,
            y : 3400/hotspotRatio,
            className : "drawer-btn cluster1",
            text: 'Agriculture, Food & Natural Resources',
            clusterId: 'CC1',
            width: 480/hotspotRatio,
            height:566/hotspotRatio,
            img: 'hotspot_food.svg',
            hover: 'hotspot_food_hover.svg'
        },
        {
            id : 'cluster16',
            code: "16.0000",
            x : 2800/hotspotRatio,
            y : 3300/hotspotRatio,
            className : "drawer-btn cluster16",
            text: 'Transportation, Distribution & Logistics',
            clusterId: 'CC16',
            width: 618/hotspotRatio,
            height:582/hotspotRatio,
            img: 'hotspot_transportation.svg',
            hover: 'hotspot_transportation_hover.svg'
        },
    ];

    return overlays;
};

export default getOverlays;
