import React, {useState} from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Menu from "./Menu";

const MegaMenu = (props) => {
    const [open, setOpen] = useState(false);

    const mouseLeaveFunc = (event) => {
        console.log(event.target)
        if (event.target.classList.contains("tab-action")) {
            //leave open
        } else {
            props.closeMenu()
        }
    }
    return (
        <div className="megamenu-container">
            <div className="tab-action-container" onMouseEnter={() => props.openMenu()} onMouseLeave={(event) => mouseLeaveFunc(event)}>
                <button className="tab-action" role="tab" onClick={() => props.tabClick()} aria-selected={props.selected}>
                    Explore careers
                    <span className="hidden-bold">Explore careers</span>
                </button>
                <button className="button icon-btn" onClick={props.open ? () => props.closeMenu() : () => props.openMenu()} aria-expanded={props.open} aria-label="More Careers pages" aria-controls="careers_menu">
                    <Icon icon="drop-down" size="18"/>
                </button>
            </div>
            
        </div>

    )
}

export default MegaMenu