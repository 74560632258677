import React, {useEffect, useState, useRef} from "react";
import FastFacts from "../../groups/FastFacts";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import CareerOverviewCarousel from "../../careers/CareerOverviewCarousel";
import FastFactsCarousel from "./FastFactsCarousel";

const CareersCardMobile = (props) => {
    const [active, setActive] = useState(props.active ? props.active : 0)

    const openAccordion = (event, index) => {
        if (active !== index) {
            let elementTop = window.pageYOffset + 80
             console.log(window.pageYOffset)
           //open
           setActive(index)
           props.select(index)
        
        // event.currentTarget.scrollIntoView({block:"start", behavior:"smooth"})
        
        //    window.scrollTo({top: elementTop, behavior:"smooth"})
        //    setTimeout(() => window.scrollBy(0,-80), 3000)

        } else {
            //do nothing
        }
    }

    return (
       <>
            {props.careers.length > 1 ? 
                <div className="card-body">
                    {props.careers.map((career,index) => {
                        return (
                            <div className={active === index ? "career-group open" :"career-group"} key={"career"+index}>
                                <button className="career-accordion-btn" aria-expanded={active === index} onClick={(event) => openAccordion(event,index)}>
                                        <span className="tab-label"><span dangerouslySetInnerHTML={{__html: career.title}}/></span>
                                        {career.altImage ? <span className="image"><img src={career.altImage} alt="placeholder"/></span> : career.image ? <span className="image"><img src={career.image} alt="placeholder"/></span> : ''}
                                </button>
                                <CSSTransition in={active === index} appear={true} classNames={"career-accordion"} timeout={300}>
                                    <div className="career-info-box">
                                        {/* <div className="card career-fast-facts">
                                            <h5 className="fast-facts-title">Fast facts</h5>
                                            <FastFactsCarousel data={career}/>
                                        </div> */}
                                        <FastFacts data={career}/>
                                    </div>
                                </CSSTransition>
                            </div>
                        )
                    })}
                    
                    
                    {/* <a className="button primary medium" href={process.env.PUBLIC_URL + "/careers"}>Explore more careers</a> */}
                </div> 
            
            : <div className="card-body">
                    <div className="career-group single open">
                        <span className="career-accordion-btn open">
                                {/* <span className="tab-label" dangerouslySetInnerHTML={{__html: career.title}}/> */}
                                <span className="image"><img src={props.careers[0].url} alt="placeholder"/></span>
                        </span>
                        <div className="career-info-box open">
                        <FastFacts data={props.careers[0]}/>
                            {/* <FastFacts data={props.careers[0]}/> */}
                        </div>
                    </div>
                    {/* <a className="button primary medium" href={process.env.PUBLIC_URL + "/careers"}>Explore more careers</a> */}
              </div>
                }
       </>
    )
}
export default CareersCardMobile