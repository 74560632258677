import React, {useEffect, useState, useRef} from "react";
import CareerBanner from "./careers/CareerBanner";
import { Link, useParams } from "react-router-dom";
import OccupationCard from "./global/cards/OccupationCard";
import careers from "../assets/data/careersMenu.json"; //for now
import recommended from "../assets/data/recommendedOccupations.json";
import saved from "../assets/data/savedOccupations.json";

const CareerPathwayDetail = (props) => {
    const [pageData, setPageData] = useState({});
    const params = useParams();
    const {pathwayId} = params;
    let clusterId
    let clusterImg = useRef(null);

    useEffect(() => {
        window.scrollTo(0,0)
    },[pathwayId])

    useEffect(() => {
        let newData;

        
        careers.forEach(cluster => {
            if (cluster.pathways.filter(f => f.code === pathwayId).length > 0) {
                clusterId = cluster.code;
                clusterImg.current = cluster.video.poster;
                newData = cluster.pathways.filter(f => f.code === pathwayId)
                return;
            }
        })
        console.log(newData)
        setPageData(newData[0])
    },[pathwayId, props])

    return (
        <main className="careers-main">
            <div className="careers-header intro">
                <CareerBanner title={pageData.title ? pageData.title : "Pathway title"} image={clusterImg.current} />
                
            </div>
           
            <section className="career-body">
            <div className="career-body-header">
                    <h2 className="desktop-h3 bold">Related occupations</h2>
                    <p className="body-text">Explore salary details, common responsibilities, and more for individual roles in this pathway.</p>
                </div>
                <div className="card-row three-three-three">
                    {pageData.occupations?.filter(f => recommended.includes(f.soc_code)).map((job) => {
                        
                        return (
                            <OccupationCard data={job} saved={saved.includes(job.soc_code)} recommended={recommended.includes(job.soc_code)}/>
                            
                        )
                    })}
                    {pageData.occupations?.filter(f => !recommended.includes(f.soc_code)).map((job) => {
                        
                        return (
                            <OccupationCard data={job} saved={saved.includes(job.soc_code)} recommended={recommended.includes(job.soc_code)}/>
                            
                        )
                    })}
                    
                        
                </div>
            </section>
        </main>
    )
}

export default CareerPathwayDetail