import React from "react";
import Icon from "../global/Icon";

const MyEducation = (props) => {

    if (props.user && props.user.education && props.user.education.length > 0) {
        return (

            <div className="card education-card">
                <div className="card-header">
                <div className="flex-row">
                        <h2 className="desktop-h4 bold card-title">Education</h2> 
                        <button className="button icon-btn add-btn">
                            <Icon icon="add" size="24"/>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    {props.user.education.map((edu, index) => {
                        return (
                            <div className="education-entry">
                                {edu.school ? 
                                <div className="education-info">
                                    <p><strong>School: </strong> {edu.school}</p>
                                    <p><strong>Major: </strong> {edu.major}</p>
                                    <p><strong>Graduation date: </strong> {edu.graduation}</p>
                                    </div> : <div className="education-info">
                                    <h3 className="desktop-h5 bold">{edu.title}</h3>
                                        <p><strong>Earned:</strong> {edu.date}</p>
                                    </div> }
                                    <button className="button icon-btn">
                                        <Icon icon="edit" size="24"/>
                                    </button>
                            </div> 
                        )
                    })}
                </div>
                
            </div>
        )
    } else {
        return (

            <div className="card education-card">
                <div className="card-header no-border">
                    <h2 className="desktop-h4 bold card-title">Education</h2>
                </div>
                <div className="card-body">
                    <button className="empty-area-button">
                        <span className="circle"><Icon icon="add" size="24"/></span>
                        <span>Include your school, major, and graduation date.</span>
                    </button>
                    
                </div>
            </div>
        )
    }
   
}
export default MyEducation