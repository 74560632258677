import React, {useEffect, useState} from "react";
import Icon from "./Icon";

const BackToTop = () => {
    const [showScrollButton, setShowScrollButton] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', e => {
            if (window.scrollY > 80) {
                setShowScrollButton(true)
            } else {
                setShowScrollButton(false)
            }
        })
        return () => {
            window.removeEventListener('scroll', e => {
                if (window.scrollY > 80) {
                    setShowScrollButton(true)
                } else {
                    setShowScrollButton(false)
                }
            })
        }
    })
    return (
        <button className={showScrollButton ? "back-to-top show":"back-to-top"} aria-label="back to top" onClick={() => window.scrollTo({top: 0, left: 0,behavior: "smooth"})}>
            <Icon icon="collapse" size="24"/>
        </button>
    )
}
export default BackToTop