import React, {useState} from "react";

const FilterMenuOffice = (props) => {
    const [checked, setChecked] = useState(props.data.selected)

    const handleCheck = (item) => {
        let currentChecked = [item]
        // if (currentChecked.indexOf(item) > -1) {
        //     currentChecked.splice(currentChecked.indexOf(item),1)
        // } else {
        //     currentChecked.push(item)
        // }
        setChecked(currentChecked)
        props.modify(currentChecked)
    }

    return (
        <div className="filter-body">
            <div className="form-group">
            {/* <p className="group-label" style={{marginBottom:"12px"}}>Office type</p> */}
                {/* <div className="gr-radio">
                    <input type="radio" name="officeLocation" id="officeLocation1" value="all"
                        onChange={() => handleCheck("All")}
                        defaultChecked={props.data.selected.includes("All")}
                    />
                    <label htmlFor="officeLocation1">
                        <span>All Onsite/Remote</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                    
                </div> */}
                <div className="checkbox">
                    
                    <label htmlFor="officeLocation1">
                        <input type="checkbox" name="officeLocation" id="officeLocation1" value="onsite" 
                        onChange={() => handleCheck("Onsite")}
                        defaultChecked={props.data.selected.includes("Onsite")}/>
                        <span>Onsite</span>
                    </label>
                    
                </div>
                <div className="checkbox">
                    
                    <label htmlFor="officeLocation3">
                        <input type="checkbox" name="officeLocation" id="officeLocation3" value="hybrid" 
                            onChange={() => handleCheck("Hybrid")}
                            defaultChecked={props.data.selected.includes("Hybrid")}/>
                        <span>Hybrid</span>
                    </label>
                </div>
                <div className="checkbox">
                    <label htmlFor="officeLocation2">
                        <input type="checkbox" name="officeLocation" id="officeLocation2" value="remote"
                            onChange={() => handleCheck("Remote")}
                            defaultChecked={props.data.selected.includes("Remote")} />
                        <span>Remote</span>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default FilterMenuOffice