import React, {useState} from "react";
import Icon from "../Icon";

const ToggleChip = (props) => {
    const [selected, setSelected] = useState(false);



    return (
        <button className="chip-btn" aria-pressed={selected} onClick={() => {setSelected(!selected);props.click()}}>
            {selected && <Icon icon="correct" size="18"/>}
            {props.label}
        </button>
    )
}
export default ToggleChip