import React from "react";
import Icon from "../global/Icon";

const InsightsBarModal = (props) => {

    return (
        <div className='modal-overlay'>
            <div className="modal insights-modal bar-modal">
                <button className="button icon-btn close-btn" aria-label="close" onClick={props.close}>
                    <Icon icon="close" size="24"/>
                </button>
                <div className="modal-body">
                    <h1 className="desktop-h4 bold">
                        More about this graph
                    </h1>
                    <div className="group">
                        <div className="bar-container">
                            <div className="bar" style={{width:"10%"}} role="img" aria-label="meter filled to 10 percent">
                                <span></span>
                            </div>
                            <div className="fifty"></div>
                            
                        </div>
                        <h2 className="mobile-h5 bold">If you score on the lower end</h2>
                        <p className="body-text">{props.data.minimum.title}:</p>
                        <p className="body-text">{props.data.minimum.details}</p>
                    </div>
                    <div className="group">
                        <div className="bar-container">
                            <div className="bar" style={{width:"90%"}} role="img" aria-label="meter filled to 90 percent">
                                <span></span>
                            </div>
                            <div className="fifty"></div>
                            
                        </div>
                        <h2 className="mobile-h5 bold">If you score on the higher end</h2>
                        <p className="body-text">{props.data.maximum.title}:</p>
                        <p className="body-text">{props.data.maximum.details}</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default InsightsBarModal