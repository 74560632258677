import React from "react";
import workStyle from "../../../assets/images/cta/work-style-large.png";
import assessment25 from "../../../assets/images/cta/assessment-progress-25-lg.png";
import assessment50 from "../../../assets/images/cta/assessment-progress-50-lg.png";
import assessment75 from "../../../assets/images/cta/assessment-progress-75-lg.png";
import workStyleResults from "../../../assets/images/cta/assessment-results-lg.png";

const AssessmentCTACard = (props) => {
    //props: part #, state (progress #, results ready)
   console.log(props) 
   console.log(typeof (props.progress))


    return (
        //props.part === 1 && props.progress > 0 no extra class
        
        <div className={`card dark action-card`}>
            {props.part === 1 && props.progress === 0 ? 
            // not started
                <>
                 <div className="card-message">
                    <h2 className={`card-title desktop-h1`}>Where do you fit?</h2>
                    <p className="body-text">Take the work style assessment to learn where your strengths shine.</p>
                    <div className="card-actions">
                        <button className="button small primary">Launch assessment</button>
                        <button className="button small light filled">Learn more</button>
                    </div>
                </div>
                <div className="card-image">
                    <div className="image" style={{padding:"35px 0"}}>
                        <img src={workStyle} alt="cta image alt text"/>
                    </div>
                </div>
                </>
            : props.part === 1 && props.progress === 100 && props.resultsReady ? 
            //part 1 done, part 2 not started, results ready
            <>
                 <div className="card-message">
                    <h2 className={`card-title desktop-h1`}>Want a sneak peak?</h2>
                    <p className="body-text">Take a look at your part 1 results before jumping into part 2.</p>
                    <div className="card-actions">
                        <button className="button small primary">Start part 2</button>
                        <button className="button small light filled">View part 1 results</button>
                    </div>
                </div>
                <div className="card-image">
                    <div className="image">
                        <img src={assessment50} alt="cta image alt text"/>
                    </div>
                </div>
                </>
            : props.part === 1 && props.progress === 100 ? 
            //part 1 done, results not ready
            <>
                 <div className="card-message">
                    <h2 className={`card-title desktop-h1`}>One part down</h2>
                    <p className="body-text">Part 2 is all about finding your strengths. Get started while we prepare your part 1 results.</p>
                    <div className="card-actions">
                        <button className="button small primary">Start part 2</button>
                        {/* <button className="button small light filled">Learn more</button> */}
                    </div>
                </div>
                <div className="card-image">
                    <div className="image">
                        <img src={assessment50} alt="cta image alt text"/>
                    </div>
                </div>
                </>
            : props.part === 1 && props.progress > 0 ? 
            //part 1 in progress
            <>
                 <div className="card-message">
                    <h2 className={`card-title desktop-h1`}>You're this close</h2>
                    <p className="body-text">Take a few minutes to finish part 1 of the work style assessment and see your results.</p>
                    <div className="card-actions">
                        <button className="button small primary">Finish part 1</button>
                        {/* <button className="button small light filled">Learn more</button> */}
                    </div>
                </div>
                <div className="card-image">
                    <div className="image">
                        <img src={assessment25} alt="cta image alt text"/>
                    </div>
                </div>
                </>
            : props.part === 2 && props.progress === 100 ? <>
                <div className="card-message">
                    <h2 className={`card-title desktop-h1`}>Your results are in!</h2>
                    <p className="body-text">Take a look at your full work style results.</p>
                    <div className="card-actions">
                        <button className="button small primary">View results</button>
                        {/* <button className="button small light filled">View part 1 results</button> */}
                    </div>
                </div>
                    
                <div className="card-image" style={{padding:"24px 0"}}>
                    
                        <div className="image" style={{maxHeight:"186px"}}>
                            <img src={workStyleResults} alt={"graphic"}/>
                        </div>
                    
                </div>
                
            </>
            : props.part === 2 && props.progress > 0 ? <>
            <div className="card-message">
               <h2 className={`card-title desktop-h1`}>Let’s wrap this up</h2>
               <p className="body-text">Finish part 2 to get your full work style results.</p>
               <div className="card-actions">
                   <button className="button small primary">Finish part 2</button>
                   <button className="button small light filled">View part 1 results</button>
               </div>
           </div>
           <div className="card-image">
               <div className="image">
                   <img src={assessment75} alt="cta image alt text"/>
               </div>
           </div>
           </>
            : <>
            <div className="card-message">
                <h2 className={`card-title desktop-h1`}>Where do you fit?</h2>
                <p className="body-text">Take the work style assessment to learn where your strengths shine.</p>
                <div className="card-actions">
                    <button className="button small primary">Launch assessment</button>
                    <button className="button small light filled">Learn more</button>
                </div>
            </div>
            <div className="card-image">
                <div className="image" style={{padding:"35px 0"}}>
                    <img src={workStyle} alt="cta image alt text"/>
                </div>
            </div>
           </>
            }
            
            
        </div>
    )
}
export default AssessmentCTACard