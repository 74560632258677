import React, {useEffect, useRef, useState} from "react";
import CertificatePopup from "./CertificatePopup";

const SuggestedCertificates = (props) => {
    const [active, setActive] = useState(null);
    const [position, setPosition] = useState({style:{}, arrow:"left"});

    const parent = useRef();


    useEffect(() => {
        //is parent doesnt contain event target mouse then hide

        const hideMouse = (event) => {
            // console.log(event.target)
            if (parent.current.contains(event.target)) {
                //do nothing
            } else {
                setActive(null)
            }
        }

        document.addEventListener("mousemove", hideMouse)

        return () => {
            document.removeEventListener("mousemove", hideMouse)
        }
    },[])

    const getPosition = (event) => {
        let item = event.currentTarget.getBoundingClientRect(),
            parentPos = parent.current.getBoundingClientRect(),
            windowWidth = window.innerWidth;

            // console.log(item)

       let leftAmount = 0;

       if (item.left < (0.5 * windowWidth)) {
            leftAmount =  item.left - parentPos.left;
            if (leftAmount >= 0) {
                setPosition({style:{left:leftAmount+"px"},arrow:"left"})
            } else {
                setPosition({style:{left:"-24px"},arrow:"left"})
            }
            
       } else if (item.right > parentPos.right) {
        setPosition({style:{right:"0px"},arrow:"right"}) 
       } else {
        setPosition({style:{right:parentPos.right - item.right + "px"},arrow:"right"}) 
       }
            

    }

    return (
        <div className="suggested-certificates desktop-only" ref={parent}>
                <div className="card-body card-row">
                    {props.suggested.map((card, index) => {
                        return (
                            <button className="card pathway-card" 
                                onClick={(event) => {getPosition(event);setActive(card)}}
                                onMouseOver={(event) => {getPosition(event);setActive(card)}}
                                // onMouseLeave={(event) => setPosition({style:{display:'none'},arrow:"left"})}
                                >
                                {index < 2 &&  <span className="card-label ">
                                    Recommended
                                </span> }
                                <div className="image">
                                    <img src={card.thumbnail} alt=""/>
                                </div>
                                <div className="card-text">
                                    <h3 className="card-title">{card.title}</h3>
                                    <p className="issuer">{card.issuer}</p>
                                </div>
                            </button>
                        )
                    })}
                    
                    
                </div>
               <CertificatePopup visible={active !== null} data={active} position={position} more={() => props.more(active)} add={() => props.add(active)}/>
        </div>
    )
}
export default SuggestedCertificates