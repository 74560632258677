import React, {useState, useEffect, useRef} from "react";

const Coachmark = (props) => {

    const ref = useRef()

    useEffect(() => {
        if (ref.current) {
            ref.current.querySelector("button").focus()
        }
    },[])

    return (
        <div ref={ref} className={`coachmark fadeInUp ${props.position}`} role="dialog" aria-labelledby="coachmark-title" aria-describedby="coachmark-body">
            <h2 className="coach-title" id="coachmark-title">{props.title}</h2>
            <p className="coach-body" id="coachmark-body">{props.body}</p>
            <div className="coach-actions">
                <button className="button primary small" onClick={() => props.click()}>Got it</button>
            </div>
        </div>
    )
}
export default Coachmark