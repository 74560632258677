import React, {useState} from "react";

const FilterMenuCompensation = (props) => {
    const [checked, setChecked] = useState(props.data.selected)

    const handleCheck = (item) => {
        let currentChecked = [item]
        // if (currentChecked.indexOf(item) > -1) {
        //     currentChecked.splice(currentChecked.indexOf(item),1)
        // } else {
        //     currentChecked.push(item)
        // }
        setChecked(currentChecked)
        props.modify(currentChecked)
    }
    return (
        <div className="filter-body">
            <div className="form-group radio-options">
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation1" value="0" 
                    onChange={() => handleCheck("0")}
                    defaultChecked={props.data.selected.includes("0")}
                    />
                    <label htmlFor="compensation1">
                        <span>$0</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation2" value="15" 
                    onChange={() => handleCheck("15")}
                    defaultChecked={props.data.selected.includes("15")}/>
                    <label htmlFor="compensation2">
                        <span>$15,000+</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation3" value="20" 
                    onChange={() => handleCheck("20")}
                    defaultChecked={props.data.selected.includes("20")}/>
                    <label htmlFor="compensation3">
                        <span>$20,000+</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation4" value="35"
                    onChange={() => handleCheck("35")}
                    defaultChecked={props.data.selected.includes("35")} />
                    <label htmlFor="compensation4">
                        <span>$35,000+</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation5" value="42"
                    onChange={() => handleCheck("42")}
                    defaultChecked={props.data.selected.includes("42")} />
                    <label htmlFor="compensation5">
                        <span>$42,000+</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation6" value="55"
                    onChange={() => handleCheck("55")}
                    defaultChecked={props.data.selected.includes("55")} />
                    <label htmlFor="compensation6">
                        <span>$55,000+</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
                <div className="gr-radio">
                    <input type="radio" name="compensation" id="compensation8" value="80"
                    onChange={() => handleCheck("80")}
                    defaultChecked={props.data.selected.includes("80")} />
                    <label htmlFor="compensation8">
                        <span>$80,000+</span>
                    </label>
                    <span>
                        <svg viewBox='0 0 18 18' width='18' height='18'>
                            <circle r='6' cx='9' cy='9' />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}
export default FilterMenuCompensation