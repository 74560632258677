import React, { useEffect, useState } from "react";

const CertificatePopup = (props) => {
    const [open, setOpen] = useState(props.visible);


    useEffect(() => {
        setOpen(props.visible)
    },[props.visible])

    useEffect(() => {
        //is mouse isn't over card or popup then close popup

    })

    return (
        <div className={open ? "pop-up-container popIn" : "pop-up-container hide"} 
            style={props.position.style}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            >
            
            {props.data && <div className="certificate-popup ">
            <div className={`arrow ${props.position.arrow}`}/>
            <h4 className="desktop-h4 bold">{props.data.title}</h4>
            <p>{props.data.shortDesc}</p>
            <div className="certificate-info-group">
                <h5 className="desktop-h6 bold">
                    Skills you&rsquo;ll gain with this certificate:
                </h5>
                <div className="chip-list">
                    {props.data.skills.map((item,index) => {
                        return (
                            <div className="chip-label chip-btn" key={"skill"+index}>
                                {item}
                            </div>
                        )
                    })}
                    
                   
                </div>
            </div>
            <div className="button-row">
                <button className="button small outline light filled" onClick={() => props.more()}>See details</button>
                <button className="button small dark filled" onClick={() => props.add()}>Add to path</button>
            </div>
            </div> }
        </div>
    )
}
export default CertificatePopup