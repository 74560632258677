import React, {useState} from "react";
import Icon from "../Icon";

const SkillCard = (props) => {
    const [saved, setSaved] = useState(props.data.saved ? props.data.saved : false);

    const bookmark = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setSaved(!saved)
    }

    return (
        <a className={`card skill-card ${props.modifier} ${saved ? "saved" : ""}`} href="#">
                <button className="button icon-btn bookmark-btn" aria-pressed={saved}
                    onClick={(e) => bookmark(e)}>
                    <Icon icon={saved ? "bookmark-on":"bookmark-off"} size="24"/></button>
            <div className={"image video"}>
                <div className="play-icon">
                    <svg width="22" height="22" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><g filter="url(#filter0_d_2120_12483)"><path d="M53.3136 39.891L17.8459 60.5101C14.836 62.2582 10.9731 60.1415 10.9731 56.6175V15.3793C10.9731 11.8609 14.8304 9.73868 17.8459 11.4923L53.3136 32.1114C53.9983 32.503 54.5675 33.0691 54.9634 33.7523C55.3592 34.4354 55.5677 35.2113 55.5677 36.0012C55.5677 36.7912 55.3592 37.567 54.9634 38.2502C54.5675 38.9333 53.9983 39.4994 53.3136 39.891Z" fill="white"></path></g><defs><filter id="filter0_d_2120_12483" x="0.973145" y="0.864868" width="64.5947" height="70.2703" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="5"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2120_12483"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2120_12483" result="shape"></feBlend></filter></defs></svg>
                </div>
                <img src={process.env.PUBLIC_URL + "/images/skills/" + props.data.thumbnail} alt={"thumbnail"}/>
                {props.affiliate && <span className="avatar logo" dangerouslySetInnerHTML={{__html: props.data.affiliate}}/>}
                {props.tag && <span className="tag">{props.data.tag}</span>}
                {props.message && <div className="circle message"><Icon icon="chat" size="24"/></div>}
            </div>
            <div className="card-body card-info">
                {props.data.tutor && <div className="avatar">
                    <img src={props.data.tutor.image} alt={props.data.tutor.name}/>
                </div>}
                
                <div className="card-info">
                    <h3 className="card-title">{props.data.title}</h3>
                    <p className="card-subtitle author-name">{props.data.author}</p>
                    
                    {!props.data.progress && 
                    <p className="card-meta">
                        <span>{props.data.length}</span>
                        <span>{props.data.cost}</span>
                    </p> }
                </div>
            </div>
            {props.data.progress && <div className="progress-bar-container"><div className="progress-bar"><div className="bar" style={{width:props.data.progress + "%"}}/></div><span className="progress-bar-label">{props.data.progress}% complete</span></div>}

            
        </a>
    )
}
export default SkillCard