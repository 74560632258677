import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Icon from "../global/Icon";

const InsightsBar = (props) => {
    const [placement,setPlacement] = useState('');
    const [style, setStyle] = useState({})
    let data = props.data;
    const isDesktop = useMediaQuery('(min-width:808px)');
    const bar = useRef();
    const percentile = useRef();



    const formatPercentile = (number) => {
        //get last digit
        let digit = number.toString().slice(-1);
        let suffix
        if (number > 9 && number < 21) {
            suffix = 'th'
        }
        else if (digit === '1') {
            suffix = 'st'
        } else if (digit === '2') {
            suffix = 'nd'
        } else if (digit === '3') {
            suffix = 'rd'
        }else {
            suffix = 'th'
        }

        return number + suffix
    }
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#DDE3EE",
            "&::before": {
                backgroundColor: "#DDE3EE"
               }
          },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: "#DDE3EE",
          color: '#333',
          fontSize: 14,
          textAlign:'center',
          maxWidth:'270px',
          padding: '6px 12px',
          fontWeight:'600'
        },
      }));

    const percentilePlacement = () => {
       let barRight = bar && bar.current ? bar.current.getBoundingClientRect().right : 0,
            barLeft = bar && bar.current ? bar.current.getBoundingClientRect().left : 0,
            labelRight = percentile && percentile.current ? percentile.current.getBoundingClientRect().right : 0,
            labelLeft = percentile && percentile.current ? percentile.current.getBoundingClientRect().left : 0;

            console.log(barRight, labelRight, barLeft, labelLeft)
            console.log(Math.floor(labelRight - barRight))
            setStyle(data.value === 100 ? {
                right:'-4px',
                transform:'translateX(0)'
            } :(labelLeft < barLeft) ? {
                left: 0,
                right:'unset'
            } : (labelRight > barRight) ? {
                right: Math.floor(labelRight - barRight) + 'px'
            } : {})
        setPlacement((labelLeft < barLeft) ? 'align-left' : (labelRight > barRight) ? 'align-right' : '')
    }
    useEffect(() => {
        percentilePlacement();
        window.addEventListener('resize', percentilePlacement);
        return () => window.removeEventListener('resize', percentilePlacement);
      }, []);

    if (isDesktop) {
        return (
            <div className={`insights-bar-container ${props.first ? 'first-bar' :''}`}>
            <div className="bar-container" ref={bar}>
                <div className="bar" style={{width: data.value + "%"}}>
                    <span ref={percentile} className={placement} style={style}><strong>You</strong> {formatPercentile(data.value)} percentile</span>
                </div>
                <div className="fifty">
                    {props.first && <LightTooltip placement="top" title="The average across a representative norm group of students" arrow slotProps={{
                                        popper: {
                                        modifiers: [
                                            {
                                            name: 'offset',
                                            options: {
                                                offset: [0, 40],
                                            },
                                            },
                                        ],
                                        },
                                    }}>
                        <span>50th percentile <span className="icon"><Icon icon="info" size="18"/></span></span></LightTooltip> }
                </div>
            </div>
            <div className="labels">
                <div className="minimum-label">
                    <strong>{data.minimum.title}</strong>
                    <span className="bar-description">
                        {data.minimum.details}
                    </span>
                </div>
                <div className="maximum-label">
                    <strong>{data.maximum.title}</strong>
                    <span className="bar-description">
                        {data.maximum.details}
                    </span>
                </div>
            </div>
            
            
        </div>
        )
    } else {
        return (
        
            <div className="insights-bar-container">
                <button className="button icon-btn info-btn" aria-haspopup="dialog" aria-label={"More about this graph, " + data.title} onClick={props.info}><Icon icon="info" size="18"/></button>
                <div className="bar-container" ref={bar}>
                    <div className="bar" style={{width: data.value + "%"}}>
                        <span ref={percentile} className={placement} style={style}><strong>You</strong> {formatPercentile(data.value)} percentile</span>
                    </div>
                    <div className="fifty">
                        {/* <span>50th percentile </span> */}
                    </div>
                </div>
                <div className="labels">
                    <div className="minimum-label">
                        <span>{data.minimum.title}</span>
                        {/* <span className="bar-description">
                            {data.minimum.details}
                        </span> */}
                    </div>
                    <div className="fifty">
                        <span>50th <br/>percentile </span>
                    </div>
                    <div className="maximum-label">
                        <span>{data.maximum.title}</span>
                        {/* <span className="bar-description">
                            {data.maximum.details}
                        </span> */}
                    </div>
                </div>
                
                
            </div>
        )
    }

    
}

export default InsightsBar