import React, {useState, useRef} from "react";
import Icon from "../global/Icon";
import InsightsBar from "./InsightsBar";
// import { Tooltip } from "@mui/material";

const InsightsGroupMobile = (props) => {
    const [open, setOpen] = useState(false);
    const data = props.data
    const nodeRef = useRef(null);

    
    return ( 
        <>
        {props.hideData ? 
            <div className={`insights-group ${props.type} nodata`}>
                <div className="insights-title">
                    <h2 className="mobile-h4 bold text">
                        {data.title}:
                    </h2>
                </div>
                <div className="insights-body">
                    <p className="insights-definition mobile-h6">Definition: {data.description}</p>
                    <div className="insights-bar-container">
                        <div className="bar-container">
                            <div className="bar" style={{width: "0%"}}>
                                {/* <span><strong>You</strong> {formatPercentile(data.value)} percentile</span> */}
                            </div>
                            <div className="fifty">
                                {/* <span>50th percentile <span className="icon"><Icon icon="info" size="18"/></span></span> */}
                            </div>
                        </div>
                    </div>
                </div>
                
            </div> 
        
        : 
        <div className={`insights-group ${props.type} ${open ? "expanded" : ""}`}>
            <button className="insights-title" aria-expanded={open} onClick={() => setOpen(!open)}>
                <h2 className="mobile-h4 bold text">
                    {data.title}:
                </h2>
                <span className="icon">
                    <Icon icon="expand" size="24"/>
                </span>
            </button>
            
                    <div className="insights-body">
                        <p className="insights-definition mobile-h6">Definition: {data.description}</p>
                        <InsightsBar data={data} first={false} info={props.info}/>
                        
                        <div className="insights-more-details" ref={nodeRef}>
                            <div className="insights-meaning">
                                <h3 className="body-text small bold">What does your score mean?</h3>
                                {data.meaning.map((meaning,index) => {
                                    return <p key={"para" + index} className="body-text small" dangerouslySetInnerHTML={{__html: meaning.content}}/>
                                })}
                            </div>
                            <div className="insights-improve">
                                <h3 className="body-text small bold">How can you grow your skills in this area?</h3>
                                {data.howToImprove.map((meaning,index) => {
                                    return <p key={"paraM" + index} className="body-text small" dangerouslySetInnerHTML={{__html: meaning.content}}/>
                                })}
                            </div>
                        </div>
                     
                    </div>
                
        </div>
        }
        </>
    )
}
export default InsightsGroupMobile