import React, { useEffect, useState } from "react";
import Header from "./global/Header";
import Icon from "./global/Icon";

import VerticalNav from "./navigation/VerticalNav";
import AccountMenu from "./global/AccountMenu";
import ModeToggle from "./global/ModeToggle";
import IntroBG from "../assets/images/container_transparent.png";
import jobImg from "../assets/images/job-searching-platform.png"
import teacherImg from "../assets/images/female-graduated-teacher.png"
import searchImg from "../assets/images/young-man-finding-online-jobs-in-smartphone.png"
import workFit from "../assets/images/work-fit.png";
import assessment from "../assets/images/assessment.png";
import workFitResults from "../assets/images/work-fit-results.png";
import workFitResultsLight from "../assets/images/work-fit-results-light.png";
import welcome1 from "../assets/images/welcome.png";
import welcome2 from "../assets/images/welcome2.png";
import welcome3 from "../assets/images/welcome-grid-reading.png";
import workStyle from "../assets/images/work-style-group2x.png";
import workStyleLight from "../assets/images/work-style-group-light.png";
import workStyleResults from "../assets/images/work-style-results.png";
import assessmentComplete from "../assets/images/assessment-complete-vertical-light.png";
import assessmentCompleteD from "../assets/images/assessment-complete-vertical.png";
import assessmentPart1Complete from "../assets/images/assessment-part1-complete-horizontal.png"
import assessmentPart1CompleteD from "../assets/images/assessment-part1-complete-vertical-dark.png";
import assessmentPart1Waiting from "../assets/images/assessment-part1-complete.png";
import assessmentPart2Progress from "../assets/images/assessment-part2-inprogress.png";
import ForageLogo from "../assets/images/Forage-logo.png";
import skillCourses from "../assets/data/proCourses.json";
import videoLearning from "../assets/data/videoLearning.json";
import CareerTabs from "./groups/CareerTabs";
import FastFacts from "./groups/FastFacts";
import careers from "../assets/data/careers.json";
import TabPanel from "./global/tabs/TabPanel"
import HorizontalNavMenu from "./navigation/HorizontalNav";
import Footer from "./global/Footer";
import SkillCard from "./global/cards/SkillCard";
import JobCardMini from "./global/cards/JobCardMini";
import Toast from "./global/Toast";
import CTACard from "./global/cards/CTACards";
import SavedJobsCarousel from "./global/cards/SavedJobsCarousel";
import CareersCardMobile from "./global/cards/CareersCardMobile";
import CareerOverviewCarousel from "./careers/CareerOverviewCarousel";
import CareersCard from "./global/cards/CareersCard";

const SandboxCareers = (props) => {
    const options = ["Jobs","Careers","Skills", "Profile"];
    const [activeTab, setActiveTab] = useState(props.active);
    const introMessages = (scenario) => {
        if (scenario === "intent") {
            return {heading: "Welcome!", text: "We're glad you're here."}
        } else if (scenario === "new") {
            return {heading: "Welcome to Job Match!", text: "Don't know where to start? We can help you prepare for your future."}
        } else if (scenario === "returning") {
            return {heading: "Welcome back!", text: "Land your dream job."}
        } else if (scenario === "engaged" ) {
            return {heading: "Welcome back!", text: "Let's chart your course."}
        }
    }
    const recommendedJobs = [
        {
            title: "Entry Level Electrical Engineer",
            company:"Arcadis",
            avatar: "https://media.licdn.com/dms/image/D560BAQFAyhh51gC9dw/company-logo_100_100/0/1688175751520?e=2147483647&v=beta&t=iK-C4WvTeyM9IFAJqV6veo2QK3h7AdQMPh6czTH4fkQ",
            type: "Full time",
            location: "Boston, MA",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        },
        {
            title: "Electronics Engineer",
            company:"U.S. Department of Commerce",
            avatar: "https://media.licdn.com/dms/image/C4D0BAQEY8SR1kFutMQ/company-logo_100_100/0/1648577411471?e=2147483647&v=beta&t=0b7hrFw65W2QW__UJpqwS4C4wOEJTEO2v_YSNuXj5FM",
            type: "Full time",
            location: "Boulder, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        },
        {
            title: "Electrical Designer - Entry",
            company:"Power Design, Inc.",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQE32SZ0OyPrig/company-logo_100_100/0/1688561445332?e=2147483647&v=beta&t=cnY_Eic2qQvTrT919O-m9AiB3r0yE1J_B4EPIrfvaGQ",
            type: "Full time",
            location: "Saint Petersburg, FL",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "https://media.licdn.com/dms/image/D560BAQExWVK4Gj-SZw/company-logo_100_100/0/1688476635974?e=2147483647&v=beta&t=quaLK2WCwycr684FFFyE1vs7HCgZ8PJSfEA7kN_G7QY",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: false
        }
    ]
    const savedJobs = [
        {
            title: "Entry Level Electrical Engineer",
            company:"Arcadis",
            avatar: "",
            type: "Full time",
            location: "Boston, MA",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electronics Engineer",
            company:"U.S. Department of Commerce",
            avatar: "",
            type: "Full time",
            location: "Boulder, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Designer - Entry",
            company:"Power Design, Inc.",
            avatar: "",
            type: "Full time",
            location: "Saint Petersburg, FL",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        },
        {
            title: "Electrical Engineer/Designer",
            company:"Actalent",
            avatar: "",
            type: "Full time",
            location: "Denver, CO",
            category: "Engineering",
            posted: "3 months ago",
            saved: true
        }
    ]

    return (
        <div className={`page-wrapper horizontal-nav sandbox ${props.option}`}>
            {/* <VerticalNav/> */}
            <div className="swoop"></div>
            
            <HorizontalNavMenu active="dashboard"/>
            
            <main>
               
                <section>
                    <h1>Careers</h1>
                    <CareersCard title={"Explore Architecture and Engineering"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0]]}/>

                    <CareersCard title={"See what's out there"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0],careers[14]]}/>
                    <CareersCard title={"See what's out there"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0],careers[14],careers[20]]}/>
                    <CareersCard title={"See what's out there"} description={"Explore job market data, current openings, and salary expectations to find a career path that feels right."} careers={[careers[0],careers[14],careers[20],careers[3]]}/>
                    
                </section>
            </main>           

            <Footer/>
        </div>
    )
}
export default SandboxCareers