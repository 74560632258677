import React, {useState} from "react";
import Icon from "../Icon";

const RemoveChip = (props) => {
    // const [selected, setSelected] = useState(props.checked ? props.checked : false);

    const removeChip = (event) => {
         event.stopPropagation() //is opening the input for some reason
        props.remove()
    }
    return (
        <div className={`chip-btn search-filter-chip with-remove`}>
            <span>{props.label}</span>
            <button className="button small icon-btn" aria-label={"Remove " + props.label} onClick={(event) => removeChip(event)}><Icon icon="close" size="18"/></button>
        </div>
    )
}
export default RemoveChip