import React, {useState, useEffect, useRef} from "react";

const PersonalizingLoader = React.forwardRef((props, ref) => {
    const loader = useRef(null);
    // useEffect(() => {
    //     loader.current.scrollIntoView({behavior:'smooth'})
    // },[])

    return (
        <section className="loader" ref={loader}>
            <div className="card">
                <div className="card-header">
                    <h3 className="desktop-h3">Jobs related to this badge</h3>
                    <p>&nbsp;</p>
                </div>
                <div className="card-body">
                    <div className="card-row jobs-group single-row">
                        <div className="card skeleton" style={{minHeight:"112px"}}/>
                        <div className="card skeleton" style={{minHeight:"112px"}}/>
                        <div className="card skeleton" style={{minHeight:"112px"}}/>
                        <div className="card skeleton" style={{minHeight:"112px"}}/>
                    </div>
                </div>
                <div className="card-header" style={{marginTop:"80px"}}>
                    <h3 className="desktop-h3">What's next</h3>
                    <p>&nbsp;</p>
                </div>
                <div className="card-body">
                    <div className="card-row skills-group">
                        <div className="card skeleton" style={{minHeight:"328px"}}/>
                        <div className="card skeleton" style={{minHeight:"328px"}}/>
                        <div className="card skeleton" style={{minHeight:"328px"}}/>
                        <div className="card skeleton" style={{minHeight:"328px"}}/>
                    </div>
                </div>
            </div>
            <div className="personalizing-spinner">
                <span className="ellipsis">
                    Personalizing
                </span>
                <div className="spinner">
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div>
            </div>

        </section>
    )
})
export default PersonalizingLoader