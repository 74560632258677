import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import ToggleChip from "../global/chips/ToggleChip";
import Icon from "../global/Icon";

const CareerFieldsDropdown = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="filter-menu career-fields-dropdown">
            <button className="button icon-btn medium" aria-expanded={open} onClick={() => setOpen(!open)}>
                <Icon icon="filter" size="24"/>
            </button>
            {open && <div className="menu fadeIn">
                    {props.filters.map((filter,index) => {
                        return (
                            <ToggleChip label={filter}  key={"field"+index} click={() => props.click(filter)}/>
                        )
                    })}
                </div>}
        </div>
    )
}
export default CareerFieldsDropdown