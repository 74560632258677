import React, {useRef, useEffect, useState} from "react";
import Icon from "../../global/Icon";
// import { Slider } from "@mui/base";
import { Box, Slider } from "@mui/material";
import StyledSlider from "../../global/Slider";
import SearchChip from "../../global/chips/SearchChip";
import AutocompleteLocations from "../../global/search/AutocompleteLocations";

const FilterMenuLocation = (props) => {
    const [checked, setChecked] = useState(props.data.selected);
    const [searchedRoles, setSearchedRoles] = useState([])

    const input = useRef();

    useEffect(() => {
        let newArr = []
        if (props.data.selected.length > 0) {
            props.data.selected.forEach(item => {
                //check if its in searched array
                if (searchedRoles.indexOf(f => f.term === item) > -1) {
                    //do nothing
                } else {
                    newArr.push({term: item, selected:true})
                }
                
            })
        }
        setSearchedRoles(newArr)
    },[])

    const addToSelected = (arr) => {
        //need to pass selected up so button can show number
        props.modify(arr)
    }

    return (
        <div className="filter-body">
            <div className="form-group">
                <AutocompleteLocations placeholder="Filter locations" addToSelected={addToSelected} selected={props.data.selected} />    
                    
            </div>
        </div>
    )
}
export default FilterMenuLocation