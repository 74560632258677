import React, {useState} from "react";
import Footer from "../global/Footer";
import Header from "../global/Header";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../global/Icon";
import CareerSearchInput from "../careers/CareerSearchInput";
import AddChip from "../global/chips/AddChip";
import CareerSearchDropdown from "../careers/CareerSearchDropdown";
import AutocompleteLocations from "../global/search/AutocompleteLocations";

const PostJob = (props) => {
    const [jobPost, setJobPost] = useState({
        title: '',
        description: '',
        location: [],
        skills: [],
        role: '',
        compensation: ''
    });
    const [status, setStatus] = useState('draft'); //draft, published
    const navigate = useNavigate();

    const addToPost = (key,val) => {

        let jobPostCopy = {...jobPost}

        if (key === 'skills') {
            jobPostCopy['skills'].push(val)
        }
         else {
            jobPostCopy[key] = val;
        }

        setJobPost(jobPostCopy)
    }


    const navigateBack = (status) => {
        navigate(process.env.PUBLIC_URL + "/recruiter/home", {state: {post: jobPost, status: status}})
    }

    return (
        <div className={`page-wrapper horizontal-nav recruiter-home`} >
            <Header logo={true} auth={true}> </Header>
            <main className="">
                <div className="flex-row align-start" style={{gap:"12px", alignItems:"center"}}>
                    <Link className="button icon-btn" to={process.env.PUBLIC_URL + "/recruiter/home"}>
                        <Icon icon="back" size="24"/>
                    </Link>
                    <h1>Post a job {status === 'draft' && <span className="tag chip-label" style={{verticalAlign:"middle"}}>Draft</span>} </h1>
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="jobTitle">Title</label>
                    <input className="gr-input" type="text" id="jobTitle" onInput={(event) => addToPost('title', event.target.value)}/>
                </div>
                <div className="form-group">
                    <CareerSearchDropdown  add={(job) => addToPost('role', job)}/>
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Employment type</legend>
                        <div className="radio-group">
                            <div className="gr-radio">
                                <input type="radio" name="jobType" id="jobType1" value='apprenticeship' onChange={(event) => addToPost('type', event.target.value)} />
                                <label htmlFor="jobType1">
                                    <span>Apprenticeship</span></label>
                                    <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="jobType" id="jobType2" value='internship' onChange={(event) => addToPost('type', event.target.value)} /> 
                                <label htmlFor="jobType2">  
                                   <span>Internship</span>
                                </label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                               <input type="radio" name="jobType" id="jobType3" value='part-time' onChange={(event) => addToPost('type', event.target.value)} />
                                <label htmlFor="jobType3">
                                <span>Part-time</span></label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="jobType" id="jobType4" value='full-time' onChange={(event) => addToPost('type', event.target.value)} />
                                <label htmlFor="jobType4">
                                
                                <span>Full-time</span></label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="jobType" id="jobType5" value='contract' onChange={(event) => addToPost('type', event.target.value)} />
                                <label htmlFor="jobType5">
                                
                                <span>Contract</span></label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Workplace type</legend>
                        <div className="radio-group">
                            <div className="gr-radio">
                                <input type="radio" name="workType" id="workType1" value='onsite' onChange={(event) => addToPost('workplace', event.target.value)} />
                                <label htmlFor="workType1">
                                    
                                    <span>Onsite <span className="meta">Employees come to work in-person</span></span>
                                </label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="workType" id="workType2" value='offsite'  onChange={(event) => addToPost('workplace', event.target.value)} /> 
                                <label htmlFor="workType2">
                                   
                                   <span>Hybrid <span className="meta">Employees work on-site and off-site</span></span>
                                </label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                               <input type="radio" name="workType" id="workType3" value='hybrid' onChange={(event) => addToPost('workplace', event.target.value)} />
                                <label htmlFor="workType3">
                                    
                                    <span>Remote <span className="meta">Employees work off-site</span></span>
                                </label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="form-group">
                    <label htmlFor="searchLocation" className="legend">Location</label>
                    <AutocompleteLocations placeholder="Search locations" selected={[]} addToSelected={(arr) => setJobPost({...jobPost, location: arr})}/>
                    {/* 
                    <div className="autocomplete-container">
                        <div className="search-input">
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                            <input className="gr-input" type="text" 
                            placeholder="Search locations" id="searchLocation"
                            onInput={(event) => addToPost('location', event.target.value)}
                            // onInput={() => checkExists('location', 'Location')}
                            />
                        </div>
                        <div className="autocomplete-results"></div>
                        
                    </div> */}
                </div>
                <div className="form-group">
                    <p className="form-label" id="jobDescription">Description</p>
                    <textarea rows={5} className="gr-textarea" aria-labelledby="jobDescription"
                     onKeyDown={(event) => addToPost('desription', event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Compensation type</legend>
                        <div className="radio-group radio-row">
                            <div className="gr-radio">
                                <input type="radio" name="compType" id="compType1" value='range' onChange={(event) => addToPost('compensation', event.target.value)} />
                                <label htmlFor="compType1">
                                
                                <span>Pay range</span></label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="compType" id="compType2" value='exact'  onChange={(event) => addToPost('compensation', event.target.value)}  />
                                <label htmlFor="compType2">
                                
                                <span>Exact amount</span></label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="compType" id="compType3" value='unpaid'  onChange={(event) => addToPost('compensation', event.target.value)} />
                                <label htmlFor="compType3">
                                
                                <span>Unpaid</span></label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                        </div>
                        {jobPost.compensation?.toLowerCase() === 'range' ? 
                        <div className="secondary-controls">
                            <div className="form-group">
                                <div className="flex-row input-range">
                                    <div className="form-input">
                                        <label className="form-label" htmlFor="minPay">Minimum pay</label>
                                        <div className="pay-input">
                                            <input className="gr-input" type="number" id="minPay"/>
                                            <span>$</span>
                                        </div>
                                    </div>
                                    <span className="hyphen">&ndash;</span>
                                    <div className="form-input">
                                        <label className="form-label" htmlFor="maxPay">Maximum pay</label>
                                        <div className="pay-input">
                                            <input type="number" className="gr-input" id="maxPay"/>
                                            <span>$</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input" style={{marginTop: 16}}>
                                    <label className="form-label" htmlFor="payFreq">Frequency</label>
                                    <div className="gr-select-container">
                                        <select id="payFreq" className="gr-select">
                                            <option value="annually">Annually</option>
                                            <option value="monthly">Monthly</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="daily">Daily</option>
                                            <option value="hourly">Hourly</option>
                                        </select>
                                        <Icon icon="drop-down" size="18"/>
                                    </div>
                                </div>
                                <div className="form-input">
                                    <div className="checkbox">
                                        <label className="" htmlFor="plusComission">
                                            <input type="checkbox" id="plusComission"/>
                                            <span>Plus commission</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        : jobPost.compensation?.toLowerCase() === 'exact' ? <div className="secondary-controls">
                            <div className="form-group">
                                
                                <div className="form-input">
                                    <label className="form-label" htmlFor="totalPay">Pay</label>
                                    <div className="pay-input">
                                        <input type="number" className="gr-input" id="totalPay"/>
                                        <span>$</span>
                                    </div>
                                </div>
                                    
                                
                                <div className="form-input" style={{marginTop: 16}}>
                                    <label className="form-label" htmlFor="payFreq">Frequency</label>
                                    <div className="gr-select-container">
                                        <select id="payFreq" className="gr-select">
                                            <option value="annually">Annually</option>
                                            <option value="monthly">Monthly</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="daily">Daily</option>
                                            <option value="hourly">Hourly</option>
                                        </select>
                                        <Icon icon="drop-down" size="18"/>
                                    </div>
                                </div>
                                <div className="form-input">
                                    <div className="checkbox">
                                        <label className="" htmlFor="plusComission2">
                                            <input type="checkbox" id="plusComission2"/>
                                            <span>Plus commission</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> : <></> }
                    </fieldset>
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Skills required</legend>
                        <div className="search-input" style={{marginBottom:0}}>
                            <input type="text" className="gr-input" 
                            aria-label="Search skills" 
                            placeholder="Search skills"
                            />
                            <span className="search-icon">
                                <Icon icon="search" size="24"/>
                            </span>
                        </div>
                        <div className="selected-list">
                            {jobPost.skills?.length > 0 && 
                                <div className="chip-list">
                                    {jobPost.skills.map((skill,index) => {
                                        return (
                                            <div className="chip-btn search-filter-chip dark with-remove">
                                                {skill}
                                                <button className="button icon-btn" aria-label={"Remove " + skill}><Icon icon="close" size="18"/></button>
                                            </div>
                                        )
                                    })}
                                </div> 
                            }
                        </div>
                        {jobPost.role.length > 0 &&
                    <div className="suggested-list">
                        <p><em>Suggested skills based on occupation</em></p>
                        <div className="chip-list">
                            <AddChip label={"Troubleshooting"} click={() => addToPost('skills', 'Troubleshooting')}/>
                            <AddChip label={"Repairing"} click={() => addToPost('skills', 'Repairing')}/>
                            <AddChip label={"Active listening"} click={() => addToPost('skills', 'Active listening')}/>
                            <AddChip label={"Critical thinking"} click={() => addToPost('skills', 'Critical thinking')}/>
                            <AddChip label={"Speaking"} click={() => addToPost('skills', 'Speaking')}/>
                        </div>
                    </div> }
                    </fieldset>
                    
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Benefits</legend>
                        <div className="checkbox-group">
                            <div className="checkbox">
                                <label htmlFor="benefits1">
                                    <input type="checkbox" name="benefits" id="benefits1"/>
                                    <span>Dental insurance</span></label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="benefits2">
                                    <input type="checkbox" name="benefits" id="benefits2"/>
                                    <span>Life insurance</span></label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="benefits3">
                                    <input type="checkbox" name="benefits" id="benefits3"/>
                                    <span>Medical insurance</span></label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="benefits4">
                                    <input type="checkbox" name="benefits" id="benefits4"/>
                                    <span>Paid time off</span></label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="benefits5">
                                    <input type="checkbox" name="benefits" id="benefits5"/>
                                    <span>Retirement plan</span></label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="benefits6">
                                    <input type="checkbox" name="benefits" id="benefits6"/>
                                    <span>Vision insurance</span></label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="form-group">
                    <fieldset className="gr-fieldset">
                        <legend>Work authorization</legend>
                        <div className="radio-group">
                            <div className="gr-radio">
                                <input type="radio" name="workauth" id="workauth1" />
                                <label htmlFor="workauth1">
                                    
                                    <span>This job <strong>requires</strong> US work authorization</span>
                                </label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                            <div className="gr-radio">
                                <input type="radio" name="workauth" id="workauth2" />
                                <label htmlFor="workauth2">
                                    
                                    <span>This job <strong>does not</strong> require US work authorization</span>
                                </label>
                                <span><svg viewBox="0 0 18 18" width="18" height="18"><circle r="6" cx="9" cy="9"></circle></svg></span>
                            </div>
                        </div>
                    </fieldset>
                </div>
               
                <div className="form-actions">
                    <button className="button light outline medium" onClick={() => navigateBack('draft')}>Save Draft</button>
                    <div className="flex-row">
                        <button className="button light outline medium">Preview job post</button>
                        <button className="button medium primary" onClick={() => navigateBack('active')}>Publish Job</button>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
export default PostJob