import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import HorizontalNavMenu from "../navigation/HorizontalNav";
import Icon from "./Icon";
import { Link } from "react-router-dom";
import Swoop from "./Swoop";

const PageWrapper = (props) => {

    return (
        <div className={`page-wrapper horizontal-nav ${props.activePage}`} >
            <div className={props.swoop ? "swoop large" :"swoop"}>
            

            </div>
            
            <HorizontalNavMenu active={props.activePage} auth={true}/>
            {/* {props.swoop && <Swoop swoop={props.swoop}/> } */}
            {/* <main> */}
                
                {props.children}
            {/* </main> */}
            <Footer puppy={false}/>
            {/* {props.activePage !== 'jobs' &&  } */}
        </div>
    )
}
export default PageWrapper