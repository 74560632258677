import React from "react";
import Icon from "../global/Icon";

const MyExperience = (props) => {

    if (props.user && props.user.experience && props.user.experience.length > 0) {
        return (

            <div className="card experience-card">
                <div className="card-header">
                    <div className="flex-row">
                    <h2 className="desktop-h4 bold card-title">Work experience</h2>
                    <button className="button icon-btn add-btn">
                            <Icon icon="add" size="24"/>
                        </button>
                    </div>
                    
                </div>
                <div className="card-body">
                    {props.user.experience.map((exp, index) => {
                            return (
                                <div className="experience-entry" key={"exp"+index}>
                                    <button className="button icon-btn">
                                            <Icon icon="edit" size="24"/>
                                        </button>
                                    <div className="experience-info">
                                      {exp.employer &&  <p className="employer desktop-h4">{exp.employer}</p> }
                                        <h3 className="desktop-h2">{exp.title}</h3>
                                        <p className="experience-dates">{exp.startDate ? `${exp.startDate} – ${exp.endDate}` : exp.endDate}</p>
                                       {exp.description && <div className="experience-desc" dangerouslySetInnerHTML={{__html: exp.description}}/>}
                                    </div>
                                        
                                </div>
                            )
                        })}
                </div>
                
            </div>
        )
    } else {
        return (

            <div className="card experience-card">
                <div className="card-header no-border">
                    <h2 className="desktop-h4 bold card-title">Work experience</h2>
                </div>
                <div className="card-body">
                    <button className="empty-area-button">
                        <span className="circle"><Icon icon="add" size="24"/></span>
                        <span>Full time, part time, unpaid — it all counts.</span>
                    </button>
                    
                </div>
            </div>
        )
    }
   
}
export default MyExperience