import React, { useEffect, useState } from "react";
import HorizontalNavMenu from "./navigation/HorizontalNav";
import Footer from "./global/Footer";
import AboutMe from "./profile/AboutMe";
import MyEducation from "./profile/MyEducation"
import MyExperience from "./profile/MyExperience"
import MySkills from "./profile/MySkills"
import MyStory from "./profile/MyStory"
import MyProjects from "./profile/MyProjects";
import PageWrapper from "./global/PageWrapper";
import WorkStyleInsights from "./global/cards/WorkStyleInsights";
import badgeImg from "../assets/images/Credly-Engineering-badge.png"
import { useParams, Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import Swoop from "./global/Swoop";
import dataLaura from "../assets/data/userLaura.json";
import dataHelly from "../assets/data/userHelly.json";
import dataBlank from "../assets/data/userHellyBlank.json";
import dataAndy from "../assets/data/userAndyBlank.json";
const Profile = (props) => {
    const badge = {
        img: 'Credly-Engineering-badge.png',
        title: 'Cloud Engineering Immersive Bootcamp',
        issuer: "Quickstart Inc."
    }
    
    const [user, setUser] = useState(null);
    const [newPath, setNewPath] = useState([]) 
    const search = useLocation().search;
    const location = useLocation();
    const navigate = useNavigate();


    // let newPath = 

    useEffect(() => {
        if (location.state !== null) {
            setNewPath(location.state !== null ? location.state.path : [])
            console.log(location.state)
            // console.log(location.state.path)
            console.log(newPath)
        }
        
    },[location])

    useEffect(() => {
        const userName = new URLSearchParams(search).get('user')
        const state = new URLSearchParams(search).get('state')

        console.log(userName, )
        
        setUser(
            location && location.state && location.state.user === "Laura" ? dataLaura[0] :
            userName === "Laura" ? dataLaura[0] : 
            userName === "Andy" ? dataAndy[0] :
            state && state === "future" ? dataLaura[0] : 
            userName === "Helly" ? dataHelly[0] :
            dataBlank[0]
        )
    },[])

    return (
        <>
            {/* <Swoop swoop={{title:"Profile",subtitle:"Use your profile to document your wins and highlight your skills."}}/> */}
            
                <main>
                <section className="intro">
                        <h1 className="page-title secondary desktop-h3 bold">Profile</h1>
                    </section>
            {user ? <section className="profile-content">
                <div className="left-col col-1">
                 {user && user.name && <AboutMe user={user}/> }
                
                {user && (user.name === "Laura Perry" || user.name === "Andy Perry") ? 
                <div className="card story-card">
                    <div className="card-header">
                        <h2 className="desktop-h4 bold card-title">Badges & Certifications</h2>
                    </div>
                    <div className="card-body">
                        <div className="badge-list">
                            {user.badges.map((badge, index) => {
                                return (
                                    <div className="badge-container" key={"badgeEarned"+index}>
                                        <div className="badge-information">
                                            <div className={`badge ${badge.issuer.split(" ")[0].toLowerCase()}`}>
                                                <img src={badge.img} alt="badge"  style={{maxWidth:"100%",width:"100%"}}/>
                                            </div>
                                            <div className="badge-details">
                                                <h2 className="badge-title desktop-h5 bold">{badge.title}</h2>
                                                <p className="badge-issuer desktop-h5">{badge.issuer}</p>
                                                
                                            </div>
                                        </div>
                                        <div className="badge-actions">
                                            <button className="button small primary">Share</button>
                                        </div>
                                    </div>
                                )
                            })}  
                           
                        </div>
                    </div>
                    
                </div> : <></>}
                {user && newPath.length > 0 ?  <div className="card story-card">
                        <div className="card-header">
                            <h2 className="desktop-h4 bold card-title">{newPath[0].issuer.toLowerCase() === 'comptia' ? "CompTIA" : "AWS"} certification path</h2>
                        </div>
                        <div className="card-body">
                            {newPath.length > 0 ? 
                            <div className="pathway-list">
                            {newPath.map((step, index) => {
                                return (
                                    <div className="card pathway-card" key={"pathStep"+index}>
                                        {step.completed && <span className="card-label completed">
                                        <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.2041 3.82022L3.2145 5.86032C3.27981 5.93278 3.40533 5.93262 3.47038 5.85998L7.17299 1.49316" stroke="white" stroke-width="1.49222" stroke-linecap="round"/>
                                            </svg> 
                                            Completed
                                        </span>}
                                        <div className="image">
                                            <img src={step.thumbnail} alt=""/>
                                        </div>
                                        <div className="card-text">
                                            <h3 className="card-title desktop-h5 bold">{step.title}</h3>
                                            <p className="issuer">{step.issuer}</p>
                                        </div>

                                    </div>
                                    
                                )
                            })}
                           </div>
                            :
                            <div className="pathway-list">
                                {user.pathway.map((step, index) => {
                                    return (
                                        <div className="card pathway-card" key={"pathStep"+index}>
                                            {step.completed && <span className="card-label completed">
                                            <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.2041 3.82022L3.2145 5.86032C3.27981 5.93278 3.40533 5.93262 3.47038 5.85998L7.17299 1.49316" stroke="white" stroke-width="1.49222" stroke-linecap="round"/>
                                                </svg> 
                                                Completed
                                            </span>}
                                            <div className="image">
                                                <img src={step.thumbnail} alt=""/>
                                            </div>
                                            <div className="card-text">
                                                <h3 className="card-title  desktop-h5 bold">{step.title}</h3>
                                                <p className="issuer">{step.issuer}</p>
                                            </div>

                                        </div>
                                        
                                    )
                                })}
                            </div> }
                        </div>
                        <div className="card-footer">
                            <button onClick={() => navigate(`${process.env.PUBLIC_URL}/pathway?user=Laura`, {state:{path:newPath}})} className="button small primary">View certificate path</button>
                        </div>
                    </div> : <></>}
                   
                    {/* {user && user.name === "Laura Perry" ?
                     <WorkStyleInsights/> 
                     : <div className="card work-style">
                        <div className="card-header">
                            <h2 className="desktop-h4 bold card-title">Discover your Work Style</h2>
                        </div>
                        <div className="card-body">
                            <p>Take this short two-part assessment to learn how your personality relates to your fit within the future workplace.</p>
                            <button className="button primary small">Begin</button>
                        </div>
                    </div>} */}
                    <div className="card work-style">
                        <div className="card-header">
                            <h2 className="desktop-h4 bold card-title">Discover your Work Style</h2>
                        </div>
                        <div className="card-body">
                            <p>Take this short two-part assessment to learn how your personality relates to your fit within the future workplace.</p>
                            <button className="button primary small">Begin</button>
                        </div>
                    </div>
                     <MySkills user={user}/>
                   
                </div>
                <div className="main-col col-2">
                    {/* <MyStory user={user}/> */}
                    <MyEducation user={user}/>
                    <MyExperience user={user}/>
                    {/* <MyProjects user={user}/> */}
                </div>

            </section> : <></>}
            {/* <div className="profile illustration">
                <div className="puppy"></div>
                {/* <div className="css-sq2z9g eusso3d1"></div>
            </div> */}
            </main>
        </>
    )
}
export default Profile