import { Slide } from "@mui/material";
import React, {useState, useEffect} from "react";
import Icon from "../global/Icon";
import InsightsGroup from "./InsightsGroup";
import InsightsGroupMobile from "./InsightsGroupMobile";

const InsightsDrawer = (props) => {
    const [visible, setVisible] = useState(true)

    return (
        
        <div className="insights-drawer">
            {props.data && <>
            <div className="page-nav drawer-header">
                <button className="button icon-btn" onClick={props.close}>
                    <Icon icon="back" size="24"/>
                </button>
                <h1 className={'page-title tertiary mobile-h3 bold'}>{props.data.title}</h1>
            </div>
            <div className="drawer-body">
                {props.partVisible === 1 ? <>
                    <div className='insights-group-container part-one'>
                            {props.data.categories.filter(f => f.part === 1).map((category, index) => {
                                return (
                                    <InsightsGroupMobile
                                        type={props.data.type}
                                        data={category}
                                        first={index===0}
                                        info={() => props.info(category)}
                                    />
                                );
                            })}
                            </div>
                            <div className='insights-group-container part-two'>
                                <div className='part-two-message'>
                                    <h2 className='desktop-h4'>Complete part 2 of the assessment to view your full results</h2>
                                    <button className='button medium primary'>Start part 2</button>
                                </div>
                                {props.data.categories.filter(f => f.part === 2).map((category, index) => {
                                    return (
                                        <InsightsGroupMobile
                                            type={props.data.type}
                                            data={category}
                                            hideData={true}
                                            info={() => props.info(category)}
                                        />
                                    );
                                })}
                            </div>
                        </>
                        
                    : <>
                        {props.data.categories.map((category, index) => {
                            return (
                                <InsightsGroupMobile
                                    type={props.data.type}
                                    data={category}
                                    first={index===0}
                                    info={() => props.info(category)}
                                />
                            );
                        })}
                    </>}
            </div>
            </>
            }
        </div>
        
    )
}
export default InsightsDrawer