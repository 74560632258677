export const searchTerms = [
    "Engine Tuning",
    "Engineering Drawing",
    "Engineering Procurement Construction And Installation (EPCI)",
    "Engineering",
    "Engineering Support",
    "Engineering Calculations",
    "Engineering Acceptance Testing",
    "Engineering Consulting",
    "Engineering Design Processes",
    "Engineering Consulting Managers",
    "Engineering Teachers, Postsecondary",
    "Engineering Consultants",
    "Engine and Other Machine Assemblers",
    "Power Engineering",
    "Electronic Engineering",
    "Project Engineering",
    "Agricultural Engineering",
    "Diesel Engines",
    "Small Engine Repair",
    "Internal Combustion Engines",
    "Small Engines",
    "Food Engineering",
    "Menu Engineering",
    "Unreal Engine (UE)",
    "Game Engine",
    "Software Engineering",
    "Audio Engineering",
    "Civil Engineering",
    "Biomedical Engineering (BME)",
    "Process Engineering",
    "Manufacturing Engineering",
    "Product Engineering",
    "Support Engineering",
    "Test Engineering",
    "Site Reliability Engineering",
    "Packaging Engineering",
    "Search Engine Marketing (SEM)",
    "Search Engine Optimization (SEO)",
    "Financial Engineering",
    "Internet Booking Engines",
    "Data Engineering",
    "Release Engineering (RE)",
    "Feature Engineering",
    "Computer Engineering",
    "Aerospace Engineering",
    "Chemical Engineering",
    "Bioprocess Engineering",
    "Electromechanical Engineering",
    "Radio Frequency Engineering (RF Engineering)",
    "Control Engineering",
    "Clinical Engineering",
    "Biochemical Engineering",
    "Biomechanical Engineering",
    "Highway Engineering",
    "Traffic Engineering",
    "Structural Engineering",
    "Environmental Engineering",
    "Architectural Engineering",
    "Telecommunications Engineering",
    "Wireless Engineering",
    "Electrical Engineering",
    "Materials Engineering",
    "Value Engineering (VE)",
    "Industrial Engineering",
    "Mechanical Engineering",
    "Computer Aided Engineering (CAE)",
    "Wind Engineering",
    "Genetic Engineering",
    "Mining Engineering",
    "Production Engineering",
    "Reverse Engineering",
    "Railway Engineering",
    "Model-Based Systems Engineering (MBSE)",
    "Hydropower Engineers",
    "Integration Engineers",
    "Component Engineers",
    "Airline Pilots, Copilots, and Flight Engineers",
    "Contracts Engineers",
    "Mechanical Engineers",
    "Nanotechnology Engineering Technicians",
    "Architectural and Engineering Managers",
    "Sound Engineering Specialists",
    "Nanotechnology Engineering Technologists",
    "Industrial Engineers",
    "Human Factors Engineers and Ergonomists",
    "Manufacturing Engineering Technologists",
    "Manufacturing Engineers",
    "Industrial Engineering Technologists",
    "Validation Engineers",
    "Reliability Engineers",
    "Microsystems Engineers",
    "Nanosystems Engineers",
    "Automation Engineers",
    "Compliance Engineers",
    "Thermal Engineers",
    "Solar Energy Systems Engineers",
    "Logistics Engineers",
    "Software Quality Assurance Engineers",
    "Cloud Implementation Engineers",
    "Test Automation Engineers",
    "AI Systems Engineers",
    "Computer Systems Engineers/Architects",
    "Machine Learning Engineers",
    "Telecommunications Engineering Specialists",
    "Aerodynamics Engineers",
    "Aerospace Engineers",
    "Biochemical Engineers",
    "Pharmaceutical Engineers",
    "Medical Device Engineers",
    "Biomedical Engineers",
    "Mechatronics Engineers",
    "Robotics Engineers",
    "Photonics Engineers",
    "Wind Energy Engineers",
    "Energy Engineers",
    "Automotive Engineering Technicians",
    "Bus and Truck Mechanics and Diesel Engine Specialists",
    "Outdoor Power Equipment and Other Small Engine Mechanics",
    "Chemical Engineers",
    "Transportation Engineers",
    "Civil Engineers",
    "Water/Wastewater Engineers",
    "Environmental Engineers",
    "Computer Hardware Engineers",
    "IoT Engineers",
    "Power Distribution Engineers",
    "Substation Engineers",
    "Electrical Engineers",
    "Instrumentation and Control Engineers",
    "Electronics Engineers",
    "Automotive Engineers",
    "Fuel Cell Engineers",
    "Materials Engineers",
    "Commissioning Engineers",
    "Process Engineers",
    "Marine Engineers",
    "Nuclear Engineers",
    "Data Engineers",
    "Mining and Geological Engineers",
    "Mineral Process Engineers",
    "Civil Engineering Technicians",
    "ICT Engineering Technicians",
    "Electrical Engineering Technicians",
    "Electronics Engineering Technologists",
    "IoT Engineering Technicians",
    "Electrical Engineering Technologists",
    "Electronics Engineering Technicians",
    "Automation Engineering Technicians",
    "Aerospace Engineering and Operations Specialists",
    "Electromechanical Engineering Technologists",
    "Electromechanical Engineering Technicians",
    "Environmental Engineering Technicians",
    "Industrial Engineering Technicians",
    "Process Engineering Technicians",
    "Mechanical Engineering Technologists",
    "Mechanical Engineering Technicians",
    "Operating Engineers and Other Construction Equipment Operators",
    "Locomotive Engineers",
    "Rail Yard Engineers, Dinkey Operators, and Hostlers",
    "Ship Engineers",
    "Agricultural Engineers",
    "Explosives Engineers",
    "Fire-Prevention and Protection Engineers",
    "Industrial Health and Safety Engineers",
    "Metallurgical Engineers",
    "Petroleum Engineers",
    "Product Safety Engineers",
    "Sales Engineers",
    "Stationary Engineers and Boiler Operators",
    "Welding Engineers",
    "Civil Engineering Technologies & Technicians",
    "Agriculture & Biological Engineering",
    "Automation Engineering",
    "Automotive Engineering",
    "Biomedical Engineering",
    "Civil/Environmental Engineering",
    "Construction Engineering",
    "Energy Engineering",
    "General Engineering",
    "Materials Science & Engineering",
    "Nautical/Naval Engineering",
    "Network Engineering",
    "Nuclear Engineering",
    "Architectural Engineering Technologies & Technicians",
    "Computer Engineering Technologies & Technicians",
    "Drafting/Design Engineering Technologies & Technicians",
    "Electrical Engineering Technologies & Technicians",
    "Mechanical Engineering Technologies & Technicians",
    "Nuclear Engineering Technologies & Technicians"
]