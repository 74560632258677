import React, {useEffect, useState, useRef} from "react";
import { createPortal } from "react-dom";
import Icon from "./global/Icon";
import { Link, useParams } from "react-router-dom";
import careers from "../assets/data/careers.json";
import JobCardMini from "./global/cards/JobCardMini";
import Swoop from "./global/Swoop";
import CareerBanner from "./careers/CareerBanner";
import { getCareerData } from "../assets/helpers/getCareerFromOnet";
import employmentData from "../assets/data/careersEmploymentData.json";
import simulations from "../assets/data/simulations.json";
import ForageCard from "./global/cards/ForageCard";
import verifiedIcon from "../assets/icons/verified.svg"
import Toast from "./global/Toast";
import saved from "../assets/data/savedOccupations.json";
import { useScrollTrigger, useMediaQuery } from "@mui/material";

const CareerDetail = (props) => {
    const [pageData, setPageData] = useState({})
    const [pageWages, setPageWages] = useState({})
    const [activeTab, setActiveTab] = useState(1);
    const [taskList, setTaskList] = useState(true);
    const params = useParams();
    const { careerId } = params;
    const [toast, setToast] = useState({show: false, message: ''});

    const career = careers.find((record) => record.id === careerId);
    const [isSaved, setIsSaved] = useState(false);

    // useEffect(() => {
    //    const data = getCareerData(careerId)

    //     // setPageData(data)
    //     console.log(data)
    // },[careerId])
    const isDesktop = useMediaQuery('(min-width:568px)');
    const trigger = useScrollTrigger({disableHysteresis: true, threshold: isDesktop ? 312 : 200});

    useEffect(() => {
        if (saved.includes(careerId)) {setIsSaved(true)}

        let salaryData = employmentData.filter(f => f.code === careerId)[0]
        setPageWages(salaryData)
        fetch("https://services.onetcenter.org/ws/online/occupations/" + careerId + "/details?client=pearson1&display=long",
            {
                method: 'GET',
                mode: 'cors',
                credentials: 'omit',
                headers: {
                'Accept': 'application/json'
                }
            }
        )
        .then(response => response.json())
        .then(data => setPageData(data));
        
}, [careerId]);
   

    useEffect(() => {
        window.scrollTo(0,0)
        setTaskList(true)
    },[careerId])

    const viewTasks = (type) => {
        if (type === 'more') {
            setTaskList(true)
        } else {
            //less
            setTaskList(false)
        }
    }

    const addToBookmarks = (type) => {

        if (type === "add") {
            setIsSaved(true)
            setToast({open:true, message: "Added to your saved occupations"})
           
        } else {
            setIsSaved(false)
            setToast({open:true, message: "Removed from your saved occupations"})
        }
    
	};

    return (
        <>
            {/* <Swoop swoop={{title:career.title, subtitle:career.description,navigation:"/careers"}}/> */}
            <main className="careers-main">
                <div className="intro">
                   <CareerBanner title={pageData.occupation ? "Occupation: " + pageData.occupation.title : "Occupation: Occupation title"} description={pageData.occupation ? pageData.occupation.description : "Occupation description"} code={careerId} video={{src:"https://cdn.careeronestop.org/OccVids/OccupationVideos/" + careerId + ".mp4"}} />
                   <button className={`button icon-btn medium floating-action-btn bookmark-btn ${!isDesktop && trigger ? 'primary fixed' : !isDesktop ? 'primary absolute' : trigger ? 'primary':''}`} aria-label={isSaved ? "Remove from saved occupations" : "Add to saved Occupations"} onClick={() => addToBookmarks(isSaved ? "remove" : "add")}>
                    <Icon icon={isSaved ? "bookmark-on" : "bookmark-off"} size="24"/>
                </button>
                    
                </div>
                
                <section className="career-body">
                <div className="career-information">
                        
                        <div className="career-features">
                            {/* <div className="feature-group">
                                <span className="feature-number">{pageWages.projected_openings_yr ? (pageWages.projected_openings_yr * 1000).toLocaleString() : "--"}</span>
                                <span className="feature-description">Projected job openings (2022 &ndash; 2023)</span>
                            </div> */}
                            <div className="feature-group">
                                <span className="feature-number">${pageWages.annual_median ? pageWages.annual_median.toLocaleString() : "--"}</span>
                                <span className="feature-description">Estimated median salary</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="detail-group">
                        <h2 className="desktop-h4 bold">What things do people with this occupation do?</h2>
                        {pageData?.tasks?.task.length > 0 ? <>
                        <div className={`detail-list-container ${taskList ? 'condensed' : 'expanded'}`}>
                            <div className={"detail-list"}>
                                <ul>
                                    {pageData.tasks.task.map((task, index) => {
                                    return (
                                        <li key={"task"+task.id}>
                                            <p className="body-text">
                                                {task.statement}
                                            </p>
                                        </li>
                                    )
                                    
                                    })}
                                </ul>
                            
                            </div>
                        </div>
                        
                            <div className="action-row">
                                <button className="link-btn" aria-hidden="true" onClick={() => setTaskList(!taskList)}>
                                    {taskList ? "View more" : "View less"} 
                                </button>
                            </div>
                            
                        </> : <p className="body-text">More information coming soon</p> }
                    </div> 
                    {/* <div className="detail-group">
                        <h2 className="desktop-h4 bold">Is this occupation right for me?</h2>
                    </div> */}
                    
                    <div className="detail-group">
                        <h2 className="desktop-h4 bold">What skills do I need for this occupation?</h2>
                        {pageData?.skills?.element.length > 0 ?
                        <div className="skills-list chip-list">
                            {pageData.skills.element.map((skill, index) => {
                                return (
                                    <div className="chip-btn" key={"skill"+skill.id}>
                                        {index < 3 && <span className="verified"><img src={verifiedIcon} alt="Verified skill" /></span>}
                                        {skill.name}
                                        </div>
                                )
                            })}
                        </div> : <p className="body-text">More information coming soon</p> }
                    </div> 
                    
                    {pageData?.related_occupations?.occupation.length > 0 &&
                    <div className="detail-group">
                        <h2 className="desktop-h4 bold">Related occupations</h2>
                        <div className="jobs-list">
                            {pageData.related_occupations.occupation.map((job) => {
                                return (
                                    <p className="body-text" key={"job"+job.code}><Link to={process.env.PUBLIC_URL + "/career/"+job.code}>{job.title}</Link></p>
                                )
                            })}
                        </div>
                    </div> }
                    <div className="detail-group">
                        <h2 className="desktop-h4 bold">Latest job postings for {pageData.occupation ? pageData.occupation.title : "Occupation title"}</h2>
                        <div className="card-row three-three-three">
                            <JobCardMini data={{title: 'Job posting title', company: 'Sample company',location: 'Anywhere, USA', type: 'Full time'}} />
                            <JobCardMini data={{title: 'Job posting title', company: 'Sample company',location: 'Anywhere, USA', type: 'Full time'}} />
                            <JobCardMini data={{title: 'Job posting title', company: 'Sample company',location: 'Anywhere, USA', type: 'Full time'}} />
                        </div>
                    </div>
                    <div className="detail-group">
                        <h2 className="desktop-h4 bold">Related virtual work experiences by Forage</h2>
                        <div className="card-row skills-group three-three-three">
                            {simulations.slice(0,3).map((sim, index) => {
                                        return (
                                            
                                                <ForageCard key={"foramge"+index} data={sim} modifier="forage-card" click={() => console.log("forage click")}/> 
                                            
                                        )
                                    })}
                        </div>
                    </div>
                </section>
                {toast.open &&
				createPortal(
					<Toast
						message={toast.message}
					/>,
					document.body
				)}
            </main>
        </>
    )
}

export default CareerDetail