import React, {useState} from "react";
import Icon from "../Icon";
import badgeImg from "../../../assets/images/Credly-Engineering-badge.png"
import { Link } from "react-router-dom";


const ShareBadge = (props) => {
    const [shared, setShared] = useState(false)
    const badge = props.data ? props.data : {
        img: 'Credly-Engineering-badge.png',
        title: 'Cloud Engineering Immersive Bootcamp',
        issuer: "Quickstart Inc."
    };

    const passShared = () => {

        if (shared) {
            props.share()
        }
        props.close()
    }
    
    return (
        <div className='modal-overlay'>
        <div className="modal light-bg share-badge">
            <button className="button icon-btn close-btn" aria-label="Close" onClick={() => passShared()}>
                <Icon icon="close" size="24"/>
            </button>
            <div className="modal-header">
                <h1 className="modal-title desktop-h3">
                    Share your badge
                </h1>
            </div>
            <div className="modal-body">
                <div className="badge-information">
                    <div className="badge avatar">
                        <img src={badge.thumbnail ? badge.thumbnail : badgeImg} alt="badge"/>
                    </div>
                    <div className="badge-details">
                        <h2 className="badge-title desktop-h4 bold">{badge.title}</h2>
                        <p className="badge-issuer subtitle-1">Issued by: <a>{badge.issuer}</a></p>
                    </div>
                </div>
                <div className="share-group">
                    <h3 className="desktop-h4 bold">Update credentials</h3>
                    <p>Promote your achievements by adding your badge to your profile.</p>
                    <div className="flex-row share-options">
                        <a className="share-option" onClick={() => setShared(true)} href={"https://www.linkedin.com"} target={"_blank"}>
                            <span className="share-avatar linkedin"></span>
                            <span className="share-type">LinkedIn</span>
                        </a>
                        <button className="share-option" onClick={() =>  setShared(true)}>
                            <span className="share-avatar ziprecruiter"></span>
                            <span className="share-type">ZipRecruiter</span>
                        </button>
                    </div>
                </div>
                <div className="share-group">
                    <h3 className="desktop-h4 bold">Publish</h3>
                    <p>Share on social media or send to friends and colleagues.</p>
                    <div className="flex-row share-options">
                        <button className="share-option" onClick={() =>  setShared(true)}>
                            <span className="share-avatar twitter"></span>
                            <span className="share-type">X</span>
                        </button>
                        <button className="share-option" onClick={() =>  setShared(true)}>
                            <span className="share-avatar facebook"></span>
                            <span className="share-type">Facebook</span>
                        </button>
                        <button className="share-option" onClick={() => setShared(true)}>
                            <span className="share-avatar">
                                <Icon icon="email" size="24"/>
                            </span>
                            <span className="share-type">Email</span>
                        </button>
                        <button className="share-option" onClick={() => setShared(true)}>
                            <span className="share-avatar">
                                <Icon icon="hyperlink" size="24"/>
                            </span>
                            <span className="share-type">Public link</span>
                        </button>
                        <button className="share-option" onClick={() => setShared(true)}>
                            <span className="share-avatar">
                                <Icon icon="html" size="24"/>
                            </span>
                            <span className="share-type">Embed code</span>
                        </button>
                    </div>
                </div>
                <div className="share-group">
                    <h3 className="desktop-h4 bold">Download</h3>
                    <p>Take things offline.</p>
                    <div className="flex-row share-options">
                        <button className="share-option" onClick={() => setShared(true)}>
                            <span className="share-avatar">
                                <Icon icon="download" size="24"/>
                            </span>
                            <span className="share-type">Download</span>
                        </button>
                        
                    </div>
                </div>
            </div>
           
        </div>
        </div>
    )
}
export default ShareBadge