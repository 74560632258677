import React, {useState} from "react";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ForageCard from "../global/cards/ForageCard";
const ForageFilterCompany = (props) => {
    const [simulations, setSimulations] = useState(props.data);

    const filterSims = (company) => {
        let fullList = [...props.data];
        let filteredList

        if (company.length > 0) {
            //filter by company
            filteredList = fullList.filter(c => c.company.toLowerCase() === company.toLowerCase())
        } else {
            //no filter
            filteredList = fullList
        }
        setSimulations(filteredList)

    }
    return (
        <div className="secondary-card-list">
            <div className="card-header">
                <CompanyAutocomplete filter={filterSims}/>
                
            </div>
            <h2 className="mobile-h6">Viewing {simulations.length} results</h2>
            <div className="card-row skills-group show-all">
                {simulations.length > 0 ? <>
                {simulations.map((sim,index) => {
                    return (
                        <ForageCard data={sim} key={"companies"+index} modifier="forage-card" click={() => props.setModal({open:true,data:sim})}/>
                    )
                })}
                </> : <p>No virtual work experiences available</p>} 
                
            </div>
        </div>
    )

}
export default ForageFilterCompany