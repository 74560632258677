import React from 'react';
import VirtualScrollChild from './VirtualScrollChild';
import InfiniteScroll from './InfiniteScroll';

/**
 * A wrapper component for implementing virtual and
 * infinite scrolling.
 */
function VirtualAndInfiniteScroll({listItems, height}) {
    const VirtualScrollChildren = listItems.map(listItem => 
        <VirtualScrollChild height={height} children={listItem}/>
    );

    return (
        <InfiniteScroll 
            listItems={VirtualScrollChildren} 
            />
    );
}

export default VirtualAndInfiniteScroll; 