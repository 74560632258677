import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Skills from './components/Skills';

import Sandbox from './components/Sandbox';
import Jobs from './components/Jobs';
import Careers from './components/Careers';
import Profile from './components/Profile';
import CareerDetail from './components/CareerDetail';
import WorkStyleQuiz from "./components/workstyle/WorkStyleQuiz";
import Insights from "./components/insights/Insights"
import SkillSecondary from './components/SkillSecondary';
import SandboxCareers from './components/SandboxCareers';
import BadgeDetails from './components/badge/BadgeDetails';
import CreateAccount from './components/CreateAccount';
import Email from './components/badge/Email';
import PageWrapper from './components/global/PageWrapper';
import Pathway from './components/Pathway';
import { useRef } from 'react';
import CareerPathwayDetail from './components/CareerPathwayDetail';
import ClusterDetail from './components/ClusterDetail';
import UnauthHome from './components/turbo/UnauthHome';
import CareerSavedDetail from "./components/CareerSavedDetail";
import CareerRecommended from './components/CareerRecommended';
import RecruiterHome from './components/recruiter/RecruiterHome';
import PostJob from './components/recruiter/PostJob';

function App(props) {

  let hidePage = useRef(false);
  const hidePageBG = (boo) => {
    hidePage.current = boo
    console.log(hidePage.current)
  }

  return (
    <div className="App">
      <BrowserRouter
		
		>
      <Routes>
      <Route
            path={process.env.PUBLIC_URL + '/unauth'}
            element={<UnauthHome/>}
          />
        <Route
            path={process.env.PUBLIC_URL + '/'}
            element={<PageWrapper activePage="dashboard" swoop={true}><Dashboard/></PageWrapper>}
          />
          <Route path={process.env.PUBLIC_URL + '/createaccount'}
          element={<CreateAccount/>}
          />
          <Route path={process.env.PUBLIC_URL + '/badgeEmail'}
          element={<Email/>}/>
          <Route
            path={process.env.PUBLIC_URL + '/dashboard'}
            element={<PageWrapper activePage="dashboard" swoop={true}><Dashboard/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/dashboard?certificateEarned=:certificate'}
            element={<PageWrapper activePage="dashboard" swoop={true}><Dashboard/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL +`/dashboard?major=:majorId`}
            element={<PageWrapper activePage="dashboard" swoop={true}><Dashboard/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL +`/dashboard?workStyleCase=:caseNum`}
            element={<PageWrapper activePage="dashboard" swoop={true}><Dashboard/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/skills'}
            element={<PageWrapper activePage="skills" swoop={true}><Skills/></PageWrapper>}
          />
          <Route path={process.env.PUBLIC_URL + '/savedLearning'}
            element={<PageWrapper activePage="skills" detail={true} swoop={true}><SkillSecondary swoop={{title:"Saved learning",subtitle:""}} type='saved'/></PageWrapper>}/>
          <Route path={process.env.PUBLIC_URL + '/simulations'}
            element={<PageWrapper activePage="skills" detail={true} swoop={true}><SkillSecondary swoop={{title:"Explore virtual job experiences",subtitle:'Preview the day-to-day tasks you can expect at real roles in top companies, all free from Forage.'}} type='forage'/></PageWrapper>}/>
          <Route path={process.env.PUBLIC_URL + '/learning'}
            element={<PageWrapper activePage="skills" detail={true} swoop={true}><SkillSecondary type='videos' swoop={{title:'Explore all video learning',subtitle: 'Learn new skills with short videos from experts with real world experience.'}} tagline={"Explore all video learning"}/></PageWrapper>}/>
          <Route
            path={process.env.PUBLIC_URL + '/jobs'}
            element={<PageWrapper activePage="jobs" footer={true}><Jobs pageType='recommended' /></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/jobs?recommended=0'}
            element={<PageWrapper activePage="jobs" footer={false}><Jobs zeroRecs={true} pageType='recommended' /></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/jobs/saved'}
            element={<PageWrapper activePage="jobs" footer={false}><Jobs pageType='saved'/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/jobs?search'}
            element={<PageWrapper activePage="jobs" footer={false}><Jobs pageType='search' /></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/careers'}
            element={<PageWrapper activePage="careers"><Careers/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/careers/saved'}
            element={<PageWrapper activePage="careers"><CareerSavedDetail/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/careers/recommended'}
            element={<PageWrapper activePage="careers"><CareerRecommended/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/careers/:clusterId'}
            element={<PageWrapper activePage="careers" detail={true}><ClusterDetail/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/careers/pathway/:pathwayId'}
            element={<PageWrapper activePage="careers" detail={true}><CareerPathwayDetail/></PageWrapper>}
          />
          <Route path={process.env.PUBLIC_URL + '/career/:careerId'} 
            element={<PageWrapper activePage="careers" detail={true}><CareerDetail /></PageWrapper>}/>

        <Route
            path={process.env.PUBLIC_URL + '/sandbox'}
            element={<PageWrapper activePage="dashboard" swoop={true}><Sandbox/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/pathway'}
            element={<PageWrapper activePage="skills" swoop={true}><Pathway/></PageWrapper>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/careersCards'}
            element={<SandboxCareers/>}
          />
          <Route
            path={process.env.PUBLIC_URL + '/profile'}
            element={<PageWrapper activePage="profile" swoop={true}><Profile/></PageWrapper>}
          />
           <Route path={process.env.PUBLIC_URL + '/workstylequiz'} element={<WorkStyleQuiz/>}/>
          <Route path={process.env.PUBLIC_URL + '/insights'} element={<PageWrapper activePage={'profile'}><Insights /></PageWrapper>}/>
          <Route path={process.env.PUBLIC_URL + '/badgeDetails'} element={<BadgeDetails auth={false}/>}/>
          <Route path={process.env.PUBLIC_URL + '/badgeDetails?loggedin=true'} element={<BadgeDetails auth={false}/>}/>
          <Route path={process.env.PUBLIC_URL + '/recruiter/home'} element={<RecruiterHome/>}/>
          <Route path={process.env.PUBLIC_URL + '/recruiter/add-job'} element={<PostJob/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
