import React from "react";

const Footer = (props) => {

    return (
        <div className="footer-container">
            
            <div className={`footer-curve`}>
                <svg viewBox="0 0 1280 160" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <path d="M9.07599e-06 160L1280 160V0C1030.48 33.0616 610.08 63.0416 0 56.1825L9.07599e-06 160Z" fill="#020917"/>
                </svg>
            </div>
            <footer className="page-footer">
                {/* <div className="css-0 e11gdo671">
                    <span className="css-15pzk e11gdo672"></span>
                    <a rel="noopener norel" target="_blank" href="https://www.pearson.com/en-us/pearsonplus/app-coming-soon.html" className="css-bxmrmw e11gdo673">Download the Mobile app</a>
                </div> */}
                <ul className="css-1c9jac0 e11gdo674">
                    <li className="css-o9b79t e11gdo675"><button className="css-1904l99 e11gdo677">Cookies</button></li>
                    <li className="css-o9b79t e11gdo675"><a href="https://www.pearson.com/en-us/pearsonplus/legal/terms-of-use.html" rel="noopener norel" target="_blank" className="css-tadcwa e11gdo676">Terms of use</a></li>
                    <li className="css-o9b79t e11gdo675"><a href="https://www.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html" rel="noopener norel" target="_blank" className="css-tadcwa e11gdo676">Privacy</a></li>
                    <li className="css-o9b79t e11gdo675"><a href="https://www.pearson.com/en-us/pearsonplus/legal/cookie-policy.html" rel="noopener norel" target="_blank" className="css-tadcwa e11gdo676">Do not sell my personal information</a></li>
                    <li className="css-o9b79t e11gdo675"><a href="https://www.pearson.com/en-us/pearsonplus/legal/accessibility.html" rel="noopener norel" target="_blank" className="css-tadcwa e11gdo676">Accessibility</a></li>
                    <li className="css-o9b79t e11gdo675"><a href="https://www.pearson.com/en-us/pearsonplus/legal/patent-notice.html" rel="noopener norel" target="_blank" className="css-tadcwa e11gdo676">Patent notice</a></li>
                </ul>
                <div className="css-0 e11gdo678 copyright">© 1996–2024 Pearson All rights reserved.</div>
            </footer>
            
        </div>
    )
}
export default Footer