import React, {useEffect, useState} from "react";
import Icon from "../global/Icon";
import { createPortal } from "react-dom";
import Toast from "../global/Toast";
const JobResultCard = (props) => {
    const [saved, setSaved] = useState(props.saved ? props.saved : false);
    const [active, setActive] = useState(props.active);
    const [toast, setToast] = useState({open:false, message:''})

    useEffect(() => {
        setActive(props.active)
    },[props.active])
    useEffect(() => {
        setSaved(props.saved)
    },[props.saved])
    const bookmark = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (saved) {
            setSaved(false)
            setToast({open:true, message: "Removed from Saved jobs"})
        }
        else {
          
                setSaved(true)
                setToast({open:true, message: "Added to Saved jobs"})
            
        }
        props.bookmark()
        // setSaved(!saved)
    }

    const getInitials = (company) => {
        let acronym = company.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')

        return acronym.substring(0,2);

    }

    useEffect(() => {
        if (toast.open) {
            setTimeout(event => {
                setToast({open:false, message:''})
            },4000)
        }
      
        return(() => {
            clearTimeout()
        })

    },[toast])
    return (
        <>
        <a className={`card mini-card job-result-card ${saved ? 'saved':''} ${active ? 'active':''}`} href="#" onClick={props.click}>
            <button className="button icon-btn bookmark-btn" 
                    aria-pressed={saved} aria-label="Bookmark"
                    onClick={(e) => bookmark(e)}
                    >
                <Icon icon={saved ? "bookmark-on" : "bookmark-off"} size="16"/></button>
                {props.recommended && <span className="chip-label recommended-chip">Recommended</span>}
            <h4 className="card-title job-title">{props.data.job_title}</h4>
            <div className="job-details">
                <div className="avatar">
                    {props.data.avatar ? 
                    <img src={props.data.avatar} alt="logo"/>
                    : <span className="initial">{getInitials(props.data.company_name)}</span>}
                </div>
                <div className="meta">
                    <p className="job-company">{props.data.company_name}</p>
                    <p className="meta">
                        <span>{props.data.job_type}</span>
                        <span>{props.data.inferred_city}, {props.data.state} </span>
                    </p>
                </div>
            </div>
            
        </a>
        {toast.open &&
				createPortal(
					<Toast
						message={toast.message}
					/>,
					document.body
				)}
        </>
    )
}
export default JobResultCard