import React, {useState, useEffect, useLayoutEffect, useRef} from "react";

const OverflowList = (props) => {
    const [viewMore, setViewMore] = useState(false);
    const [visibleSkills, setVisibleSkills] = useState(props.data);
    const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] = useState(props.data.length);

    const list = useRef();

    useEffect(() => {
        setVisibleSkills(props.data)

    },[props.data])
   
    useLayoutEffect(() => {
        const cells = list.current?.children;
        // console.log("is this running")
        setNumberOfItemsDisplayed(props.data.length);
        setTimeout(() => {
            if (cells && cells.length > 0) {
                let numLines = props.lines ? props.lines - 1 : 1;
                const startingOffset = cells[0].offsetTop * numLines;
                let numItems = 0;
                
                for (let i = 0; i < cells.length; i++) {
                    if (!cells[i].classList.contains("view-more") && cells[i].offsetTop > startingOffset) {
                        break;
                    }
                    numItems++;
                    console.log("increase num items", cells.length)
                }
                setNumberOfItemsDisplayed(numItems);
            }
        })
        
    }, [props.data]);

    return (
        
        <div className="overflow-list chip-list" ref={list}>
             { visibleSkills.map((item, index) => {
                if (viewMore || (numberOfItemsDisplayed >= visibleSkills.length)){
                    return (
                        <div className="chip-label chip-btn" key={"skill"+index}>
                            {item}
                        </div> 
                )} else if (index < numberOfItemsDisplayed - 1){
                    return (
                        <div className="chip-label chip-btn" key={"skill"+index}>
                            {item}
                        </div> 
                )}
            })
            }

           {(numberOfItemsDisplayed >= visibleSkills.length) ? <></> : 
           <button className="chip-btn chip-label view-more" onClick={() => setViewMore(!viewMore)}>{viewMore ? "View less skills" : `+${props.data.length - numberOfItemsDisplayed + 1} skills`}</button>}
        </div>
    )
}

export default OverflowList