import React, {useState} from "react";
import Icon from "../Icon";

const AddChip = (props) => {

    return (
        <button className={`chip-btn search-filter-chip with-remove`} onClick={() => {props.click(props.label)}}>
            {props.label}
            <span className="icon-btn">
                <Icon icon="add" size="18"/>
            </span>
        </button>
    )
}
export default AddChip