import React, { useEffect, useRef, useState } from "react";
import Icon from "../global/Icon";
import altImages from "../../assets/data/careerImages.json";

const CareerBanner = (props) => {
    const [data, setData] = useState(props.video ? props.video : props.image)
    const [isPlaying, setIsPlaying] = useState(false);
    const [mute, setMute] = useState(true);
    const video = useRef();
    const fallBackSrc = process.env.PUBLIC_URL + "/images/occupations/placeholder2.jpg"
    const fallBackVid = {src: process.env.PUBLIC_URL + "/images/occupations/placeholderVideo.mp4"}
    useEffect(() => {
        

        if (props.video) {
            //check if video loads
            setData(props.video)
            
        } else {
            let altImage = altImages.filter(f => f.code === props.code)
            console.log(altImage)
            if (altImage.length > 0) {
                setData(altImage[0].image)
            } else {
                setData(props.image)
            }
        }

        // setData(props.video ? props.video : props.image)

        if (video.current) {
            video.current?.load();
            // setTimeout(() => video.current.play())
            setIsPlaying(true);
            var tracks = video.current.textTracks[0];
            tracks.mode = 'showing'; 
        }
        
    },[props])

    const skipEndCard = (event) => {
        let endTime = event.target.duration
            if(event.target.currentTime >= (endTime - 5)) {
                event.target.pause();
                //loop over
                console.log("stop video before end time")
                event.target.currentTime = 0;
                event.target.play()
            }
    }

    return (
        <div className="banner-image">
            {props.video && data ? 
                <div className="video-container">
                <video ref={video} key={props.code} onTimeUpdate={(event) => skipEndCard(event)} 
                 playsInline crossorigin="anonymous"

                controls controlsList="nodownload" loop="true" autoplay="autoplay" muted poster={data.poster}>
                    <source src={data.src} type="video/mp4" onError={(e)=>{e.target.onError = null; e.target.src = fallBackVid.src;e.target.parentNode.load()}}></source>
                    <track label="English" kind="subtitles" srclang="en" src={props.video.track} data-track-src={props.video.track}/>
                    {props.video.track_es && <track label="Español" kind="subtitles" lang="es" src={props.video.track_es} data-track-src={props.video.track_es}/>}
                </video>
                </div>
                : props.youtube ? <div className="video-container">
                <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/vbb4SZOTK2Y?si=pLATBw_t5WssISrM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            : data ? 
            <div className="video-container"><img src={data} onError={(e)=>{e.target.onError = null; e.target.src = fallBackSrc}} alt=""/></div>
            : 
            <></>
        }

            <div className="career-info">
                {props.title && <h1 className="desktop-h3 bold">{props.title}</h1>}
                {props.description && <p className="career-description body-text">{props.description}</p>}
            </div>
        </div>
    )
}

export default CareerBanner