import React, {useEffect, useState, useRef} from "react";
import { Tabs, Tab } from "@mui/material"; 
import Header from "./global/Header";
import Icon from "./global/Icon";
import { Link, useLocation, useParams } from "react-router-dom";
import PageWrapper from "./global/PageWrapper";
import CareerOverviewCarousel from "./careers/CareerOverviewCarousel";
import CareersAccordion from "./careers/CareersAccordion";
import ScrollCarousel from "./careers/ScrollCarousel";
import placeholder from "../assets/images/Graph.png";
import careers from "../assets/data/careers.json";
import jobs from "../assets/data/jobs.json";
import simulations from "../assets/data/simulations.json";
// import Tabs from "./global/tabs/Tabs";
import TabPanel from "./global/tabs/TabPanel";
import SkillCard from "./global/cards/SkillCard";
import ForageCard from "./global/cards/ForageCard";
import { createPortal } from "react-dom";
import ForageModal from "./skills/ForageModal";
import ToggleChip from "./global/chips/ToggleChip";
import CareerFieldsDropdown from "./skills/CareerFieldsDropdown";
import { VirtualizedGrid } from "@mierak/react-virtualized-grid";
import { FixedSizeList as List } from "react-window";
import VirtualizedItemGrid from 'react-virtualized-item-grid';
import VirtualScrollChild from "./global/scroll/VirtualScrollChild";
import VirtualAndInfiniteScroll from "./global/scroll/VirtualInfinite";
import BackToTop from "./global/BackToTop";
import CompanyAutocomplete from "./skills/CompanyAutocomplete";
import ForageFilterCompany from "./skills/ForageFilterCompany";
import ForageContent from "./skills/ForageContent";
import VideoLearning from "./skills/VideoLearning";
import Swoop from "./global/Swoop";

const SkillSecondary = (props) => {
    const location = useLocation()
    // let {startingTab } = location.state ? location.state : 0;
    const [simulationsShown, setSimulationsShown] = useState([...simulations])
    const [simulationFilters, setSimulationFilters] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [showOptions, setShowOptions] = useState(false);
    const [modal, setModal] = useState({open:false,data:{}})
    const [showScrollButton, setShowScrollButton] = useState(false)
    const toast = useRef();
    const tabContent = useRef();
    

    useEffect(() => {
        window.scrollTo(0,0);
        console.log(window.location.pathname)
        console.log(location)
    })

   
    const savedLearning = [
        {
            type:"simulation",
            title: "Agile Methodology",
            company:"Cognizant",
            avatar: "https://media.licdn.com/dms/image/D4E0BAQHDKo68h6DqgQ/company-logo_100_100/0/1688389916667?e=2147483647&v=beta&t=Q6h9d65fGxFgbVVQIF9pHnSn64xSjnl7eUcq3-hTPqs",
            thumbnail: "images/skills/img4.png",
            forage: true,
            length: "4 hours",
            tasks: 4,
            category: "Project Management",
            description: "",
            saved:true
        },
        {
            type:"skill",
            id:"skillvid04",
            title:"Control Movement",
            author:"CareerOneStop",
            length:"xx min",
            cost:"Free",
            videoPage:"https://www.careeronestop.org/Videos/SkillandAbilityVideos/control-movement.aspx",
            embed:"https://www.youtube.com/embed/yVKFjwsre1M?rel=0",
            thumbnail:"Control_Movement.png",
            progress: 48,
            saved:true
        },
        {
            type:"skill",
            id:"skillvid06",
            title:"Fine Manipulative Ability",
            author:"CareerOneStop",
            length:"xx min",
            cost:"Free",
            videoPage:"https://www.careeronestop.org/Videos/SkillandAbilityVideos/fine-manipulative-ability.aspx",
            embed:"https://www.youtube.com/embed/PXQQSwrV8pk?rel=0",
            thumbnail:"Fine_Manipulative_Ability.png",
            progress: 11,
            saved:true
        },
    ]
    

    // const simulations = [
    //     {
    //         title: "Agile Methodology",
    //         company:"Cognizant",
    //         avatar: "https://media.licdn.com/dms/image/D4E0BAQHDKo68h6DqgQ/company-logo_100_100/0/1688389916667?e=2147483647&v=beta&t=Q6h9d65fGxFgbVVQIF9pHnSn64xSjnl7eUcq3-hTPqs",
    //         thumbnail: "images/skills/img4.png",
    //         forage: true,
    //         length: "4 hours",
    //         tasks: 4,
    //         category: "Project Management",
    //         description: "description here"
    //     },
    //     {
    //         title: "Strategy Consulting",
    //         company:"Accenture",
    //         avatar: "https://media.licdn.com/dms/image/D4E0BAQGTUswcRlgg9A/company-logo_100_100/0/1689352303421?e=2147483647&v=beta&t=Esui0zYMu4wp7NuYfAd9ZyarMvAb39Wn27IImO1bSRY",
    //         thumbnail: "images/skills/img5.png",
    //         forage: true,
    //         length: "4 hours",
    //         tasks: 4,
    //         category: "Consulting",
    //         description: "Gain insights into leveraging data visualizations as a tool for making informed business decisions."
    //     },
    //     {
    //         title: "Commercial Banking",
    //         company:"J.P.Morgan | Chase",
    //         avatar: "https://media.licdn.com/dms/image/C4E0BAQFN7ZGRjNcgeA/company-logo_100_100/0/1656681489088?e=2147483647&v=beta&t=0KMlNACKgGwm_fJcTt39rGG20ba1_w6cSKMOPsZ_Sf8",
    //         thumbnail: "images/skills/img6.png",
    //         forage: true,
    //         length: "4 hours",
    //         tasks: 4,
    //         category: "Banking & Finance",
    //         description: "Gain insights into leveraging data visualizations as a tool for making informed business decisions."
    //     },
    //     {
    //         title: "Agile Methodology",
    //         company:"Cognizant",
    //         avatar: "https://media.licdn.com/dms/image/D4E0BAQHDKo68h6DqgQ/company-logo_100_100/0/1688389916667?e=2147483647&v=beta&t=Q6h9d65fGxFgbVVQIF9pHnSn64xSjnl7eUcq3-hTPqs",
    //         thumbnail: "images/skills/img4.png",
    //         forage: true,
    //         length: "4 hours",
    //         tasks: 4,
    //         category: "Project Management",
    //         description: "description here"
    //     },
    //     {
    //         title: "Strategy Consulting",
    //         company:"Accenture",
    //         avatar: "https://media.licdn.com/dms/image/D4E0BAQGTUswcRlgg9A/company-logo_100_100/0/1689352303421?e=2147483647&v=beta&t=Esui0zYMu4wp7NuYfAd9ZyarMvAb39Wn27IImO1bSRY",
    //         thumbnail: "images/skills/img5.png",
    //         forage: true,
    //         length: "4 hours",
    //         tasks: 4,
    //         category: "Consulting",
    //         description: "Gain insights into leveraging data visualizations as a tool for making informed business decisions."
    //     },
    //     {
    //         title: "Commercial Banking",
    //         company:"J.P.Morgan | Chase",
    //         avatar: "https://media.licdn.com/dms/image/C4E0BAQFN7ZGRjNcgeA/company-logo_100_100/0/1656681489088?e=2147483647&v=beta&t=0KMlNACKgGwm_fJcTt39rGG20ba1_w6cSKMOPsZ_Sf8",
    //         thumbnail: "images/skills/img6.png",
    //         forage: true,
    //         length: "4 hours",
    //         tasks: 4,
    //         category: "Banking & Finance",
    //         description: "Gain insights into leveraging data visualizations as a tool for making informed business decisions."
    //     }
    // ]
    const addToBookmarks = (type) => {
		const toaster = toast.current;

        if (type === "add") {
            toaster.querySelector("p").innerText = "Added to your preferred roles"
        } else {
            toaster.querySelector("p").innerText = "Removed from your preferred roles"
        }
        

		setTimeout(() => {
			toaster.classList.add('show');
			setTimeout(() => {
				toaster.classList.replace('show', 'animate-out');
			}, 5000);
			setTimeout(() => {
				toaster.classList.remove('animate-out');
			}, 5500);
		}, 200);
	};

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
      };

      const ItemRenderer = ({item}) => {
      
          // Access the items array using the "data" prop:
        //   const item = props.data[props.index];
       
          return (
            <ForageCard data={item} modifier="forage-card" click={() => setModal({...modal, open:true,data:item})}/>
            
          );
        
      }
    return (
        <>
        {/* <Swoop swoop={{title:props.swoop.title, subtitle: props.swoop.subtitle, navigation: '/skills'}} /> */}
        <main>
        <section className="intro">
            <div className="page-nav">
            <Link to={process.env.PUBLIC_URL + '/skills'} className="button icon-btn">
                <Icon icon="back" size="24"/>
            </Link>
            <h2 className="page-title tertiary desktop-h3 bold">{props.swoop.title}</h2>
            </div>
            {/* <p className="description"></p> */}
        </section>
        <div className="skill-details">
                <div className={"tab-container"} >
                    
                {/* {props.history} */}
                {props.type === 'forage' ? 
                    <ForageContent setModal={setModal} />
                : props.type === 'videos' ? 
                    <VideoLearning/>
                : <section>
                    <div className="">
                        {/* <div className="card-header">
                            <h3 className="desktop-h4 bold card-title">My saved content</h3>
                        </div> */}
                        <div className="card-body">
                            <div className="card-row skills-group show-all">
                                {savedLearning.map((item, index) => {

                                    if (item.type === "skill") {
                                        return (
                                            <SkillCard data={item} modifier={"madecraft-card"} key={"saved"+index}/>
                                        )
                                    } else {
                                        return (
                                            <ForageCard data={item} modifier={"forage-card"} key={"saved"+index} click={() => setModal({...modal, open:true,data:item})}/>
                                            
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </section>}

               
            </div>
           
                    <BackToTop/>
            <div
					className='alert toast'
					ref={toast}
				>
					<span className='icon'>
						<Icon
							icon='correct'
							size='24'
						/>
					</span>
					<p className='font-lg'>Added to your saved learning</p>
				</div>
            </div>
            {modal.open &&
				createPortal(
					<ForageModal
						close={() => setModal({...modal, open:false})}
						data={modal.data}
					/>,
					document.body
				)}
        </main>
        </>
     
    )
}

export default SkillSecondary