import React, {useEffect, useState, useRef} from "react";
import FastFacts from "../../groups/FastFacts";
import CareersCardMobile from "./CareersCardMobile";
import CareerTabs from "../../groups/CareerTabs";
import TabPanel from "../tabs/TabPanel";

const CareersSwitcher = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [renderMobile, setRenderMobile] = useState(false);

    const [width, setWidth] = useState(0);

    useEffect(() => {
        //onload
        setWidth(window.innerWidth)
    })

    useEffect(() => {
        const onResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [setWidth])

    //and then
    useEffect(() => {
        // console.log("am called");

        if (width< 807) {
            setRenderMobile(true)
        } else {
            setRenderMobile(false)
        }


    }, [width]);

    // useEffect(() => {
    //     //on load
    //     checkSize()

    //     window.addEventListener("resize", checkSize())

    //     return (() => {
    //         window.removeEventListener("resize", checkSize())
    //     })
    // })
    // function checkSize() { 
    //     console.log(window.innerWidth)
    //     if (window.innerWidth < 807) {
    //         setRenderMobile(true)
    //     } else {
    //         setRenderMobile(false)
    //     }
    // }



    return (
        <div className="careers-group">
            
            {renderMobile ? 
                <CareersCardMobile careers={props.careers} active={activeTab} select={(item) => setActiveTab(item)}/>
                : <>
                    {props.careers.length > 1 ? 
                    <div className="card-body">
                        <CareerTabs careers={props.careers} selected={activeTab} select={(item) => setActiveTab(item)} hideButton={true}></CareerTabs>
                        
                        {/* <a className="button primary medium" href={process.env.PUBLIC_URL + "/careers"}>Explore more careers</a> */}
                    </div> 
                
                : <div className="card-body">
                    <div className="tab-container career-switcher">
                        <div className="tab-list-container">
                            <div className="single-career image">
                                <img src={props.careers[0].image} alt="alt text"/>
                            </div>
                            {/* <a className="button primary medium" href={process.env.PUBLIC_URL + "/careers"}>Explore more careers</a> */}

                        </div>
                        <div className="tab-content">
                            <FastFacts data={props.careers[0]}/>
                        </div>
                    </div>
                </div>
                    }
                </>   
            }
            
        </div>
    )
}
export default CareersSwitcher