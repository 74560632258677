import React, {useState} from "react";
import Icon from "../global/Icon";

const InsightsAccordion = (props) => {
    const [open,setOpen] = useState(false)

    return (
        <div className={`insights-accordion ${props.type}`}>
            <button className="tab-action" onClick={() => props.click()}>
               <span className="mobile-h4 bold">
                {props.title}    
                </span>
                <span className="icon">
                    <Icon icon="chevron-right" size="24"/>
                </span>
            </button> 
            {open && 
                <div className="insights-accordion-body">
                    {props.children}
                </div>
            }
        </div>
    )
}
export default InsightsAccordion