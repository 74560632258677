import React, {useEffect, useState} from "react";
import { Slide } from "@mui/material";
import careers from "../../../assets/data/careersMenu.json";
import Icon from "../Icon";
import { Link } from "react-router-dom";

const MobileMenu = (props) => {
    const [selected, setSelected] = useState({cluster:{},pathway:{}})
    const [visiblePanel, setVisiblePanel] = useState(0)
    let navItems = props.navItems;
    let value = props.selected;
    // let careers = props.careersMenu;

    useEffect(() => {
        if (visiblePanel > 0) {
            document.body.style.overflow = 'hidden'
        }
    },[visiblePanel])

    const selectCluser = (code) => {
        setSelected({cluster: code, pathway:{}})
        //push focus into that row
        // setTimeout(() => {pathways.current.querySelector('a').focus()}, 300)
        
    }
    const selectPathway = (pathway) => {
        setSelected({...selected, pathway: pathway})
        //push focus into that row
        // setTimeout(() => {jobs.current.querySelector('a').focus()}, 300)
        
    }

    return (
        <div className="mobile-menu-panels">
            {visiblePanel === 0 &&
            <Slide in={visiblePanel === 0} direction="right">
            <nav className={visiblePanel === 0 ? "panel visible" : "panel"}>
                 <ul>
                 {navItems.map((option,index) => {
                    if (option.title === 'Explore careers') {
                        return (<li className={value === option.url ? "tab-item active" : "tab-item"} key={"navitem"+index}>
                              {/* <Link to={process.env.PUBLIC_URL + "/" + option.url} onClick={() => props.close()} className="tab-action" role="tab" aria-selected={value === option.url}>{option.title}</Link> */}
                              <button className="tab-action" onClick={() => setVisiblePanel(1)} aria-expanded={visiblePanel > 0}  role="tab" aria-selected={value === option.url}>
                                <span>{option.title}</span>
                                <Icon icon="chevron-right" size="24"/>
                            </button>
                             </li>)
                     } else {
                            return (
                            <li className={value === option.url ? "tab-item active" : "tab-item"} key={"navitem"+index}>
                                    <Link to={process.env.PUBLIC_URL + "/" + option.url} onClick={() => props.close()} className="tab-action" role="tab" aria-selected={value === option.url}>{option.title}</Link>
                            </li>
                            )
                        }
                    })}
                 </ul>
            </nav>
            </Slide> }
            {visiblePanel === 1 &&
                <Slide in={visiblePanel === 1} direction="left">
                <div className={visiblePanel === 1 ? "panel subpanel visible" : "panel subpanel"}>
                <div className="panel-header">
                    <div className="flex-row">
                        <button className="button icon-btn" onClick={() => setVisiblePanel(0)} aria-label="Back to main nav">
                            <Icon icon="chevron-left" size="24"/>
                        </button>
                        <h2 className="desktop-h4 bold">Explore careers</h2>
                    </div>
                    
                    {/* <h3 className="panel-title">Career clusters</h3> */}
                </div>
                
                <ul>
                    <li><Link to={process.env.PUBLIC_URL + "/careers/recommended"} className="card link-btn" onClick={() => props.close()}>Ideas for biology majors</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + "/careers/saved"} className="card link-btn" onClick={() => props.close()}>Saved occupations</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/careers"} className="link-btn" onClick={() => props.close()}>View all career clusters</Link></li>
                    {careers.map((cluster, index) => {
                        return (
                            <li className={selected.cluster.code === cluster.code ? "megamenu-item selected" :"megamenu-item"} key={"cluster"+cluster.code}>
                                {/* <Link to={process.env.PUBLIC_URL + "/careers/" + cluster.code} onClick={() => props.close()}>{cluster.title}</Link> */}
                                <button className="tab-action" aria-label={"More " + cluster.title} aria-expanded={selected.cluster.code === cluster.code} onClick={() => {selectCluser(cluster);setVisiblePanel(2)}}>
                                    <span>{cluster.title}</span>
                                    <Icon icon="chevron-right" size="24"/>
                                </button>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
            </Slide>}
            {visiblePanel === 2 &&
            <Slide in={visiblePanel === 2} direction="left">
            <div className={visiblePanel === 2 ? "panel subsubpanel visible" : "panel subsubpanel"}>
                <div className="panel-header">
                    <div className="flex-row">
                    <button className="button icon-btn" onClick={() => setVisiblePanel(1)} aria-label="Back to Career clusters list">
                        <Icon icon="chevron-left" size="24"/>
                    </button>
                    <h2 className="desktop-h4 bold">{selected.cluster.title}</h2>
                    </div>
                    <h3 className="panel-title">Pathways</h3>
                </div>
                
                <ul>
                <li><Link to={process.env.PUBLIC_URL + "/careers/" + selected.cluster.code} className="link-btn" onClick={() => props.close()}>View all</Link></li>
                    {selected.cluster.code.length > 0 && careers.filter(f => f.code === selected.cluster.code)[0].pathways.map((path, index) => {
                        return (
                            <li className={selected.pathway.code === path.code ? "megamenu-item selected": "megamenu-item"} key={"path"+path.code} >
                                {/* <Link to={process.env.PUBLIC_URL + "/careers/pathway/" + path.code} onClick={() => props.close()}>{path.title}</Link> */}
                                <button className="tab-action" aria-label={"More " + path.title} aria-expanded={selected.pathway.code === path.code} onClick={() => {selectPathway(path);setVisiblePanel(3)}}>
                                    <span>{path.title}</span>
                                    <Icon icon="chevron-right" size="24"/>
                                </button>
                            </li>
                        )
                    })}
                    
                </ul>
            </div></Slide>
            }
            {visiblePanel === 3 &&
            <Slide in={visiblePanel === 3} direction="left">
            <div className={visiblePanel === 3 ? "panel subsubpanel visible" : "panel subsubpanel"}>
                <div className="panel-header">
                    <div className="flex-row">
                    <button className="button icon-btn" onClick={() => setVisiblePanel(2)} aria-label="Back to Pathway list">
                        <Icon icon="chevron-left" size="24"/>
                    </button>
                    <h2 className="desktop-h4 bold">{selected.pathway.title}</h2>
                    </div>
                    <h3 className="panel-title">Occupations</h3>
                </div>
                <ul>
                    <li><Link to={process.env.PUBLIC_URL + "/careers/pathway/" + selected.pathway.code} className="link-btn" onClick={() => props.close()}>View all</Link></li>
                    {selected.pathway.code.length > 0 && careers.filter(f => f.code === selected.cluster.code)[0].pathways.filter(g => g.code === selected.pathway.code)[0].occupations.map((job, index) => {
                        return (
                            <li className="megamenu-item" key={"path"+job.code}>
                                <Link className="tab-action" to={process.env.PUBLIC_URL + "/career/" + job.code} onClick={() => props.close()}>{job.title}</Link>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
            </Slide> }
        </div>
        
    )
}

export default MobileMenu