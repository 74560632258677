import React, { useState, useRef, useEffect } from "react";
import Icon from "../global/Icon";
import { searchTerms } from "../../assets/data/jobTerms";
import careerData from "../../assets/data/careersMenu.json"
import { Link } from "react-router-dom";

const CareerSearchDropdown = (props) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("");
    const [resultList, setResultList] = useState({pathways:[], jobs:[]});
    const [showClear, setShowClear] = useState(false);
    const input = useRef();
    const container = useRef();

    const allPathways = useRef([]);
    const allJobs = useRef([]);

    useEffect(() => {
        //create search list
        careerData.forEach(cluster => {
            cluster.pathways.forEach(path => {
                let pathObj = {};
                pathObj.code = path.code;
                pathObj.title = path.title;
                allPathways.current.push(pathObj)

                path.occupations.forEach(job => {
                    let jobObj = {};
                    jobObj.code = job.code
                    jobObj.title = job.title

                    allJobs.current.push(jobObj)
                })
            })
        })

        // console.log(allJobs.current)
        // console.log(allPathways.current)
    
    },[])

    // useEffect(() => {
    //     setSelected(props.default)
    //     if (props.default.length > 0) {setShowClear(true)}
    // },[props.default])

    useEffect(() => {
        let autocomplete = container.current;
        const handleClick = (event) => {
            if (autocomplete && autocomplete.contains(event.target)) {
                //do nothing
            } else {
                // console.log("close", event.target)
                setOpen(false)
            }
        }
        document.addEventListener("click",handleClick)
        document.addEventListener('keydown', event => {
            if (event.key === "Escape") {
                setOpen(false)
            }
        })
        return () => {
            document.removeEventListener('click', handleClick)
            document.removeEventListener('keydown', event => {
                if (event.key === "Escape") {
                    setOpen(false)
                }
            })
        }
    },[])

    const onInput = (event) => {
        
        // console.log(event.target.value)
        if (event.target.value.length > 0) {
            
            setShowClear(true)
            
        } else {
            setShowClear(false)
            // setOpen(false)
        }
        if (event.target.value.length > 2) {
            setOpen(true)
            filterList(event.target.value)
        } 
    }
    const addToSelected = (item) => {
        input.current.value = item
        setSelected(item)
        // props.search(item)
        setOpen(false)
        setShowClear(true)
        filterList('')
        props.add(item)
    }


    const clearInputFilter = () => {
        input.current.value = ''
        setSelected('')
        setOpen(false)
        setResultList({pathways:[], jobs:[]})
        setShowClear(false)
        // props.search('')
        input.current.focus()
    }
    const onEnter = (event) => {
        if (event.key === "Enter") {
            //search
            // props.search(event.target.value)
            setOpen(false)
        }
    }

    const filterList = (str) => {
        // let newcompanyList = searchTerms.filter(c => c !== selected)
        //remove any already selected
        
        let filteredListPath, filteredListJobs;
        //filter by input
        if (str.length > 0) {
            filteredListPath = allPathways.current.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
            filteredListJobs = allJobs.current.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
            // (post) => {
            //     return post.title.toLowerCase().match(searchValue.toLowerCase());
            //  });
        } else {
            filteredListPath = allPathways.current
            filteredListJobs = allJobs.current
        }
        
        setResultList({pathways:filteredListPath, jobs:filteredListJobs})
    }

    return (
        <div className="career-search-list dropdown" ref={container}>
            <p className="form-label">Occupation role</p>
            <div className="autocomplete-container ">
                
                <div className="search-input-dropdown">
                    <input type="text" className="gr-input" 
                    aria-label="Occupation role" 
                    role="combobox" aria-autocomplete="list" aria-expanded={open} aria-haspopup="listbox"
                    aria-controls="jobSearchList"
                    
                    onChange={onInput}
                    // onKeyDown={(event) => onEnter(event)}
                    defaultValue={selected}
                    ref={input}
                    />
                    
                    
                    <span className="drop-down-icon" >
                        <Icon icon="drop-down" size="24"/>
                    </span> 
                </div>
                
                <div className="autocomplete-list" style={open && (resultList.jobs?.length > 0 || resultList.pathways?.length > 0) ? null : {display:"none"}} id="jobSearchList" role="listbox">
                    {/* <!-- use keyboard behavior from mui component --> */}
                    <ul>
                        {resultList.jobs?.map((job,index) => {
                            return (
                                <li role="presentation" key={"job"+job.code+index}>
                                    <button role="option" onClick={() => addToSelected(job.title)} aria-selected={selected === job.title}>{job.title}</button>
                                </li>
                            )
                        })}

                    </ul>
                </div> 
            </div>
        </div>
    )
}
export default CareerSearchDropdown