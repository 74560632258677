import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import ShareBadge from "../modals/ShareBadge";

import badgeImg from "../../../assets/images/Credly-Engineering-badge.png"
const defaultBadge = {
    type: 'cloud-bootcamp',
    img: 'Credly-Engineering-badge.png',
    title: 'Cloud Engineering Immersive Bootcamp',
    issuer: "Quickstart Inc.",
    thumbnail: "https://images.credly.com/size/340x340/images/3f7d11f6-bd33-4351-9473-8d3f9bbaea4a/tempfile20210816-65-1toaens.png"
}
const GEDbadge = {
    type:'ged',
    title: "GED Credential",
    issuer: "GED Testing Service",
    thumbnail:"https://images.credly.com/size/680x680/images/721a41ab-a3ad-46c7-9999-49211e071db5/ged_overall.png",
    completed:true
}
const comptiaBadge = {
    type:'Aplus',
    title: "CompTIA A+ Certification",
    issuer: "CompTIA",
    thumbnail:"https://comptiacdn.azureedge.net/webcontent/images/default-source/siteicons/logoaplus.svg?sfvrsn=5ee1de19_2",
    completed:true
}
const BadgeCTACard = (props) => {
    const [badge, setBadge] = useState(props.type === 'ged' ? GEDbadge : props.type ? comptiaBadge : defaultBadge)
    const [shareOpen, setShareOpen] = useState(false);

    return (
        
        <div className={`card dark action-card badge-card padding-even ${props.badgeShared ? "edited" :""}`}>
            
            <div className="card-header">
                <div className="flex-row">
                    <h2 className="card-title desktop-h3">
                        {props.badgeShared ? 
                        "You’ve shared your achievement"
                        : "You’ve accepted your badge, now share it to your network"
                        // "Share your badge to showcase your new credentials and skills!"
                        }
                    </h2>
                </div>
                <p className="subtitle-1">Shared badges connect your network to the <Link to={process.env.PUBLIC_URL + "/badgeDetails?user=" + props.user + "&badge="+badge.title}>details of your achievement</Link>.</p>
            </div>
                
            <div className="card-body">
                <div className="badge-information">
                    <div className={`badge avatar ${props.type ? props.type : ''}`}>
                        <img src={badge.thumbnail} alt="badge"/>
                    </div>
                    <div className="badge-details">
                        <h2 className="badge-title desktop-h3 bold">{badge.title}</h2>
                        <p className="badge-issuer desktop-h4">Issued by: <a className="white" style={{fontWeight:"normal",color:"var(--color-white)"}}>{badge.issuer}</a></p>
                        
                    </div>
                </div>
            </div>
            <div className={`card-footer ${props.type ? props.type : ''}`}>
            <button className="button medium primary" onClick={() => setShareOpen(true)} style={{zIndex:4}}>{props.badgeShared ? "Share again" : "Share badge"}</button>
            </div>
            
            
            {shareOpen && createPortal(<ShareBadge data={badge} share={() => {props.share()}} close={() => setShareOpen(false)}/>, document.body)}

        </div>
    )
}
export default BadgeCTACard