import React from "react";
import getOverlays from "../../assets/map/overlayData";
import { Link } from "react-router-dom";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export default function MapStatic(props) {

    const overlays = getOverlays()
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "var(--tooltip-background)",
            "&::before": {
                backgroundColor: "var(--tooltip-background)",
                border: "1px solid var(--tooltip-border)"
               }
          },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: "var(--tooltip-background)",
          border: "1px solid var(--tooltip-border)",
          color: 'var(--text-inverse)',
          fontSize: 14,
          textAlign:'center',
          maxWidth:'270px',
          padding: '6px 12px',
          fontWeight:'400'
        },
      }));
   
    return (
        <div className="map-image">
            <div className="image light-mode">
                <img src={process.env.PUBLIC_URL + '/images/map/map_light.png'} alt={"Full map"}/>
            </div>
            <div className="image dark-mode">
                <img src={process.env.PUBLIC_URL + '/images/map/map_dark.png'} alt={"Full map"}/>
            </div>
            <div className="hotspot-container light-mode">
                {overlays.map((overlay,index) => {
                    const rec = false;
                    // const rec = index === 12 || index === 4 || index === 7;
                    if (rec) {
                        return (
                            <LightTooltip placement="top" title="Recommended" arrow slotProps={{
                                popper: {
                                modifiers: [
                                    {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 10],
                                    },
                                    },
                                ],
                                },
                            }}>
                                <Link className={`${overlay.className} ${rec ? 'recommended' : ''}`} 
                                    to={process.env.PUBLIC_URL  + "/careers/"+overlay.code} 
                                    key={"overlay"+overlay.clusterId} 
                                    id={overlay.id}>
                                    <img src={process.env.PUBLIC_URL + '/images/map/light/'+overlay.img} alt={overlay.text}/>
                                    {overlay.hover && <img className="map-hover" src={process.env.PUBLIC_URL + '/images/map/light/'+overlay.hover} alt={''}/>}
                                    <span>{overlay.text}</span>
                                    <div className="recommended-glow" role="img" aria-label="Recommended"/>
                                </Link>
                        </LightTooltip> 
                        )
                    } else {
                    return (
                        <Link className={`${overlay.className} ${rec ? 'recommended' : ''}`} 
                            to={process.env.PUBLIC_URL  + "/careers/"+overlay.code} 
                            key={"overlay"+overlay.clusterId} 
                            id={overlay.id}>
                            <img src={process.env.PUBLIC_URL + '/images/map/light/'+overlay.img} alt={overlay.text}/>
                            {overlay.hover && <img className="map-hover" src={process.env.PUBLIC_URL + '/images/map/light/'+overlay.hover} alt={''}/>}
                            <span>{overlay.text}</span>
                        </Link>
                    )}
                })}
            </div>
            <div className="hotspot-container dark-mode">
                {overlays.map((overlay,index) => {
                    // const rec = index === 12 || index === 4 || index === 7;
                    const rec = false;
                    if (rec) {
                        return (
                            <LightTooltip placement="top" title="Recommended" arrow slotProps={{
                                popper: {
                                modifiers: [
                                    {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 10],
                                    },
                                    },
                                ],
                                },
                            }}>
                                    <Link className={`${overlay.className} ${rec ? 'recommended' : ''}`} 
                                    to={process.env.PUBLIC_URL  + "/careers/"+overlay.code} 
                                    key={"overlay"+overlay.clusterId} 
                                    id={overlay.id}>
                                    <img src={process.env.PUBLIC_URL + '/images/map/dark/'+overlay.img} alt={overlay.text}/>
                                    {overlay.hover && <img className="map-hover" src={process.env.PUBLIC_URL + '/images/map/dark/'+overlay.hover} alt={''}/>}
                                    <span>{overlay.text}</span>
                                    <div className="recommended-glow" role="img" aria-label="Recommended"/>
                                </Link>
                        </LightTooltip> 
                        )
                    } else {
                    return (
                        <Link className={`${overlay.className} ${rec ? 'recommended' : ''}`} 
                            to={process.env.PUBLIC_URL  + "/careers/"+overlay.code} 
                            key={"overlay"+overlay.clusterId} 
                            id={overlay.id}>
                            <img src={process.env.PUBLIC_URL + '/images/map/dark/'+overlay.img} alt={overlay.text}/>
                            {overlay.hover && <img className="map-hover" src={process.env.PUBLIC_URL + '/images/map/dark/'+overlay.hover} alt={''}/>}
                            <span>{overlay.text}</span>
                        </Link>
                    )}
                })}
            </div>

        </div>
    )
}
