import React from "react";
import Icon from "../global/Icon";
import OverflowList from "../OverflowList";

const MySkills = (props) => {

    if (props.user && props.user.skills.length > 0) {
        return (

            <div className="card skills-card">
                <div className="card-header">
                        <h2 className="desktop-h4 bold card-title">Skills</h2> 
                        <button className="button icon-btn medium edit-btn">
                            <Icon icon="edit" size="24"/>
                        </button>
                    
                </div>
                <div className="card-body">
                    <OverflowList lines={3} data={props.user.skills}/>
                    {/* <div className="chip-list">
                    {props.user.skills.map((skill,index) => {
                       return <div className="chip-btn">{skill}</div>
                    })}
                    </div> */}
                </div>
                
            </div>
        )
    } else {
        return (

            <div className="card skills-card">
                <div className="card-header">
                    <h2 className="desktop-h4 bold card-title">Skills</h2>
                </div>
                <div className="card-body">
                    <button className="empty-area-button">
                        <span className="circle"><Icon icon="add" size="24"/></span>
                        <span>Add skills you've gained in and out of class.</span>
                    </button>
                    
                </div>
            </div>
        )
    }
   
}
export default MySkills