import React, {useEffect, useState} from "react";
import Icon from "../global/Icon";
import { createPortal } from "react-dom";
import Toast from "../global/Toast";
const BookmarkButton = (props) => {
    const [saved, setSaved] = useState(props.saved ? props.saved : false);
    const [toast, setToast] = useState({open:false, message:''})

   
    useEffect(() => {
        setSaved(props.saved)
    },[props.saved])

    const bookmark = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (saved) {
            setSaved(false)
            setToast({open:true, message: "Removed from Saved jobs"})
        }
        else {
          
                setSaved(true)
                setToast({open:true, message: "Added to Saved jobs"})
            
        }
        props.bookmark()
        // setSaved(!saved)
    }

    

    useEffect(() => {
        if (toast.open) {
            setTimeout(event => {
                setToast({open:false, message:''})
            },4000)
        }
      
        return(() => {
            clearTimeout()
        })

    },[toast])
    return (
        <>
        <button className="button icon-btn bookmark-btn" 
            aria-pressed={saved} aria-label="Bookmark"
            onClick={(e) => bookmark(e)}
            >
            <Icon icon={saved ? "bookmark-on" : "bookmark-off"} size="24"/>
        </button>
        {toast.open &&
				createPortal(
					<Toast
						message={toast.message}
					/>,
					document.body
				)}
        </>
    )
}
export default BookmarkButton