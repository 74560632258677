import React from "react";
import workStyle from "../../../assets/images/cta/work-style-small.png";
import assessment25 from "../../../assets/images/cta/assessment-progress-25.svg";
import assessment50 from "../../../assets/images/cta/assessment-progress-50.svg";
import assessment70 from "../../../assets/images/cta/assessment-progress-70.svg";
import assessment75 from "../../../assets/images/cta/assessment-progress-75.svg";
import workStyleResults from "../../../assets/images/cta/assessment-results.png";
import workStyleReport from "../../../assets/images/cta/assessment-report.svg";

const AssessmentMiniCard = (props) => {

    return (
        //props.part === 1 && props.progress > 0 no extra class
        
        <div className={`card assessment-mini-card flex-1`}>
            {props.part === 1 && props.progress === 100 && props.resultsReady ? 
            //part 1 done, part 2 not started, results ready
            <>
                 <div className="card-body">
                    <div className="image">
                        <img src={assessment70} alt="cta image alt text"/>
                    </div>
                    <h3 className={`card-title desktop-h4 bold`}>Want a sneak peak?</h3>
                    <p className="body-text small">View your part 1 results, then start part 2.</p>
                    
                </div>
                <div className="card-footer">
                        <button className="button small light outline">Start part 2</button>
                        <button className="button small light outline">View part 1 results</button>
                    </div>
                </>
            : props.part === 1 && props.progress === 100 ? 
            //part 1 done, results not ready
            <>
                 <div className="card-body">
                 <div className="image">
                        <img src={assessment50} alt="cta image alt text"/>
                    </div>
                    <h3 className={`card-title desktop-h4 bold`}>One part down</h3>
                    <p className="body-text small">Part 2 is all about finding your strengths. Get started while we prepare your part 1 results.</p>
                    
                </div>
                <div className="card-footer">
                        <button className="button small light outline">Start part 2</button>
                        {/* <button className="button small light filled">Learn more</button> */}
                    </div>
                </>
            : props.part === 1 && props.progress > 0 ? 
            //part 1 in progress
            <>
                 <div className="card-body">
                    <div className="image">
                        <img src={assessment25} alt="cta image alt text"/>
                    </div>
                    <h3 className={`card-title desktop-h4 bold`}>You're this close</h3>
                    <p className="body-text small">Take a few minutes to finish part 1 and see your work style results.</p>
                   
                </div>
                <div className="card-footer">
                        <button className="button small light outline">Finish part 1</button>
                        {/* <button className="button small light filled">Learn more</button> */}
                    </div>
                </>
                : props.part === 2 && props.resultsViewed ? <>
                <div className="card-body">
                    <div className="image">
                        <img src={workStyleReport} alt="cta image alt text"/>
                    </div>
                    <h3 className={`card-title desktop-h4 bold`}>How you stand out</h3>
                    <p className="body-text small">Review your work style assessment results.</p>
                    
                </div>
                <div className="card-footer">
                        <button className="button small light outline">Access your report</button>
                </div>
               </>
            : props.part === 2 && props.resultsReady ? <>
                <div className="card-body">
                    <div className="image full-width">
                        <img src={workStyleResults} alt={"graphic"}/>
                    </div>
                    <h3 className={`card-title desktop-h4 bold`}>Your results are in!</h3>
                    <p className="body-text small">Take a look at your full work style results.</p>
                   
                </div>
                <div className="card-footer">
                        <button className="button small light outline">View your results</button>
                        {/* <button className="button small light filled">View part 1 results</button> */}
                </div> 
                
            </>
            : props.part === 2 && props.progress > 0 ? <>
            <div className="card-body">
                <div className="image">
                   <img src={assessment75} alt="cta image alt text"/>
               </div>
               <h3 className={`card-title desktop-h4 bold`}>Let’s wrap this up</h3>
               <p className="body-text small">Finish part 2 to get your full work style results.</p>
               
           </div>
           <div className="card-footer">
                <button className="button small light outline">Finish part 2</button>
                <button className="button small light outline">View part 1 results</button>
            </div>
           </>
             :
           <>
                
                 <div className="card-body">
                    <div className="image">
                        <img src={workStyle} alt="cta image alt text"/>
                    </div>
                    <h3 className={`card-title desktop-h4 bold`}>Find your work style</h3>
                    <p className="body-text small">Take the work style assessment to learn where your strengths shine.</p>
                    
                </div>
                <div className="card-footer">
                        <button className="button small light outline">Learn more</button>
                        <button className="button small light outline">Launch quiz</button>
                    </div>
                
          </>
            }
            
            
        </div>
    )
}
export default AssessmentMiniCard