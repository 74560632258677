import React from "react";
import SavedJobsCTACard from "../global/cards/SavedJobsCTACard";
import AssessmentCTACard from "../global/cards/AssessmentCTACards";
import CTACard from "../global/cards/CTACards";
const DashboardCTA = (props) => {

    if (props.savedJobs) {
        return (
            <SavedJobsCTACard />
        )
    } else if (props.workStyle?.progress > 0 && !props.workStyle?.resultsViewed) {
        return (
            <AssessmentCTACard part={props.workStyle.part} progress={props.workStyle.progress} resultsReady={props.workStyle.resultsReady} />
        )
    } else if (props.major?.length > 0 && props.preferences?.length > 0 && props.workStyle?.resultsViewed) {
        return (
            <></>
        )
    }
    else if (props.major?.length > 0 && props.preferences?.length > 0) {
        return (
            <AssessmentCTACard part={1} progress={0}/>
        )
    }
    else if (props.major?.length > 0) {
        return (
            <CTACard useCase={2} pageState={0} openPreferences={props.openPreferences}/>
            
        )
    } else {
        return (
            <CTACard useCase={3} pageState={0} openEducation={props.openEducation}/>
        )
    }
}

export default DashboardCTA