import React, {useEffect, useState, useRef} from "react";
import CareerBanner from "./careers/CareerBanner";
import { Link, useParams } from "react-router-dom";
import OccupationCard from "./global/cards/OccupationCard";
import careers from "../assets/data/careersMenu.json"; //for now
import recommended from "../assets/data/recommendedOccupations.json";
import saved from "../assets/data/savedOccupations.json";
import { createPortal } from "react-dom";
import JobPreferences from "./jobs/JobPreferences";
import Toast from "./global/Toast";

const CareerSavedDetail = (props) => {
    const [pageData, setPageData] = useState([]);
    const [preferencesOpen, setPreferencesOpen] = useState(false);
    const [toast, setToast] = useState({show: false, message: ''});

    const applyPref = (pref) => {
        setPreferencesOpen(false)
        
        setToast({open:true, message:"Preferences updated"})
        setTimeout(() => {
            setToast({open:false, message:''})
        },4000)
        
    }
    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
        let newData = [];

        
        careers.forEach(cluster => {
            cluster.pathways.forEach(pathway => {

                pathway.occupations.forEach(job => {
                    if (saved.includes(job.soc_code)) {
                        newData.push(job)
                    }
                })
            })
            
        })
        console.log(newData)
        setPageData(newData)
    },[])

    return (
        <main className="careers-main" style={{paddingTop:"48px"}}>
            <section className="intro">
            
                <h1 className="desktop-h3 bold">Saved occupations</h1>
                <p className="career-description body-text">See more of the jobs & internships you want by adding these occupations to your <button className="link-btn" onClick={() => setPreferencesOpen(true)}>job preferences</button>.</p>
            
                
            </section>
           
            <section className="career-body">
            
                <div className="card-row three-three-three">
                    {pageData.length > 0 && pageData.map((job) => {
                        
                        return (
                            <OccupationCard data={job} saved={true} recommended={recommended.includes(job.soc_code)}/>
                            
                        )
                    })}

                    
                        
                </div>
            </section>
            {preferencesOpen && createPortal(<JobPreferences preferences={[]} close={() => setPreferencesOpen(false)} showSaved={pageData} apply={(pref) => applyPref(pref)} major={false}/>, document.body)}
            {toast.open && <Toast message={toast.message}/> }
        </main>
    )
}

export default CareerSavedDetail